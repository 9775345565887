import React, { useState } from "react";
import phnElearnImg from "../Assets/Images/Login/phn-elearn.webp";
import "../Assets/Styles/ForgotAndResetPass.css";
import resetSuccessful from "../Assets/Json/successfulReset.json";
import Lottie from "react-lottie";
import { POST_RESET_PASS_API } from "../Components/AllAPI";
import { useLocation } from "react-router-dom";
import hideIcon from "../Assets/Images/Login/hide.png";
import showIcon from "../Assets/Images/Login/show.png";

const ResetPassword = () => {
  const [inputData, setInputData] = useState({
    new_pass: "",
    confirm_pass: "",
  });
  const [inputError, setInputError] = useState({});
  const [showSuccessfullReset, setShowSuccessfullReset] = useState(false);

  const [newPassVisible, setNewPassVisible] = useState(false);
  const [confirmPassVisible, setConfirmPassVisible] = useState(false);

  const inputHandler = (event) => {
    const { name, value } = event.target;
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const location = useLocation();
  let url = location.pathname;
  const token = url.split("/").pop();
  // console.log(url.split("/").pop());

  const toggleNewPassVisibility = () => {
    setNewPassVisible(!newPassVisible);
  };

  const toggleConfirmPassVisibility = () => {
    setConfirmPassVisible(!confirmPassVisible);
  };

  const validateForm = () => {
    const error = {};
    const { new_pass, confirm_pass } = inputData;
    if (!new_pass.trim()) {
      error.new_pass = "Please enter new password.";
    } else if (new_pass.length < 8) {
      error.new_pass = "Password length must be at least 8 characters.";
    }
    if (!confirm_pass.trim()) {
      error.confirm_pass = "Please enter confirm password.";
    } else if (new_pass !== confirm_pass) {
      error.confirm_pass = "Passwords do not match.";
    }
    setInputError(error);
    return Object.keys(error).length === 0;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const isFormValid = validateForm();

    if (isFormValid) {
      try {
        let res = await fetch(POST_RESET_PASS_API, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token,
            password: inputData.confirm_pass,
          }),
        });
        let resJson = await res.json();
        // console.log("resjson", resJson);
        const msg = resJson.message;
        if (msg === "Password has been reset successfully") {
          setShowSuccessfullReset(true);
        }
        // console.log("msg", msg);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="container resetPassMain">
      <div className="card resetPassCard">
        <div className="card-body p-0">
          <div className="text-center">
            <img src={phnElearnImg} alt="Phn-Learn" className="w-50" />
          </div>

          {showSuccessfullReset ? (
            <div className="mt-5 pt-3">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: resetSuccessful,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={150}
                width={150}
              />
              <p class="successfullMSg mb-2 mt-5">Password Reset Successful</p>
              <p className="loginToMsg">Log In to PHN E learn Web Portal</p>
            </div>
          ) : (
            <>
              {" "}
              <p className="resetPass-title mt-4">Reset Password</p>
              <div>
                <form onSubmit={submitHandler}>
                  <div>
                    <label className="resetPass-label_text mb-1">
                      New Password
                    </label>

                    <div class="input-group mb-1">
                      <input
                        type={newPassVisible ? "text" : "password"}
                        class="form-control resetPassInput"
                        className="form-control resetPassInput"
                        onChange={inputHandler}
                        value={inputData.new_pass}
                        name="new_pass"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                      />
                      <button
                        class="btn showPassBtn"
                        type="button"
                        id="button-addon2"
                      >
                        <img
                          src={newPassVisible ? showIcon : hideIcon}
                          alt="eye-icon"
                          className="eye_icon_reset_pass"
                          onClick={toggleNewPassVisibility}
                        />
                      </button>
                    </div>
                    <span className="error-msg">{inputError.new_pass}</span>
                  </div>

                  <div className="mt-2">
                    <label className="resetPass-label_text mb-1">
                      Confirm Password
                    </label>
                    <div className="password-input-container">
                      <div class="input-group mb-1">
                        <input
                          type={confirmPassVisible ? "text" : "password"}
                          class="form-control resetPassInput"
                          onChange={inputHandler}
                          value={inputData.confirm_pass}
                          name="confirm_pass"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                        />
                        <button
                          class="btn showPassBtn"
                          type="button"
                          id="button-addon2"
                        >
                          <img
                            src={confirmPassVisible ? showIcon : hideIcon}
                            alt="eye-icon"
                            className="eye_icon_conf_pass"
                            onClick={toggleConfirmPassVisibility}
                          />
                        </button>
                      </div>
                    </div>
                    <span className="error-msg">{inputError.confirm_pass}</span>
                  </div>

                  <input
                    type="submit"
                    value="Change Password"
                    className="btn loginBtn w-100 mt-4"
                  />
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
