import React, { useEffect, useState } from "react";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import DayDateProfile from "../Components/DayDateProfile";
import editIcon from "../Assets/Images/ManagerDashboard/edit-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import Excelexport from "../Excelexport";
import plusIcon from "../Assets/Images/StudentList/plusIcon.svg";
import LottieAnimation from "../Components/LottieAnimation";
import prevArrow from "../Assets/Images/SuperAdmin/prev_arrow.png";
import nextArrow from "../Assets/Images/SuperAdmin/next_arrow.png";
import { GET_MGR_SCHOOLS_LIST } from "../Components/AllAPI";

const SchoolsList = () => {
  const [searchInput, setSearchInput] = useState("");
  const [schoolsData, setSchoolsData] = useState([]);
  const [apiResult, setApiResult] = useState();
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const navigate = useNavigate();
  let managerSidebar = sidebarData.Manager;

  const allocatedSchools = () => {
    const allocatedFileteredData = schoolsData.filter((data) => {
      return data.trainer_name !== "Unallocated";
    });

    setSchoolsData(allocatedFileteredData);
  };

  const unAllocatedSchools = () => {
    const unallocatedFilteredData = schoolsData.filter((list) => {
      return list.trainer_name === "Unallocated";
    });
    setSchoolsData(unallocatedFilteredData);
  };

  useEffect(() => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(GET_MGR_SCHOOLS_LIST, {
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setApiResult(json);
        setSchoolsData(json.allSchools);
        setIsLoading(false);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  const getBgColor = (school_name_value) => {
    if (school_name_value === "Unallocated") {
      return "#FFF4F0";
    } else if (school_name_value === "allocated") {
      return "#fff";
    }
  };

  $(document).ready(function () {
    $(".schoolList .nav-link").click(function () {
      $(".schoolList .nav-link").removeClass("active");
      $(this).addClass("active");
    });
  });
  useEffect(() => {
    if (schoolsData && schoolsData.length > 0) {
      const filtered = schoolsData.filter((data) => {
        return (
          searchInput.toLowerCase() === "" ||
          data.sch_name.toLowerCase().includes(searchInput) ||
          data.trainer_name.toLowerCase().includes(searchInput) ||
          data.sch_poc_name.toLowerCase().includes(searchInput) ||
          data.sch_address.toLowerCase().includes(searchInput)
        );
      });

      setResults(filtered);
    } else {
      setResults([]);
    }
  }, [schoolsData, searchInput]);

  const totalItems = results ? results.length : 0;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = results
    ? results.slice(indexOfFirstItem, indexOfLastItem)
    : [];
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const PagePerItem = Math.min(totalItems - indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    setCurrentPage(1);
  }, [schoolsData]);

  // Function to handle changing pages
  const handlePageChange = (pageNumber) => {
    setCurrentPage(parseInt(pageNumber));
  };

  // Function to go to the previous page
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Function to go to the next page
  const nextPage = () => {
    if (indexOfLastItem < totalItems) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <Sidebar sidebarLinks={managerSidebar} />
      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          <div className="sidebody schoolList">
            <div className="container">
              {/* <DayDateProfile /> */}
              <div className="pt-4">
                <div className="row">
                  <div className="col-lg-9 col-sm-12 col-md-12 col-12">
                    <p className="studentListTitle">School List</p>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-md-12 col-12">
                    {/* <div className="search"> 
                  <span className="fa fa-search"></span>
                  <input type="text" placeholder="Search" />
                </div> */}

                    <div
                      className="input-group mb-3"
                      style={{ float: "right" }}
                    >
                      <span className="input-group-text" id="basic-addon1">
                        <span className="fa fa-search"></span>
                      </span>
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-9 col-12">
                    <nav className="navbar navbar-expand-lg navbar-light">
                      <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav mr-auto">
                          <li className="nav-item me-3">
                            <Link
                              className="nav-link active"
                              to="#"
                              onClick={() => {
                                setSchoolsData(apiResult.allSchools);
                              }}
                            >
                              All
                            </Link>
                          </li>
                          <li className="nav-item me-3">
                            <Link
                              className="nav-link"
                              to="#"
                              onClick={() => {
                                setSchoolsData(apiResult.allocatedSchools);
                              }}
                            >
                              Allocated
                            </Link>
                          </li>
                          <li className="nav-item me-3">
                            <Link
                              className="nav-link"
                              to="#"
                              onClick={() => {
                                setSchoolsData(apiResult.unAllocatedSchools);
                              }}
                            >
                              Unallocated
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                  <div className="col-lg-3 col-12">
                    <div>
                      <Excelexport
                        excelData={schoolsData}
                        fileName={"Student List"}
                      />

                      <button
                        type="button"
                        className="btn addStudentBtn"
                        onClick={() => navigate("/manager/addschool")}
                      >
                        Add School
                        <img
                          src={plusIcon}
                          alt="plusIcon"
                          className="plusIcon ms-2"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <table className="table table-borderless studentTable">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No.</th>
                      <th scope="col">School Name</th>
                      <th scope="col">Trainer</th>
                      <th scope="col">Trainer Mobile</th>
                      <th scope="col">School POC</th>
                      <th scope="col">Location</th>
                      <th scope="col">Intake</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(results) && results.length > 0 ? (
                      currentItems.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              background: getBgColor(data.trainer_name),
                            }}
                          >
                            <td>{index + indexOfFirstItem + 1}</td>
                            <td>{data.sch_name}</td>
                            <td>{data.trainer_name}</td>
                            <td>{data.trainer_phone}</td>
                            <td>{data.sch_poc_name}</td>
                            <td>{data.sch_address}</td>
                            <td>
                              {data.sch_phn_intake}/{data.sch_total_intake}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              id={`sch_id_${data.sch_id}`}
                            >
                              <Link to={"/manager/editschool"} state={{ data }}>
                                <img src={editIcon} alt="EditIcon" />
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7" className="noDataContainer">
                          <div className="noData">No data available</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {Array.isArray(results) && results.length > 0 && (
                <div className="pagination row">
                  <div className="result_msg col-12 col-lg-3 col-sm-7 col-md-7">
                    <p
                      className="result_msg_text"
                      style={{ marginBottom: "0px" }}
                    >
                      Displaying {PagePerItem} results of {totalItems}
                    </p>
                  </div>

                  <div className="button_module col-12 col-lg-9 col-sm-5 col-md-5 d-flex">
                    <div
                      className="d-flex"
                      style={{
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <p className="paginate_msg_text">Page</p>
                      <div>
                        <select
                          className="form-select pageDropdown"
                          value={currentPage}
                          onChange={(e) => handlePageChange(e.target.value)}
                          style={{width:'116%'}}
                        >
                          {Array.from(
                            { length: Math.ceil(totalItems / itemsPerPage) },
                            (_, i) => i + 1
                          ).map((pageNum) => (
                            <option value={pageNum} key={pageNum}>
                              {pageNum}&nbsp;
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="paginate_msg_text d-flex gap-1">
                        <span>of</span> {Math.ceil(totalItems / itemsPerPage)}
                      </p>
                    </div>

                    <div className=" d-flex">
                      <button
                        type="button"
                        className="bton"
                        onClick={prevPage}
                        disabled={currentPage === 1}
                      >
                        <img src={nextArrow} alt="next" className="arrow_btn" />
                      </button>

                      <button
                        type="button"
                        className="bton"
                        onClick={nextPage}
                        disabled={indexOfLastItem >= totalItems}
                      >
                        <img src={prevArrow} alt="prev" className="arrow_btn" />
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="pagination_div">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-12">
                <div className="show_result d-flex">
                  <p className="show_result_text">Show result:</p>
                  <select
                    name="show_result"
                    id="result_dropdown"
                    onChange={handleSelectChange}
                  >
                    <option value="2">2</option>
                    <option value="4">4</option>
                    <option value="4">6</option>
                    <option value="8">8</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-12">
                <Pagination
                  data={schoolsData}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div> */}
            </div>
          </div>
          {/* <!-- Modal --> */}
          <div
            className="modal fade"
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <p className="modal-title">Edit Trainer</p>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="container mt-3">
                  <form>
                    {" "}
                    <input
                      type="text"
                      placeholder="Name"
                      name="user_name"
                      // onChange={handleChange}
                      className="form-control"
                    />
                    {/* <span className="error_msg">{errors.user_name}</span> */}
                    <input
                      type="text"
                      placeholder="Mobile"
                      name="stu_father_contact"
                      // onChange={handleChange}
                      maxLength={10}
                      minLength={10}
                      className="form-control mt-4"
                    />
                    {/* <span className="error_msg">{errors.stu_father_contact}</span> */}
                    <input
                      type="text"
                      placeholder="Email id"
                      name="email"
                      // onChange={handleChange}
                      className="form-control mt-4"
                    />
                    {/* <span className="error_msg">{errors.email}</span> */}
                    <input
                      type="text"
                      placeholder="Qualification"
                      name="password"
                      // onChange={handleChange}
                      className="form-control mt-4"
                    />
                    {/* <span className="error_msg">{errors.password}</span> */}
                    <div className="text-center my-5">
                      <input
                        type="submit"
                        className="submit_btn"
                        value="Add"
                        // onClick={handleSubmit}
                        id="submit_btn"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SchoolsList;
