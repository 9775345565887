import React, { useState } from "react";
import Sidebar from "./Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import { Link, useLocation } from "react-router-dom";
import DayDateProfile from "./DayDateProfile";
import nextArrow from "../Assets/Images/Dashboard/next_icon.png";
import ReactPlayer from "react-player";
import AllCourseVideoList from "./AllCourseVideoList";
import { ALL_PARTICULAR_CHAPTER_ADD_PROGRESS } from "./AllAPI";
function AllParticularChapter() {
  const location = useLocation();
  const videoData = location.state.videoData;
  // console.log("videoData", videoData);
  let syllabus = location.state.syllabus;
  // console.log("syllData", syllabus);
  const [videoCompleted, setVideoCompleted] = useState(false);
  //   const videoData = location.state.videoData;
  const user_role = localStorage.getItem("user_role");
  let commonSidebar;
  // console.log(user_role);
  if (user_role === "Manager") {
    commonSidebar = sidebarData.Manager;
  } else if (user_role === "Trainer") {
    commonSidebar = sidebarData.Trainer;
  } else if (user_role === "Principal") {
    commonSidebar = sidebarData.Principle;
  }
  const handleSubmit = async () => {
    var token = localStorage.getItem("authToken");
    try {
      let res = await fetch(ALL_PARTICULAR_CHAPTER_ADD_PROGRESS, {
        method: "POST",
        headers: {
          authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          video_id: videoData.vid_id,
        }),
      });
      let resJson = await res.json();
      // console.log(resJson);
      const msg = resJson.message;
      // console.log(msg);
    } catch (err) {
      console.log(err);
    }
  };

  const handleVideoEnded = () => {
    // alert("completed");
    setVideoCompleted(true);
    handleSubmit();
  };

  return (
    <>
      <Sidebar sidebarLinks={commonSidebar} />
      <div className="sidebody">
        <div className="container">
          {/* {user_role === "Trainer" ? "" : <DayDateProfile />} */}

          {/* video details */}
          <div className="ChapterDetails mt-4 mb-5">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/allcourselist" className="prevPageTitle">
                    Courses
                  </Link>
                </li>
                <img src={nextArrow} alt="nextArrow" />
                <li className="breadcrumb-item">
                  <Link
                    className="prevPageTitle"
                    to="/allparticularcourse"
                    state={{ courses: syllabus }}
                  >
                    {`Chapter ${syllabus.chapter_no}`}
                  </Link>
                </li>
                <img src={nextArrow} alt="nextArrow" />

                <p
                  className="currentPageTitle mt-0 mb-0"
                  style={{ color: "#211C37" }}
                >
                  Topic
                </p>
              </ol>
            </nav>
            <p className="Header-Video">{syllabus.chapter_name}</p>
            <div className="row mt-5">
              <div className="col-lg-7 col-md-7">
                <div className="Image-Div">
                  {/* <img
                    className="Video-Image mb-4"
                    src={videoData.videoImg}
                    alt="Video-Preview"
                    width="80%"
                  /> */}
                  {videoData.vid_path === null || videoData.vid_path === "" ? (
                    <img
                      className="Video-Image mb-4"
                      src={videoData.vid_thumbnail}
                      alt="Video-Preview"
                      width="96%"
                    />
                  ) : (
                    <ReactPlayer
                      url={videoData.vid_path}
                      playing
                      width="90%"
                      // height="30rem"
                      controls={true}
                      onEnded={handleVideoEnded}
                    />
                  )}
                </div>
                <div className="Header-video-div mt-4">
                  <div className="Topic-div">
                    <p className="videoTitle">{videoData.vid_title}</p>
                  </div>
                  <div className="chapter-div">
                    <p
                      style={{ marginBottom: "0px" }}
                      className="videoSubTitle"
                    >
                      {videoData.chapterSubTitle}
                    </p>
                  </div>
                </div>
                <div className="Description-Div border-top pt-3">
                  <div className="Description-Header-Text">
                    <p>Description</p>
                  </div>
                  <div className="Description-Text">
                    <p className="desc">{videoData.vid_description}</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 col-md-5 second-column ps-4"
                style={{ borderLeft: "1px solid #E7E6EA" }}
              >
                <div className="Topics-Covered-Div">
                  <p className="Header-Topics">Topics</p>
                </div>
                <AllCourseVideoList
                  syllabusData={syllabus}
                  videoCompleted={videoCompleted}
                  video_id={videoData.vid_id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllParticularChapter;
