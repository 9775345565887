import React, { useEffect, useState } from "react";
import profileImg from "../Assets/Images/StudentList/student_profile.svg";
import "../Assets/Styles/ProfileStudent.css";
import editIcon from "../Assets/Images/ManagerDashboard/edit-icon.svg";
import { Link } from "react-router-dom";
import { GET_PROFILE_STUDENT } from "../Components/AllAPI";

const ProfileStudent = () => {
  const [profile, setProfile] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_PROFILE_STUDENT, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("profile data", data);
        setProfile(data.student_info);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const formatDOB = (dob) => {
    return dob ? dob.split("-").reverse().join("-") : "-";
  };

  const formatPhnEnrollDate = (phnEnrollDate) => {
    return phnEnrollDate ? phnEnrollDate.split("-").reverse().join("-") : "-";
  };

  const convertToRoman = (num) => {
    const romanNumerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    let result = "";

    for (let key in romanNumerals) {
      while (num >= romanNumerals[key]) {
        result += key;
        num -= romanNumerals[key];
      }
    }

    return result;
  };

  const convertListToRoman = (input) => {
    if (!input || typeof input !== "string") return "-";

    const numbers = input.split(",").map(Number);
    return numbers.map(convertToRoman).join(",");
  };

  const romanNumeralsString = convertListToRoman(profile.stu_grade);

  return (
    <>
      <section className="profile">
        <div className="container">
          <div className="row">
            <div className="col-7">
              <p className="profile_title">Profile</p>
            </div>
            <div className="col-4">
              <Link to={"/student/edit-profile"} state={{ profile }}>
                <img src={editIcon} alt="EditIcon" />
              </Link>
            </div>
          </div>
          <div className="blur_img w-25 h-25" style={{ borderRadius: "50px" }}>
            {profile.stu_image === null || profile.stu_image === "" ? (
              <img
                src={profileImg}
                alt="profile_image"
                width={100}
                height={100}
                style={{ borderRadius: "50px" }}
                onError={(e) => (e.target.src = profileImg)}
              />
            ) : (
              <img
                src={profile.stu_image}
                alt="profile_image"
                width={100}
                height={100}
                style={{ borderRadius: "50px" }}
                onError={(e) => (e.target.src = profileImg)}
              />
            )}
          </div>
          <p className="student_name">
            {profile.stu_fname || "-"} {profile.stu_mname || "-"}{" "}
            {profile.stu_lname || "-"}
          </p>
          <p className="grade">
            Grade:{" "}
            <span style={{ fontWeight: "600", color: "black" }}>
              {romanNumeralsString || "-"}
            </span>
          </p>

          <div className="stud_data">
            <div className="row">
              <div className="col-6">
                <p className="data_title mb-0">DOB</p>
                <p className="data_subititle">{formatDOB(profile.stu_dob)}</p>

                <p className="data_title  mb-0">PHN Enrolled Date</p>
                <p className="data_subititle">
                  {formatPhnEnrollDate(profile.stu_phn_enroll_date)}
                </p>
              </div>
              <div className="col-6">
                <p className="data_title  mb-0">Blood Group</p>
                <p className="data_subititle">{profile.stu_blood_grp || "-"}</p>
                <p className="stud_addres_title  mb-0">Address</p>
                <p className="stud_address_subtitle">
                  {profile.stu_address || "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileStudent;
