import React from "react";
import "../Assets/Styles/Dashboard.css";
const DayDateProfile = () => {
  const getCurrentDay = () => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date();
    const currentDayIndex = currentDate.getDay();
    return days[currentDayIndex];
  };

  const getFormattedDate = () => {
    const currentDate = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return currentDate.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <div className="dayAndDate">
        <div className="row">
          <div className="col-lg-10 col-sm-12 col-md-12 col-12">
            <p className="day">
              {getCurrentDay()}
              <span className="date ms-2">{getFormattedDate()}</span>
            </p>
          </div>
          {/* <div className="col-lg-2 col-sm-12 col-md-12 col-12 m-auto">
            <p className="profileInitial">M</p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default DayDateProfile;
