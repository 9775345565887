import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import "../Assets/Styles/Syllabus.css";
import projectLogo from "../Assets/Images/Dashboard/projectLogo.png";
import DayDateProfile from "../Components/DayDateProfile";
import LottieAnimation from "../Components/LottieAnimation";
import { GET_PROJECTS, GET_SYLLABUS } from "../Components/AllAPI";
const Syllabus = () => {
  let studentSidebar = sidebarData.Student;
  const [syllabus, setSyllabus] = useState([]);
  const [isActive, setIsActive] = useState("Chapter");
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(GET_SYLLABUS, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("syll daata", data);

        if (data && data.syllabus && data.syllabus.length > 0) {
          setSyllabus(data.syllabus);
        } else {
          setSyllabus([]);
        }

        setIsLoading(false);
      });
  };

  const projectApi = () => {
    setIsLoading(true);

    var token = localStorage.getItem("authToken");
    // console.log(token);
    fetch(GET_PROJECTS, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("project", data);

        if (data && data.projects && data.projects.length > 0) {
          setSyllabus(data.projects);
        } else {
          setSyllabus([]);
        }
        setIsLoading(false);
      });
  };

  // console.log(syllabus);
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          <Sidebar sidebarLinks={studentSidebar} />

          <div className="sidebody">
            <div className="container">
              <DayDateProfile />
              <p className="syllabus_title mt-4">Syllabus</p>
              {/* for navbar */}
              <div className="row">
                <div className="routes-button-div ">
                  <Link
                    to=""
                    className={`${
                      isActive === "Chapter"
                        ? "btn routes-button active"
                        : "btn routes-button"
                    }`}
                    onFocus={() => setIsActive("Chapter")}
                    onClick={fetchData}
                  >
                    Chapter
                  </Link>
                  <Link
                    to=""
                    className={`${
                      isActive === "Projects"
                        ? "btn routes-button active"
                        : "btn routes-button"
                    }`}
                    onFocus={() => setIsActive("Projects")}
                    onClick={projectApi}
                  >
                    Projects
                  </Link>
                </div>
              </div>

              {/* syllabus */}
              {syllabus.length === 0 && (
                <div className="text-center">
                  <div className="noData">No data available</div>
                </div>
              )}
      
                <div className="row row-col-auto">
                  {syllabus.map((data, ind) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 col-12"
                      key={ind}
                    >
                      <div>
                        {isActive === "Chapter" ? (
                          <Link
                            to="/student/particular-project"
                            style={{ color: "black", textDecoration: "none" }}
                            state={{ data, isActive }}
                          >
                            <>
                              <img
                                src={data.syll_image}
                                alt="syllabus_img"
                                className="Syllabus_Images"
                                width={300}
                              />
                              <div className="chapter_desc">
                                <p className="chapter_number">{`Chapter ${data.syll_chapter_no}`}</p>
                                <p className="chapter_title">
                                  {data.syll_title}
                                </p>
                              </div>
                            </>
                          </Link>
                        ) : (
                          <Link
                            to="/student/particular-project"
                            state={{ data, isActive }}
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <>
                              <img
                                src={data.proj_image}
                                alt="syllabus_img"
                                className="Syllabus_Images"
                                width={300}
                              />
                              <div className="chapter_desc">
                                <div className="d-flex">
                                  <img
                                    src={projectLogo}
                                    alt="projectLogo"
                                    width={38}
                                    height={38}
                                    className="me-2"
                                  />
                                  <div>
                                    <p className="chapter_number">{`Chapter ${data.proj_chapter_no}`}</p>
                                    <p className="chapter_title">
                                      {data.proj_title}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          </Link>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
             
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Syllabus;
