import React, { useState } from "react";
import plusIcon from "../Assets/Images/StudentList/plusIcon.svg";
import "../Assets/Styles/Videos.css";
import { POST_MGR_ADD_TRAINER_API } from "../Components/AllAPI";
import SimpleLoader from "../Components/SimpleLoader";
import { ToastContainer, toast } from "react-toastify";

const AddTrainer = () => {
  const initialData = {
    user_name: "",
    user_phone: "",
    email: "",
    tra_qualification: "",
    tra_experience: "",
  };
  const [sendData, setSendData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSendData((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const validateForm = (values) => {
    const error = {};
    const userNameRegx = /^[A-Z a-z]+$/;
    const mobileRegx = /^[6789][0123456789]{9}$/;
    const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z][a-z.A-Z]+$/;

    if (values.user_name === "") {
      error.user_name = "Please enter name.";
    } else if (userNameRegx.test(values.user_name) === false) {
      error.user_name = "Please enter valid name.";
    }
    if (values.user_phone === "") {
      error.user_phone = "Please enter mobile no.";
    } else if (mobileRegx.test(values.user_phone) === false) {
      error.user_phone = "Please enter valid mobile no.";
    }
    if (values.email === "") {
      error.email = "Please enter email id.";
    } else if (emailRegx.test(values.email) === false) {
      error.email = "Please enter valid email id.";
    }
    if (values.tra_qualification === "") {
      error.tra_qualification = "Please enter qualification.";
    }
    if (values.tra_experience === "") {
      error.tra_experience = "Please select experience.";
    }
    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors(validateForm(sendData));

    var token = localStorage.getItem("authToken");

    if (Object.keys(validateForm(sendData)).length === 0) {
      setIsLoading(true);
      try {
        let res = await fetch(POST_MGR_ADD_TRAINER_API, {
          method: "POST",
          headers: {
            authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_name: sendData.user_name,
            user_phone: sendData.user_phone,
            email: sendData.email,
            tra_qualification: sendData.tra_qualification,
            tra_experience: sendData.tra_experience,
          }),
        });
        let resJson = await res.json();
        // console.log(resJson);
        const msg = resJson.message;
        if (msg === "User created successfully") {
          toast.success("Trainer Added successfully", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => {
              setIsLoading(false);
              setTimeout(window.location.reload());
            },
          });
        } else if (msg === "Trainer with this email already exists.") {
          toast.error("Trainer with email id already exis", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setErrors(validateForm(sendData));
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    }
  };

  const vanishHandler = () => {
    setErrors("");
  };

  return (
    <>
      <button
        type="button"
        className="btn addStudentBtn"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      >
        Add Trainer
        <img src={plusIcon} alt="plusIcon" className="plusIcon ms-2" />
      </button>

      {/* <!-- Modal --> */}
      <div
        className="modal fade addTrainer"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <p className="modal-title">Add Trainer</p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={vanishHandler}
              ></button>
            </div>

            <div className="container mt-3" style={{ position: "relative" }}>
              {isLoading && (
                <div className="simple-loader">
                  <SimpleLoader />
                </div>
              )}
              <form>
                {" "}
                <input
                  type="text"
                  placeholder="Name"
                  name="user_name"
                  onChange={handleChange}
                  className="form-control vanishData"
                />
                <span className="error_msg vanishData">{errors.user_name}</span>
                <input
                  type="text"
                  placeholder="Mobile"
                  name="user_phone"
                  onChange={handleChange}
                  maxLength={10}
                  minLength={10}
                  className="form-control mt-4 vanishData"
                />
                <span className="error_msg vanishData">
                  {errors.user_phone}
                </span>
                <input
                  type="text"
                  placeholder="Email id"
                  name="email"
                  onChange={handleChange}
                  className="form-control mt-4 vanishData"
                />
                <span className="error_msg vanishData">{errors.email}</span>
                <input
                  type="text"
                  placeholder="Qualification"
                  name="tra_qualification"
                  onChange={handleChange}
                  className="form-control mt-4 vanishData"
                />
                <span className="error_msg vanishData">
                  {errors.tra_qualification}
                </span>
                <div className="my-4">
                  <select
                    name="tra_experience"
                    id="workExpDropdown"
                    onChange={handleChange}
                    className="vanishData"
                  >
                    <option value="">Work Experience</option>
                    <option value="Fresher">Fresher</option>
                    <option value="1-2 yrs">1-2 yrs</option>
                    <option value="2-3 yrs">2-3 yrs</option>
                    <option value="3-4 yrs">3-4 yrs</option>
                    <option value="4-5 yrs">4-5 yrs</option>
                    <option value="5-6 yrs">5-6 yrs</option>
                    <option value="6-7 yrs">6-7 yrs</option>
                    <option value="7-8 yrs">7-8 yrs</option>
                    <option value="8-9 yrs">8-9 yrs</option>
                    <option value="9-10 yrs">9-10 yrs</option>
                    <option value="above 10 yrs">Above 10 yrs</option>
                  </select>
                  <span className="error_msg">{errors.tra_experience}</span>
                </div>
                <div className="text-center my-5">
                  <input
                    type="submit"
                    className="submit_btn"
                    value="Add"
                    onClick={handleSubmit}
                    id="submit_btn"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTrainer;
