import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import { Route, useNavigate } from "react-router-dom";
import ManagerChart from "./ManagerChart";
import DayDateProfile from "../Components/DayDateProfile";
import ProfileManager from "./ProfileManager";
import sidebarData from "../Assets/Json/sidebarJson.json";
import RecentlyAddedSchools from "./RecentlyAddedSchools";
import LottieAnimation from "../Components/LottieAnimation";
import { GET_MGR_DASHBOARD_COUNT } from "../Components/AllAPI";

function ManagerDashboard() {
  const managerSidebar = sidebarData.Manager;
  const [schoolCount, setSchoolCount] = useState([]);
  const [trianerCount, setTrainerCount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(GET_MGR_DASHBOARD_COUNT, {
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log('manager json',json);
        setSchoolCount(json.school_counts);
        setTrainerCount(json.trainer_counts);
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    // Prevent going back to the previous page after logging out
    const clearHistory = () => {
      window.history.pushState(null, window.location.pathname);
      window.onpopstate = () => {
        navigate("/managerdashboard");
      };
    };
    clearHistory();
    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);
  return (
    <>
      <Sidebar sidebarLinks={managerSidebar}></Sidebar>

      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          {" "}
          <section className="sidebody">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-12">
                  {/* <DayDateProfile /> */}

                  <div className="schoolAndTrainerCount">
                    <div className="row parent">
                      <div className="col-12 col-lg-6">
                        <div className="card  header main1 ">
                          <b>Schools</b>
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="container-1  vertical-line">
                              <p className="Numbers">
                                {schoolCount.total_schools}
                              </p>
                              <p className="left-text">Total</p>
                            </div>

                            <div className="container-2">
                              <p className="right-number">
                                {schoolCount.unassigned_schools}
                              </p>
                              <p className="right-text">Unallocated</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-6 ">
                        <div className="card header main2">
                          <b>Trainers</b>
                          <div className="d-flex align-items-center justify-content-center mt-4">
                            <div className="container-1 vertical-line">
                              <p className="Numbers">
                                {trianerCount.total_trainers}
                              </p>
                              <p className="left-text">Total</p>
                            </div>

                            <div className="container-2">
                              <p className="right-number">
                                {trianerCount.unassigned_trainers}
                              </p>
                              <p className="right-text">Unallocated</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ManagerChart />
                  <br />

                  <div>
                    <RecentlyAddedSchools />
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-12 col-12 mt-5 pt-5 ps-4"
                  style={{ borderLeft: "2px solid #F3F5F7" }}
                >
                  <ProfileManager />
                  <div className="mb-5"></div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default ManagerDashboard;
