import React, { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import excelIcon from "../Assets/Images/StudentList/excelIcon.svg";
function Bulkupload(props) {

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        resolve(excelData);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const exportToExcel = (data, filename) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, filename);
  };

  const saveToDatabase = async (data) => {

    var authToken = localStorage.getItem("authToken");
    const response = await fetch(props.bulkuploadAPI, {
      method: "post",
      headers: {
        authorization: "Bearer " + authToken,
              Accept: "application/json",
              "Content-Type": "application/json",
      },
      body: JSON.stringify(data)

    });
    let resJson = await response.json();
  };

  const handleImport = async () => {
    try {
      const excelData = await readExcelFile(selectedFile);
      alert("File uploaded successfully");
  
      // Call a function to send data to the database
      saveToDatabase(excelData);

      // Optionally, export the data to Excel
      //   exportToExcel(excelData, 'exported_data.xlsx');
    } catch (error) {
      console.error("Error reading Excel file:", error);
    }
  };
  return (
    <div>
      <button className="btn button_layer" type="button" onClick={handleImport}>
        {" "}
        <input
          type="file"
          className="bulkuploadfile"
          onChange={handleFileChange}
        />{" "}
        <button type="button" class="btn btn-primary">
          Bulk Upload
        </button>
      </button>
    </div>
  );
}

export default Bulkupload;
