import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Components/Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import "../Assets/Styles/AddSchool.css";
import { useNavigate } from "react-router-dom";
import uploadIcon from "../Assets/Images/ManagerDashboard/upload-icon.svg";
import StatesData from "../Assets/Json/data.json";
import deleteIcon from "../Assets/Images/ManagerDashboard/delete-icon.svg";
import {
  POST_MGR_ADD_SCHOOL_API,
  GET_MGR_ADD_SCHOOL_ALLOCATE_TRAINER_API,
} from "../Components/AllAPI";
import SimpleLoader from "../Components/SimpleLoader";
import { ToastContainer, toast } from "react-toastify";

const AddSchool = () => {
  const [agreement, setAgreement] = useState(false);

  let managerSidebar = sidebarData.Manager;
  const initialData = {
    school_name: "",
    maharashtra_state_board: "",
    total_school_intake: "",
    school_logo: "",
    state: "",
    district: "",
    principle_name: "",
    principle_mobile: "",
    principle_email: "",
    poc_name: "",
    poc_mobile: "",
    poc_emailid: "",
    phn_intake: "",
    address: "",
    phn_enrollment_date: "",
    allocate_trainer: "",
    grade: "",
  };
  const [errors, setErrors] = useState({});
  const [sendData, setSendData] = useState(initialData);
  const [sendGrade, setSendGrade] = useState({ gradeValue: [] });
  const [trainerData, setTrainerData] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedstates, setSelectedStates] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [showError, setShowError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [principalDetails, setPrincipalDetails] = useState({
    principle_name: "",
    principle_mobile: "",
    principle_email: "",
  });
  const [schoolPOC, setSchoolPOC] = useState({
    sameAsPrincipal: false,
    principle_name: "",
    principle_mobile: "",
    principle_email: "",
  });
  const [pocChecked, setPocChecked] = useState(false);

  const getState = async () => {
    setStates(StatesData.states);
  };

  const handleChange = (e) => {
    if (e.target.name === "state") {
      setSelectedStates(e.target.value);
      setDistrict(StatesData.states[e.target.value].districts);
      setSelectedStates(StatesData.states[e.target.value].state);
    }

    if (e.target.name === "principle_name") {
      let field = e.target.name;
      let value = e.target.value;

      setPrincipalDetails((prevDetails) => ({
        ...prevDetails,
        [field]: value,
      }));
    }

    if (e.target.name === "principle_mobile") {
      let field = e.target.name;
      let value = e.target.value;

      setPrincipalDetails((prevDetails) => ({
        ...prevDetails,
        [field]: value,
      }));
    }

    if (e.target.name === "principle_email") {
      let field = e.target.name;
      let value = e.target.value;

      setPrincipalDetails((prevDetails) => ({
        ...prevDetails,
        [field]: value,
      }));
    }

    if (e.target.name === "poc_name") {
      let field = "principle_name";
      let values = e.target.value;

      setSchoolPOC((prevPOC) => ({
        ...prevPOC,
        [field]: values,
      }));
    }

    if (e.target.name === "poc_mobile") {
      let field = "principle_mobile";
      let values = e.target.value;

      setSchoolPOC((prevPOC) => ({
        ...prevPOC,
        [field]: values,
      }));
    }

    if (e.target.name === "poc_emailid") {
      let field = "principle_email";
      let values = e.target.value;

      setSchoolPOC((prevPOC) => ({
        ...prevPOC,
        [field]: values,
      }));
    }

    const { name, value } = e.target;
    setSendData((values) => ({
      ...values,
      [name]: value,
    }));
  };
  const gradeNo = sendGrade.gradeValue;
  const strGrade = gradeNo.toString();

  const validateForm = (values) => {
    const error = {};
    const userNameRegx = /^[A-Z a-z . , & ']+$/;
    const mobileRegx = /^[6789][0123456789]{9}$/;
    const intakeRegx = /^[0-9]*$/;
    const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z][a-z.A-Z]+$/;

    if (strGrade.length === 0) {
      error.grade = "Please select grade";
    }
    if (values.school_name === "") {
      error.school_name = "Please enter school name.";
    } else if (userNameRegx.test(values.school_name) === false) {
      error.school_name = "Please enter valid school name.";
    }
    if (values.principle_name === "") {
      error.principle_name = "Please enter principal name.";
    } else if (userNameRegx.test(values.principle_name) === false) {
      error.principle_name = "Please enter valid principal name.";
    }

    if (values.poc_name === "") {
      error.poc_name = "Please enter poc name.";
    } else if (userNameRegx.test(values.poc_name) === false) {
      error.poc_name = "Please enter valid poc name.";
    }
    if (values.principle_mobile === "") {
      error.principle_mobile = "Please enter principal mobile no.";
    } else if (mobileRegx.test(values.principle_mobile) === false) {
      error.principle_mobile = "Please enter valid mobile no.";
    }

    if (values.poc_mobile === "") {
      error.poc_mobile = "Please enter poc mobile no.";
    } else if (mobileRegx.test(values.poc_mobile) === false) {
      error.poc_mobile = "Please enter valid mobile no.";
    }
    if (values.principle_email === "") {
      error.principle_email = "Please enter principal email id.";
    } else if (emailRegx.test(values.principle_email) === false) {
      error.principle_email = "Please enter valid email id.";
    }
    if (values.poc_emailid === "") {
      error.poc_emailid = "Please enter poc email id.";
    } else if (emailRegx.test(values.poc_emailid) === false) {
      error.poc_emailid = "Please enter valid email id.";
    }
    if (values.address === "") {
      error.address = "Please enter address.";
    }
    if (values.state === "") {
      error.state = "Please select state.";
    }
    if (values.district === "") {
      error.district = "Please select district.";
    }
    // if (values.allocate_trainer === "") {
    //   error.allocate_trainer = "Please select allocated trainer.";
    // }
    if (values.maharashtra_state_board === "") {
      error.maharashtra_state_board = "Please select board.";
    }

    if (values.phn_intake === "") {
      error.phn_intake = "Please enter PHN intake.";
    } else if (intakeRegx.test(values.phn_intake) === false) {
      error.phn_intake = "Please enter valid PHN intake.";
    }

    if (values.total_school_intake === "") {
      error.total_school_intake = "Please enter total school intake.";
    } else if (intakeRegx.test(values.total_school_intake) === false) {
      error.total_school_intake = "Please enter valid total school intake.";
    }
    if (values.phn_enrollment_date === "") {
      error.phn_enrollment_date = "Please enter PHN enrollment date.";
    }
    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors(validateForm(sendData));

    var token = localStorage.getItem("authToken");

    if (Object.keys(validateForm(sendData)).length === 0) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("sch_logo", selectedFile);

        const jsonData = {
          sch_name: sendData.school_name,
          sch_tra_id: sendData.allocate_trainer,
          sch_princi_name: sendData.principle_name,
          sch_princi_contact: sendData.principle_mobile,
          sch_princi_email: sendData.principle_email,
          sch_poc_name: sendData.poc_name,
          sch_poc_contact: sendData.poc_mobile,
          sch_poc_email: sendData.poc_emailid,
          sch_address: sendData.address,
          sch_board: sendData.maharashtra_state_board,
          sch_total_intake: sendData.total_school_intake,
          sch_phn_intake: sendData.phn_intake,
          sch_district: sendData.district,
          sch_state: selectedstates,
          sch_phn_enrolled_grades: strGrade,
          sch_phn_enrollment_date: sendData.phn_enrollment_date,
        };

        for (const key in jsonData) {
          formData.append(key, jsonData[key]);
        }

        const res = await fetch(POST_MGR_ADD_SCHOOL_API, {
          method: "POST",
          headers: {
            authorization: "Bearer " + token,
            Accept: "application/json",
          },
          body: formData,
        });

        let resJson = await res.json();
        // console.log(resJson);
        const msg = resJson.message;
        const status_code = resJson.status_code;
        if (msg === "School created successfully") {
          toast.success("School created successfully", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => {
              setIsLoading(false);
              navigate("/manager/schoollist");
              setTimeout(window.location.reload(), 50);
            },
          });
        } else if (status_code === 422) {
          setIsLoading(false);
          toast.error("Principal with this email id already exists with us", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          // setTimeout(window.location.reload(), 50);
        } else {
          setIsLoading(false);
          setErrors(validateForm(sendData));
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    }
  };

  // console.log("selected file", selectedFile);

  const handlePOCCheckboxChange = (e) => {
    setAgreement(e.target.checked);
    setSchoolPOC(principalDetails);

    if (e.target.checked === true) {
      setShowError(false);
      setSendData((values) => ({
        ...values,
        poc_name: principalDetails.principle_name,
        poc_emailid: principalDetails.principle_email,
        poc_mobile: principalDetails.principle_mobile,
      }));
    } else {
      setShowError(true);
      setSendData((values) => ({
        ...values,
        poc_name: "",
        poc_emailid: "",
        poc_mobile: "",
      }));
    }
  };

  const gradeList = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
  ];

  //to get id of that selected state
  const handlestatesChange = (id) => {
    const getStateId = id;
    setSendData((values) => ({
      state: getStateId,
    }));
    setSelectedStates(getStateId);
    setDistrict(StatesData.states[getStateId].districts);
  };

  useEffect(() => {
    var token = localStorage.getItem("authToken");

    fetch(GET_MGR_ADD_SCHOOL_ALLOCATE_TRAINER_API, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTrainerData(data.trainers);
      });
    getState();
  }, []);

  const selectGrade = (e) => {
    const { value, checked } = e.target;
    // console.log(`${value} is ${checked}`);
    const { gradeValue } = sendGrade;
    if (checked) {
      setSendGrade({
        gradeValue: [...gradeValue, value],
      });
    }
    // Case 2  : The user unchecks the box
    else {
      setSendGrade({
        gradeValue: gradeValue.filter((e) => e !== value),
      });
    }
  };

  const changeToDate = () => {
    document.getElementById("date").type = "date";
  };

  const changeToText = () => {
    document.getElementById("date").type = "text";
  };

  const yesBtnHandler = () => {
    navigate("/manager/schoollist");
    window.location.reload();
  };

  const uploadHandler = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const imgClickHandler = () => {
    inputRef.current.click();
  };
  const deleteHandler = () => {
    setSelectedFile(null);
    // Reset the input value to allow selecting the same file again
    inputRef.current.value = null;
  };
  return (
    <>
      <Sidebar sidebarLinks={managerSidebar} />
      {isLoading && (
        <div className="simple-loader" style={{ zIndex: "1000" }}>
          <SimpleLoader />
        </div>
      )}
      <section className="AddSchool sidebody">
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <div className="container w-75">
          <div className="row">
            <div className="col-md-6">
              <h3 className="headers">School Details</h3>
              <div className="input-error">
                <label className="small-headers mb-2">School Name</label>
                <div className="input-group inputs">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="School Name*"
                    name="school_name"
                    onChange={handleChange}
                  />
                </div>
                <span className="error_msg">{errors.school_name}</span>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">Board</label>
                <div className="custom-dropdown inputs">
                  <select
                    className="custom-select"
                    name="maharashtra_state_board"
                    onChange={handleChange}
                    style={{
                      borderRadius: "16px",
                      border: "1px solid var(--text-20-stroke, #E7E6EA)",
                    }}
                  >
                    <option value="">Board*</option>
                    <option value="State Board">State Board</option>
                    <option value="CBSE">CBSE</option>
                    <option value="ICSE">ICSE</option>
                    <option value="NIOS">NIOS</option>
                    <option value="IB">IB</option>
                    <option value="CIE">CIE</option>
                  </select>
                </div>
                <span className="error_msg">
                  {errors.maharashtra_state_board}
                </span>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">
                  Total School Intake
                </label>
                <div className="input-group inputs">
                  <input
                    type="text"
                    className="form-control"
                    name="total_school_intake"
                    placeholder="Total School Intake*"
                    onChange={handleChange}
                    maxLength={6}
                  />
                </div>

                <span className="error_msg">{errors.total_school_intake}</span>
              </div>
              <div className="input-error">
                <div className="inputs">
                  <label
                    htmlFor="formFileMultiple"
                    className="form-label small-headers"
                  >
                    School Logo
                  </label>
                  <div className="file-upload">
                    {selectedFile ? (
                      <>
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="upload-icon"
                          height={80}
                          className=""
                          id="imgSelected"
                        />
                        <button
                          type="button"
                          className="btn mt-3"
                          accept="image/png, image/jpeg"
                          style={{ float: "right" }}
                          onClick={deleteHandler}
                        >
                          <img src={deleteIcon} alt="deleteIcon" />
                        </button>
                      </>
                    ) : (
                      <>
                        <div onClick={imgClickHandler}>
                          <img src={uploadIcon} alt="upload-icon" height={80} />
                          <button
                            type="button"
                            className="btn mt-3"
                            accept="image/png, image/jpeg"
                            style={{ float: "right" }}
                            // onClick={imgClickHandler}
                          >
                            Upload
                          </button>
                        </div>
                      </>
                    )}
                    <input
                      type="file"
                      onChange={uploadHandler}
                      accept="image/png, image/jpeg"
                      ref={inputRef}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">State</label>

                <div className="custom-dropdown inputs">
                  <select
                    className="custom-select"
                    name="state"
                    onChange={handleChange}
                    style={{
                      borderRadius: "16px",
                      border: "1px solid var(--text-20-stroke, #E7E6EA)",
                    }}
                  >
                    {/* <option value="">State*</option> */}
                    {states.map((indianStates) => (
                      <option key={indianStates.id} value={indianStates.id}>
                        {indianStates.state}
                      </option>
                    ))}
                  </select>
                </div>
                <span className="error_msg">{errors.state}</span>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">District</label>

                <div className="inputs">
                  <select
                    className="custom-select"
                    name="district"
                    onChange={handleChange}
                    style={{
                      borderRadius: "16px",
                      border: "1px solid var(--text-20-stroke, #E7E6EA)",
                    }}
                  >
                    <option value="">District*</option>
                    {district.map((disName, index) => (
                      <option key={index} value={disName}>
                        {disName}
                      </option>
                    ))}
                  </select>
                </div>

                <span className="error_msg">{errors.district}</span>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">Address</label>

                <div className="inputs">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address*"
                    name="address"
                    onChange={handleChange}
                  />
                </div>
                <span className="error_msg">{errors.address}</span>
              </div>
            </div>
            <div className="col-md-6">
              <h3 className="headers contacts-gap">Contacts</h3>
              <p className="small-headers">Principal Details</p>
              <div className="input-error">
                <label className="small-headers mb-2">Principal Name</label>

                <div className="inputs">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleDate"
                    name="principle_name"
                    onChange={handleChange}
                    placeholder="Principal Name*"
                  />
                </div>
                <span className="error_msg">{errors.principle_name}</span>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">Principal Mobile</label>

                <div className="inputs">
                  <input
                    type="tel"
                    className="form-control"
                    id="exampleDate"
                    name="principle_mobile"
                    onChange={handleChange}
                    placeholder="Principal Mobile* "
                    maxLength={10}
                    minLength={10}
                  />
                </div>

                <span className="error_msg">{errors.principle_mobile}</span>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">Principal Email Id</label>

                <div className="inputs">
                  <input
                    type="email"
                    className="form-control"
                    name="principle_email"
                    id="exampleDate"
                    onChange={handleChange}
                    placeholder="Principal Email Id* "
                  />
                </div>

                <span className="error_msg">{errors.principle_email}</span>
              </div>
              <div className="boundary-1"></div>
              <p className="small-headers">
                <b></b>School POC
              </p>
              <div className="form-check input-poc ps-0 inputs">
                <div className="POC-inner-div">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="agreement"
                    value=""
                    checked={schoolPOC.sameAsPrincipal}
                    onChange={handlePOCCheckboxChange}
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label ms-3"
                    htmlFor="flexCheckDefault"
                  >
                    POC same as Principal Details
                  </label>
                </div>
              </div>
              <div className="input-error mt-3">
                <label className="small-headers mb-2">POC Name</label>

                <div className="inputs">
                  <input
                    type="name"
                    className="form-control"
                    name="poc_name"
                    value={
                      pocChecked ? schoolPOC.sch_poc_name : sendData.poc_name
                    }
                    onChange={handleChange}
                    placeholder="POC Name* "
                    readOnly={agreement}
                  />
                </div>
                {showError ? (
                  <span className="error_msg" id="hidePocName">
                    {errors.poc_name}
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">POC Mobile</label>

                <div className="inputs">
                  <input
                    type="mobile"
                    className="form-control"
                    name="poc_mobile"
                    value={
                      pocChecked
                        ? schoolPOC.sch_poc_contact
                        : sendData.poc_mobile
                    }
                    onChange={handleChange}
                    placeholder="POC Mobile* "
                    maxLength={10}
                    minLength={10}
                    readOnly={agreement}
                  />
                </div>
                {showError ? (
                  <span className="error_msg" id="hidePocMobile">
                    {errors.poc_mobile}
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">POC Email Id</label>

                <div className="inputs">
                  <input
                    type="email"
                    className="form-control"
                    name="poc_emailid"
                    value={
                      pocChecked
                        ? schoolPOC.sch_poc_email
                        : sendData.poc_emailid
                    }
                    onChange={handleChange}
                    placeholder="POC Email Id* "
                    readOnly={agreement}
                  />
                </div>
                {showError ? (
                  <span className="error_msg" id="hidePocEmail">
                    {errors.poc_emailid}
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="div-for-grade-tabs"></div>
            </div>
            <div className="boundary-2"></div>
            <p className="headers Enrollment-block">Enrollment</p>
            <div className="col-md-6 enrolment-gap">
              <div className="input-error">
                <label className="small-headers mb-2">PHN Intake</label>

                <div className="inputs">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="PHN Intake*"
                    name="phn_intake"
                    onChange={handleChange}
                    maxLength={6}
                  />
                </div>
                <span className="error_msg">{errors.phn_intake}</span>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">
                  PHN Enrollment Date
                </label>

                <div className="inputs">
                  <input
                    type="date"
                    id="date"
                    className="form-control"
                    placeholder="PHN Enrollment Date* "
                    name="phn_enrollment_date"
                    onChange={handleChange}
                    pattern="\d{2}\/\d{2}\/\d{4}"
                  />
                </div>
                <span className="error_msg">{errors.phn_enrollment_date}</span>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">Allocate a Trainer</label>

                <div className="inputs">
                  <select
                    className="custom-select"
                    name="allocate_trainer"
                    onChange={handleChange}
                    style={{
                      borderRadius: "16px",
                      border: "1px solid var(--text-20-stroke, #E7E6EA)",
                    }}
                  >
                    <option value="">Allocate a Trainer</option>
                    {trainerData.map((data, ind) => {
                      return (
                        <option value={data.tra_id} key={ind}>
                          {data.tra_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <span className="error_msg">{errors.allocate_trainer}</span>
              </div>
              <div className="Gap"></div>
            </div>
            <div className="col-md-6">
              <p className="small-headers">Enrolled Grades*</p>
              <div className="grade_selection">
                <div className="row ms-2">
                  {gradeList.map((grade, ind) => {
                    return (
                      <div
                        className="col-sm-2 col-2 col-lg-2 text-center"
                        style={{ cursor: "pointer" }}
                        key={ind}
                      >
                        <input
                          type="checkbox"
                          name={ind + 1}
                          value={ind + 1}
                          onChange={selectGrade}
                          className="gradeCheckboxes"
                        />
                        <p className="">{grade}</p>
                      </div>
                    );
                  })}
                </div>
                <span className="error_msg">{errors.grade}</span>
              </div>
            </div>

            <div className="row mt-4 mb-5">
              <div className="col-md-6">
                <div>
                  <div>
                    <button
                      type="button"
                      className="btn  cancel-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#cancelSchool"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <button
                    type="submit"
                    className="btn add-btn"
                    onClick={handleSubmit}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal --> */}

        <div
          className="modal fade "
          id="cancelSchool"
          tabIndex="-1"
          aria-labelledby="cancelSchoolLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content deleteSchoolModal">
              <div className="modal-header">
                <p className="deleteTitle">Cancel School</p>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <p className="deleteMsg ms-3">Are you sure you want to Cancel </p>

              <div className="row mt-3 mb-3">
                <div className="col-lg-6 col-sm-12 col-md-6">
                  <button className="btn yes_btn" onClick={yesBtnHandler}>
                    Yes
                  </button>
                </div>

                <div className="col-lg-6 col-sm-12 col-md-6">
                  <button
                    className="btn no_btn"
                    data-bs-dismiss="modal"
                    // onClick={hideEditModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddSchool;
