import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import "../Assets/Styles/Syllabus.css";
import projectLogo from "../Assets/Images/Dashboard/projectLogo.png";
import DayDateProfile from "../Components/DayDateProfile";
import LottieAnimation from "../Components/LottieAnimation";
import syllabusImg from "../Assets/Images/Dashboard/firstLesson.png";
import {
  GET_PROJECT_LIST_API,
  GET_CHAPTER_LIST_API,
} from "../Components/AllAPI";

const CoursesList = () => {
  let studentSidebar = sidebarData.Student;

  const [syllabus, setSyllabus] = useState([]);
  const [isActive, setIsActive] = useState(
    localStorage.getItem("courseChapterActive") === "Projects"
      ? "Projects"
      : "Chapter"
  );
  const [isLoading, setIsLoading] = useState(true);
  const [vidStatus, setVidStatus] = useState({ isChecked: false });
  const [showChapterData, setShowChapterData] = useState(true);
  const [showProjectData, setShowProjectData] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [results, setResults] = useState([]);

  const fetchData = () => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(GET_CHAPTER_LIST_API, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (
          data &&
          data.videoAssessment &&
          data.videoAssessment[0]?.chapters &&
          data.videoAssessment[0].chapters.length > 0
        ) {
          setSyllabus(data.videoAssessment[0].chapters);
        } else {
          setSyllabus([]);
        }

        setIsLoading(false);
      });
  };

  const projectApi = () => {
    setIsLoading(true);

    var token = localStorage.getItem("authToken");
    fetch(GET_PROJECT_LIST_API, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log('new project data',data);
        if (data && data.projects && data.projects.length > 0) {
          setSyllabus(data.projects);
          setProjectData(data.projects);
        } else {
          setSyllabus([]);
          setProjectData([]);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("courseChapterActive") == "Projects") {
      setShowProjectData(true);
      setShowChapterData(false);
    }
    if (showChapterData) {
      fetchData();
    } else if (showProjectData) {
      projectApi();
    }
  }, [showChapterData, showProjectData]);

  useEffect(() => {
    const filteredData = showChapterData
      ? syllabus.filter((data) => {
          const lowercaseSearchInput = searchInput.toLowerCase();
          return (
            lowercaseSearchInput === "" ||
            (data.chapter_no &&
              data.chapter_no.toString().includes(lowercaseSearchInput)) ||
            (data.chapter_name &&
              data.chapter_name.toLowerCase().includes(lowercaseSearchInput))
          );
        })
      : projectData.filter((data) => {
          const lowercaseSearchInput = searchInput.toLowerCase();
          return (
            lowercaseSearchInput === "" ||
            (data.proj_chapter_no &&
              data.proj_chapter_no.toString().includes(lowercaseSearchInput)) ||
            (data.proj_title &&
              data.proj_title.toLowerCase().includes(lowercaseSearchInput))
          );
        });
    setResults(filteredData);
  }, [syllabus, projectData, showChapterData, searchInput]);
  return (
    <>
      <Sidebar sidebarLinks={studentSidebar} />
      <div className="sidebody">
        <div className="container">
          {/* <DayDateProfile /> */}
          <div className="row me-4">
            <div className="col-lg-8 col-12">
              <p className="syllabus_title mt-4">Courses</p>
            </div>
            <div className="col-lg-4 col-12 mt-4">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <span className="fa fa-search"></span>
                </span>
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {/* for navbar */}
          <div className="row">
            <div className="routes-button-div ">
              <Link
                to=""
                className={`${
                  isActive === "Chapter"
                    ? "btn routes-button active"
                    : "btn routes-button"
                }`}
                onClick={() => {
                  setShowChapterData(true);
                  setShowProjectData(false);
                  setIsActive("Chapter");
                  localStorage.setItem("courseChapterActive", "Chapter");
                }}
              >
                Chapters
              </Link>
              <Link
                to=""
                className={`${
                  isActive === "Projects"
                    ? "btn routes-button active"
                    : "btn routes-button"
                }`}
                onClick={() => {
                  setShowChapterData(false);
                  setShowProjectData(true);
                  setIsActive("Projects");
                  localStorage.setItem("courseChapterActive", "Projects");
                }}
              >
                Projects
              </Link>
            </div>
          </div>
          {isLoading ? (
            <div style={{ marginTop: "-8rem" }}>
              <LottieAnimation />
            </div>
          ) : (
            <>
              {/* syllabus */}
              {syllabus.length <= 0 && (
                <div className="text-center noDataContainer">
                  <div className="noData">No data available</div>
                </div>
              )}
              {syllabus.length > 0 && (
                <div className="row row-col-auto">
                  {showChapterData &&
                    results.map((data, ind) => {
                      return (
                        <div
                          className="col-lg-4 col-md-6 col-sm-12 col-12"
                          key={ind}
                        >
                          <div>
                            <Link
                              to="/student/courseparticularchapter"
                              style={{ color: "black", textDecoration: "none" }}
                              state={{
                                courses: data,
                                isActive,
                                vidStatus: vidStatus,
                              }}
                            >
                              <>
                                {data.chapter_thumbnail === "" ||
                                data.chapter_thumbnail === null ? (
                                  <div className="blur_img">
                                    <img
                                      src={syllabusImg}
                                      alt="courses_img"
                                      className="Syllabus_Images"
                                    />
                                  </div>
                                ) : (
                                  <div className="blur_img">
                                    <img
                                      src={data.chapter_thumbnail}
                                      alt="courses_img"
                                      className="Syllabus_Images"
                                      style={{ borderRadius: "16px" }}
                                    />
                                  </div>
                                )}

                                <div className="chapter_desc">
                                  <p className="chapter_number">{`Chapter ${data.chapter_no}`}</p>
                                  <p className="chapter_title">
                                    {data.chapter_name}
                                  </p>
                                </div>
                              </>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              {results.length === 0 && searchInput !== "" && (
                <div className="noDataContainer">
                  <div className="text-center noData">No data available</div>
                </div>
              )}
              {/* project */}

              <div className="row row-col-auto">
                {showProjectData &&
                  projectData
                    .filter((data) => {
                      const lowercaseSearchInput = searchInput.toLowerCase();
                      return (
                        lowercaseSearchInput === "" ||
                        (data.proj_chapter_no &&
                          data.proj_chapter_no
                            .toString()
                            .includes(lowercaseSearchInput)) ||
                        (data.proj_title &&
                          data.proj_title
                            .toLowerCase()
                            .includes(lowercaseSearchInput))
                      );
                    })
                    .map((data, ind) => {
                      return (
                        <div
                          className="col-lg-4 col-md-6 col-sm-12 col-12"
                          key={ind}
                        >
                          <div>
                            <Link
                              to="/student/particular-project"
                              state={{ data, isActive,ind }}
                              style={{ color: "black", textDecoration: "none" }}
                            >
                              <>
                                <div className="blur_img">
                                  <img
                                    src={data.proj_image}
                                    alt="syllabus_img"
                                    className="Syllabus_Images"
                                    width={300}
                                    style={{ borderRadius: "16px" }}
                                  />
                                </div>
                                <div className="chapter_desc">
                                  <div className="d-flex">
                                    <img
                                      src={projectLogo}
                                      alt="projectLogo"
                                      width={38}
                                      height={38}
                                      className="me-2"
                                    />
                                    <div>
                                      <p className="chapter_number">{`Project ${ind+1}`}</p>
                                      <p className="chapter_title">
                                        {data.proj_title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CoursesList;
