import React, { useState } from "react";
import * as XLSX from "xlsx";

const BulkImport = (props) => {
  const [values, setValues] = useState([]);
  var name_pattern = /^[A-Z a-z]+$/;
  var email_pattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z][a-z.A-Z]+$/;
  var phonenumber_pattern = new RegExp(/^[6789][0123456789]{9}$/);

  function importFile(event) {
    var excelFile = event.target.files[0];
    if (excelFile) {
      var reader = new FileReader();
      reader.onload = (event) => {
        var workBook = XLSX.read(event.target.result, {
          type: "binary",
        });
        if (workBook.SheetNames.length > 0) {
          if (workBook.SheetNames.length < 3) {
            var row = XLSX.utils.sheet_to_json(workBook.Sheets["Data Sheet"], {
              header: 1,
            });

            var obj = JSON.parse(JSON.stringify(row));
            var values = Object.keys(obj).map(function (key) {
              return obj[key];
            });
            setValues(values);
            // console.log("Main data", JSON.stringify(values));
            if (values.length > 1) {
              // console.log("log1", JSON.stringify(values));
              //iterate on the list which has column names also
              var emailIDs = [];
              loop1: for (let i = 0; i < values.length; i++) {
                // console.log("log2", values[0].length);

                if (i === 0) {
                  // console.log("log3", values[0]);
                  //0th index has all the column names
                  var columnNames = values[0];
                  //Check the size of columns
                  if (columnNames.length === 6) {
                    //Check all the field names
                    if (
                      columnNames[0] === "Trainer Name *" &&
                      columnNames[1] === "Email Address *" &&
                      columnNames[2] === "Mobile Number *" &&
                      columnNames[3] === "Trainer Address" &&
                      columnNames[4] === "Trainer Qualification" &&
                      columnNames[5] === "Trainer Experience"
                    ) {
                    } else {
                      alert("Some field name are incorrect");
                      break loop1;
                    }
                    columnNames.forEach(function (columnName) {});
                  } else if (columnNames.lentgh > 6) {
                    alert("Found more columns than expected...");
                    break loop1;
                  } else {
                    alert("Some columns are missing than expected...");
                    break loop1;
                  }
                } else {
                  //rest index have the data
                  //check the data is valid or not
                  var data = values[i];

                  if (data.length === 0) {
                    // Skip empty rows
                    continue;
                  }
                  if (emailIDs.includes(data[1])) {
                    alert(" Email Id contains duplicate entries, Please refer the demo sheet.");
                    break loop1;
                  }
                  emailIDs.push(data[1]);

                  if (data[0] === ("" || null) || !name_pattern.test(data[0])) {
                    //Trainers name
                    // console.log("console 1 trainer name", data[0]);
                    alert(
                      "Trainers Name data fields are incorrect or empty, Please refer the demo sheet."
                    );
                    break loop1;
                  } else if (
                    data[1] === "" ||
                    data[1] === null ||
                    !email_pattern.test(data[1])
                  ) {
                    // console.log("Values array:", values);
                    // console.log("Data row:", data);

                    //Mail id
                    // console.log("console 2 mail id",data[1]);
                    alert(
                      "Email Id data fields are incorrect or empty, Please refer the demo sheet."
                    );
                    break loop1;
                  } else if (
                    data[2] === ("" || null) ||
                    !phonenumber_pattern.test(data[2])
                  ) {
                    //Mobile no
                    // console.log("console 3 mobile no", data[2]);
                    alert(
                      "Phone no data fields are incorrect or empty, Please refer the demo sheet."
                    );
                    break loop1;
                  } else if (data[3] === ("" || null)) {
                    //trainer address
                    // console.log("console 4 trainer address", data[3]);
                    alert(
                      "Address data fields are incorrect or empty, Please refer the demo sheet."
                    );
                    break loop1;
                  } else if (data[4] === ("" || null)) {
                    //Qualification
                    // console.log("console 5 qualification", data[4]);
                    alert(
                      "Qualification data fields are incorrect or empty, Please refer the demo sheet."
                    );
                    break loop1;
                  } else if (data[5] === ("" || null)) {
                    //Experience
                    // console.log("console 6 experience", data[5]);
                    alert(
                      "Experience data fields are incorrect or empty, Please refer the demo sheet."
                    );
                    break loop1;
                  } else {
                    if (i === values.length - 1) {
                      alert(
                        "Hurreehh! Sheet is correct, your data is inserted correctly."
                      );
                    }
                  }
                }
              }
            } else {
              // console.log("data else", values.length);
              alert("No data available");
            }
            // });
          } else {
            alert("Found more than 2 sheets");
          }
        } else {
          alert("No sheets available");
        }
      };
      reader.readAsBinaryString(excelFile);
    } else {
      alert("Failed to load the excel file! Please try again.");
    }
  }

  const uploadHandler = async (e) => {
    e.preventDefault();
    var token = localStorage.getItem("authToken");
    var validData = [];

    try {
      for (let i = 1; i < values.length; i++) {
        var data = values[i];

        if (data.length === 0) {
          continue;
        } else if (data[0] === ("" || null) || !name_pattern.test(data[0])) {
          alert(
            "Trainers Name Some data fields are incorrect or empty, Please refer the demo sheet."
          );
          return;
        } else if (
          data[1] === "" ||
          data[1] === null ||
          !email_pattern.test(data[1])
        ) {
          alert(
            " mail id Some data fields are incorrect or empty, Please refer the demo sheet."
          );
          return;
        } else if (
          data[2] === ("" || null) ||
          !phonenumber_pattern.test(data[2])
        ) {
          alert(
            "phone no Some data fields are incorrect or empty, Please refer the demo sheet."
          );
          return;
        } else if (data[3] === ("" || null)) {
          alert(
            "address Some data fields are incorrect or empty, Please refer the demo sheet."
          );
          return;
        } else if (data[4] === ("" || null)) {
          alert(
            "qualification Some data fields are incorrect or empty, Please refer the demo sheet."
          );
          return;
        } else if (data[5] === ("" || null)) {
          alert(
            "experience Some data fields are incorrect or empty, Please refer the demo sheet."
          );
          return;
        } else {
          validData.push({
            user_name: data[0],
            email: data[1],
            user_phone: data[2],
            tra_address: data[3],
            tra_qualification: data[4],
            tra_experience: data[5],
          });
        }
      }

      let res = await fetch(props.bulkuploadAPI, {
        method: "POST",
        headers: {
          authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ trainers: validData }),
      });

      let resJson = await res.json();
      // console.log(resJson);
      const msg = resJson.message;
      // console.log(msg);
      if (msg === "Trainers created successfully") {
        alert("Trainers Added successfully");
        setTimeout(window.location.reload(), 50);
      } else if (msg === "Trainer with this email already exists.") {
        alert("Trainer with email id already exist");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="d-flex">
      <input
        id="fileInput"
        type="file"
        className="bulkuploadfile me-2"
        onChange={importFile}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <button
        type="button"
        className="bulk_upload_btn btn btn-primary"
        onClick={uploadHandler}
      >
        Bulk Upload
      </button>
    </div>
  );
};

export default BulkImport;
