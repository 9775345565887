import React, { useState, useEffect } from "react";
import "../Assets/Styles/Login.css";
import { Link, useNavigate } from "react-router-dom";
import loginImg from "../Assets/Images/Login/login-img.webp";
import phnElearnImg from "../Assets/Images/Login/phn-elearn.webp";
import hideIcon from "../Assets/Images/Login/hide.png";
import showIcon from "../Assets/Images/Login/show.png";
import LottieAnimation from "../Components/LottieAnimation";
import { LOGIN_API } from "../Components/AllAPI";

const Login = () => {
  const [values, setValues] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputError, setInputError] = useState({});
  const [visible, setVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user_role");

    // Replace current URL in history with the login page URL
    window.history.replaceState(null, "", "/");
  }, []);

  useEffect(() => {
    // Prevent going back to the previous page after logging out
    const clearHistory = () => {
      window.history.pushState(null, window.location.pathname);
      window.onpopstate = () => {
        navigate("/");
      };
    };
    clearHistory();
    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    setInputError(validateForm(values));
    if (Object.keys(validateForm(values)).length === 0) {
      setIsLoading(true);
      try {
        const response = await fetch(LOGIN_API, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values.email_or_mob,
            password: values.password,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();
        handleLoginResponse(json); // Handle the login response
      } catch (error) {
        console.error("Failed to fetch:", error);
        setIsLoading(false);
        setVisible(true); // Show error message
      }
    }
  };

  const handleLoginResponse = (json) => {
    if (json.status === 200) {
      setIsLoading(true);
      localStorage.setItem("authToken", json.token);
      localStorage.setItem("user_role", json.user_role);
      // console.log('user: ',json.user_role);
      navigate(`/${json.user_role.toLowerCase()}dashboard`);
      setIsLoading(false);
    } else {
      setVisible(true); // Show error message if status is not 200
    }
  };

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      const userRole = localStorage.getItem("user_role");
      switch (userRole) {
        case "Trainer":
          navigate("/trainerdashboard");
          break;
        case "Principal":
          navigate("/principaldashboard");
          break;
        case "Student":
          navigate("/studentdashboard");
          break;
        case "Manager":
          navigate("/managerdashboard");
          break;
        case "Super_Admin":
          navigate("/super_admindashboard");
          break;
        default:
          navigate("/");
      }
    } else {
      window.history.replaceState(null, "", "/");
    }
  }, [navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validateForm = (values) => {
    const error = {};
    const mobileRegx = /^[6789][0123456789]{9}$/;
    const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z][a-z.A-Z]+$/;

    if (!values.email_or_mob) {
      error.email_or_mob = "Please enter email id.";
    } else if (
      emailRegx.test(values.email_or_mob) === false &&
      mobileRegx.test(values.email_or_mob) === false
    ) {
      error.email_or_mob = "Please enter valid email id.";
    }

    if (!values.password) {
      error.password = "Please enter password.";
    }

    return error;
  };

  const navigateToForgotPassPage = () => {
    navigate("/forgot-password");
    window.location.reload();
  };

  return (
    <>
      {isLoading ? (
        <LottieAnimation />
      ) : (
        <section className="loginPage">
          <div className="container-fluid">
            <div
              className="container pt-5 ps-5 pe-5 pb-5"
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 1px 1px 3px 3px",
                borderRadius: "20px",
              }}
            >
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 col-12">
                  <img
                    src={loginImg}
                    alt="loginImg"
                    className="w-100"
                    style={{ height: "100%" }}
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-12 ps-5 pb-5 pe-5 formDiv">
                  <img
                    src={phnElearnImg}
                    alt="phnElearnImg"
                    className="img-fluid w-50"
                  />

                  {visible && (
                    <div className="alert alert-danger mt-4" role="alert">
                      Please enter valid credentials
                    </div>
                  )}
                  <p className="loginTitle">Log In</p>
                  <form action="" onSubmit={handleSubmit}>
                    <label htmlFor="">Email ID</label>
                    <input
                      type="text"
                      className="form-control"
                      value={values.email_or_mob}
                      onChange={handleInputChange}
                      name="email_or_mob"
                    />
                    <p className="error-msg mt-2">{inputError.email_or_mob}</p>
                    <label htmlFor="">Password</label>
                    <div className="password-input-wrapper">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control mb-2"
                        value={values.password}
                        onChange={handleInputChange}
                        name="password"
                        id="password"
                      />
                      <img
                        src={passwordVisible ? showIcon : hideIcon}
                        alt="eye-icon"
                        className="login_show_pass_icon"
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    <span className="error-msg">{inputError.password}</span>
                    <input
                      type="submit"
                      value="Log In"
                      className="w-100 btn loginBtn"
                    />
                  </form>
                  <p className="terms">
                    By logging in, you agree to the <u>Terms of Service</u> and{" "}
                    <u>Privacy Policy</u>, including <u>cookie use</u>.
                  </p>
                  <Link
                    className="forgotPassBtn"
                    onClick={navigateToForgotPassPage}
                  >
                    Forgot Password
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Login;
