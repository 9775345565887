import React, { useEffect, useState, useRef } from "react";
import "../Assets/Styles/StudentAssessment.css";
// import  from './data.json'
import Timer from "./Timer";
import LottieAnimation from "../Components/LottieAnimation";
import { useLocation, useNavigate } from "react-router-dom";
import { useFullScreenHandle } from "react-full-screen";
import { shuffleArray } from "./ShuffleAnswers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  POST_STUDENT_ASSESSMENT_SUBMIT_ANSWERS,
  GET_STUDENT_ASSESSMENT_GET_CHAPTERS,
} from "../Components/AllAPI";

const StudentAssessment = () => {
  const location = useLocation();
  const syllabus = location.state.syllabus;
  const assessmentIdCourse =
    location.state.syllabus.assessment[0].assessment_id;
  const [currentQue, setCurrentQue] = useState(1);
  const [assessmentID, setAssessmentID] = useState(assessmentIdCourse);
  console.log("assessmentID", assessmentID);
  const [timerExp, setTimerExp] = useState();
  let timerRef = useRef(null);
  const [timeInseconds, setTimeInseconds] = useState(null);
  window.addEventListener("popstate", (event) => {
    localStorage.removeItem("expiryTimestamp");
    localStorage.removeItem("StudentAnswers");
    localStorage.removeItem("skip");
  });
  const handle = useFullScreenHandle();
  var token = localStorage.getItem("authToken");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [Questions, setQuestions] = useState([]);

  const [answers, setAnswers] = useState({});
  const navigate = useNavigate();
  const [states, setStates] = useState({});

  useEffect(() => {
    document.addEventListener("click", function () {
      document.getElementById("click");
    });
  });

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(GET_STUDENT_ASSESSMENT_GET_CHAPTERS, {
          method: "GET",
          headers: {
            Authorization: `bearer ${token}`,
          },
        });

        if (response.ok) {
          // console.log(response)
          const data = await response.json();
          // console.log("Data from Api: ", data);

          const chapterContainingAssessment =
            data.videoAssessment[0].chapters.find((chapter) =>
              chapter.assessment.some(
                (assessment) => assessment.assessment_id === assessmentID
              )
            );
          const shuffledQuestions = shuffleArray(
            chapterContainingAssessment.assessment[0].questions
          );
          // console.log("shuffle", shuffledQuestions);
          setQuestions(shuffledQuestions);
          // console.log("Questions", Questions);

          const timeObtained = Number(
            chapterContainingAssessment.assessment[0].assessment_time
          );
          setTimeInseconds(timeObtained * 60);

          const lengthofQuestions =
            data.videoAssessment[0].chapters[0].assessment[0].questions.length;
        }
      } catch (err) {
        console.error("Error:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const skippedArray = localStorage.getItem("skip");
    // console.log('when api fetch: ',skippedArray)
    if (skippedArray) {
      setStates(JSON.parse(skippedArray));
    }

    const storedAnswers = localStorage.getItem("StudentAnswers");
    if (storedAnswers) {
      setSelectedOptions(JSON.parse(storedAnswers));
      const currIndex = localStorage.getItem("currentIndex");
      if (currIndex) {
        setCurrentQue(parseInt(currIndex));
      }
    }
  }, []);

  useEffect(() => {
    const skippedArray = localStorage.getItem("skip");
    if (skippedArray) {
      setStates(JSON.parse(skippedArray));
    }
  }, []);

  useEffect(() => {
    setAnswers((prev) => ({
      ...prev,
      ...selectedOptions,
    }));
    // console.log("answers array in useEffect", answers);
  }, [selectedOptions]);

  useEffect(() => {
    localStorage.setItem("currentIndex", JSON.stringify(currentQue));
  }, [currentQue]);

  const handleSelectedOption = (e, option_id, question_id) => {
    e.preventDefault();
    setSelectedOptions({
      ...selectedOptions,
      [question_id]: option_id,
    });

    setAnswers((prev) => ({
      ...prev,
      [question_id]: option_id,
    }));

    setStates({
      ...states,
      [currentQue]: "Attempted",
    });
    localStorage.setItem(
      "skip",
      JSON.stringify({
        ...states,
        [currentQue]: "Attempted",
      })
    );

    localStorage.setItem(
      "StudentAnswers",
      JSON.stringify({
        ...selectedOptions,
        [question_id]: option_id,
      })
    );
  };

  // console.log("Selected Option: ", selectedOptions);
  const handleQuestionClick = (id) => {
    setCurrentQue(id);
    // console.log("current Index", currentQue);

    setStates({
      ...states,
      [currentQue]: "Attempted",
    });
    localStorage.setItem(
      "skip",
      JSON.stringify({
        ...states,
        [currentQue]: "Attempted",
      })
    );
  };

  const handleNext = () => {
    setCurrentQue(currentQue + 1);

    setStates({
      ...states,
      [currentQue]: "Attempted",
    });
    localStorage.setItem(
      "skip",
      JSON.stringify({
        ...states,
        [currentQue]: "Attempted",
      })
    );
  };

  const handlePrev = () => {
    setCurrentQue(currentQue - 1);

    setStates({
      ...states,
      [currentQue]: "Attempted",
    });
    localStorage.setItem(
      "skip",
      JSON.stringify({
        ...states,
        [currentQue]: "Attempted",
      })
    );
  };

  const isQuestionAnswered = (id) => {
    return selectedOptions.hasOwnProperty(id);
  };
  const isOptionSelected = (id, optionId) => {
    return selectedOptions[id] === optionId;
  };

  const handleTimerExp = () => {
    // console.log("Inside handleTimerExp div: ");
    // setTimeInseconds(0);
    handleTimerSubmit();
    localStorage.removeItem("expiryTimestamp");
    setTimerExp(true);
  };

  const handleTimerSubmit = async () => {
    // console.log("selectedOptions in answers array: ", answers);
    if (timerExp) {
      // console.log("Timer is expired");

      if (Object.keys(selectedOptions).length === 0) {
        // console.log("No answers selected. Allowing submission...");

        try {
          setTimeInseconds(0);
          const data = {
            assessment_id: assessmentID.toString(),
            answers: [],
          };

          const response = await fetch(POST_STUDENT_ASSESSMENT_SUBMIT_ANSWERS, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //  ' Content-Type': 'text/html; charset=utf-8',
              Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(data),
          });
          if (response.ok) {
            const responseData = await response.json();
            // console.log("Data sent Successfully", responseData);
            localStorage.removeItem("StudentAnswers");
            localStorage.removeItem("expiryTimestamp");
            localStorage.removeItem("skip");
            navigate("/student/assessmentcompletion", {
              state: { responseData, syllabus },
            });
            // console.log("Local Storage cleared");
            // Redirect to success page or handle response accordingly
          } else {
            console.error("Failed");
          }
        } catch (err) {
          console.log("Error Occurred", err);
        }
      } else {
        try {
          setTimeInseconds(0);
          const data = {
            assessment_id: assessmentID.toString(),
            answers: Object.keys(answers).map((questionId) => ({
              question_id: parseInt(questionId),
              answered_option_id: answers[questionId],
            })),
          };

          const response = await fetch(POST_STUDENT_ASSESSMENT_SUBMIT_ANSWERS, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //  ' Content-Type': 'text/html; charset=utf-8',
              Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(data),
          });

          if (response.ok) {
            const responseData = await response.json();
            // console.log("Data sent Successfully");

            // console.log("response: ", responseData);
            // setSubmitData(responseData);

            localStorage.removeItem("StudentAnswers");
            localStorage.removeItem("expiryTimestamp");

            localStorage.removeItem("skip");
            navigate("/student/assessmentcompletion", {
              state: { responseData, syllabus },
            });
            //   navigate("/success", { state: responseData });
            // console.log("Local Storage cleared");
          } else {
            console.error("Failed");
          }
        } catch (err) {
          console.log("Error Occured", err);
        }
      }
    }
  };

  const handleSubmit = async () => {
    // console.log("selectedOptions in answers array: ", answers);

    const confirmed = window.confirm("Are you sure you want to submit?");
    if (confirmed) {
      //if not any single option selected then also the form should be submitted
      if (Object.keys(selectedOptions).length === 0) {
        // No answer has been selected
        // console.log("No answers selected. Allowing submission...");
        // Perform submission logic here
        try {
          setTimeInseconds(0);
          const data = {
            assessment_id: assessmentID.toString(),
            answers: [],
          };

          const response = await fetch(POST_STUDENT_ASSESSMENT_SUBMIT_ANSWERS, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //  ' Content-Type': 'text/html; charset=utf-8',
              Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(data),
          });
          if (response.ok) {
            const responseData = await response.json();
            // console.log("Data sent Successfully", responseData);
            localStorage.removeItem("StudentAnswers");
            localStorage.removeItem("expiryTimestamp");
            localStorage.removeItem("skip");
            navigate("/student/assessmentcompletion", {
              state: { responseData, syllabus },
            });
            // console.log("Local Storage cleared");
            // Redirect to success page or handle response accordingly
          } else {
            console.error("Failed");
          }
        } catch (err) {
          console.log("Error Occurred", err);
        }
      } else {
        try {
          setTimeInseconds(0);
          const data = {
            assessment_id: assessmentID.toString(),
            answers: Object.keys(answers).map((questionId) => ({
              question_id: parseInt(questionId),
              answered_option_id: answers[questionId],
            })),
            // answers:answers
          };
          // console.log("data: ", data);

          const response = await fetch(POST_STUDENT_ASSESSMENT_SUBMIT_ANSWERS, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //  ' Content-Type': 'text/html; charset=utf-8',
              Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(data),
          });

          if (response.ok) {
            const responseData = await response.json();
            // console.log("Data sent Successfully");
            // console.log('Data: ',data)

            // console.log("response: ", response);
            // console.log("response: ", responseData);
            // setSubmitData(responseData);

            localStorage.removeItem("StudentAnswers");
            localStorage.removeItem("expiryTimestamp");
            // localStorage.removeItem("timerValue");
            localStorage.removeItem("skip");
            navigate("/student/assessmentcompletion", {
              state: { responseData, syllabus },
            });
            //   navigate("/success", { state: responseData });
            // console.log("Local Storage cleared");
          } else {
            console.error("Failed");
          }
        } catch (err) {
          console.log("Error Occured", err);
        }
      }
    } else {
      console.log("Submission Aborted");
    }
  };
  return (
    <div>
      {isLoading ? (
        <LottieAnimation />
      ) : (
        <div className="container mt-5">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            // transition: Bounce,
          />
          <div>
            <p className="Stu_Assess_header">Assessment</p>
          </div>
          <div className="row">
            <div className="col-12 col-lg-8 col-md-8 col-sm-8 que_main_div">
              <div>
                <div className="stu_que_text">
                  {/* {Questions.find((q) => q.ID === currentQue)?.question} */}
                  {/* {Questions[currentQue - 1].question} */}
                  {/* {questions.question.find((q=> q.id+1 === currentQue)?.que_question)} */}
                  {`Q${currentQue}: `}
                  {Questions && Questions[currentQue - 1].que_question}
                  {/* Lorem ipsum dolor sit amet consectetur fermentum platea? */}
                </div>
                <div>
                  <p className="Stu_answer_header mt-4">Answers</p>
                </div>
                {/* {Questions.find((q) => q.ID === currentQue)?.answer.map( */}
                {Questions &&
                  Questions.length > 0 &&
                  Questions[currentQue - 1].options.map((ans, index) => (
                    <div
                      className="d-flex"
                      style={{ marginBottom: "10px" }}
                      key={index}
                    >
                      <div
                        // className="stu_Asses_que_title option-one"
                        className={`stu_Asses_que_title text-center option-${
                          index + 1
                        }`}
                        // onClick={(e) => handleSelectedOption(e, ans.opt_id)}
                        onClick={(e) =>
                          handleSelectedOption(
                            e,
                            ans.opt_id,
                            ans.opt_question_id
                          )
                        }
                      >
                        <p className="stu_Asses_que_title_text">
                          {String.fromCharCode(65 + index)}
                        </p>
                      </div>
                      <div
                        className={`card stu_assess_card ${
                          isOptionSelected(ans.opt_question_id, ans.opt_id)
                            ? "selected"
                            : ""
                        }`}
                      >
                        <div
                          // className={`card-body stu_assess_card-body `}
                          className="card-body stu_assess_card-body"
                          onClick={(e) =>
                            handleSelectedOption(
                              e,
                              ans.opt_id,
                              ans.opt_question_id
                            )
                          }
                        >
                          <p
                            // className={`stu_assess_ans_text `}
                            className="stu_assess_ans_text"
                          >
                            {/* {ans} */}
                            {ans.opt_option}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                <div className="next_prev_button_div">
                  {currentQue === 1 ? (
                    <>
                      <button
                        type="button"
                        className="nextQuestion_button"
                        // onClick={ () => setCurrentQue(currentQue + 1)}
                        onClick={handleNext}
                      >
                        Next Question
                      </button>
                    </>
                  ) : //make some changes here
                  currentQue === (Questions ? Questions.length : "") ? (
                    <>
                      <button
                        type="button"
                        className="prev_button"
                        // onClick={() => setCurrentQue(currentQue - 1)}
                        onClick={handlePrev}
                      >
                        Previous
                      </button>
                      <button
                        type="button"
                        className="next_button"
                        onClick={() => handleSubmit()}
                        // onClick = {handleReviewButtonClick}
                        // onClick={() => setCurrentQue(currentQue + 1)}
                      >
                        Submit
                        {/* Review */}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="prev_button"
                        // style={{ marginRight: "24px" }}
                        // onClick={() => setCurrentQue(currentQue - 1)}
                        onClick={handlePrev}
                      >
                        Previous
                      </button>
                      <button
                        type="button"
                        className="next_button"
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-4 col-sm-4 ques_nav_link_main_div">
              <div style={{ width: "100%" }}>
                <div className="timer_header">
                  <p className="Stu_Assess_header">Questions</p>
                  {/* {timeInseconds && ( */}
                  <Timer
                    timer={timeInseconds}
                    // startTimer={!!timeInseconds}
                    onExpire={handleTimerExp}
                    ref={timerRef}
                  />
                  {/* )
        }
           */}
                </div>
                {/* <p className="Stu_Assess_header">Questions</p> */}
                <div className="row row-cols-auto que_nav_div">
                  {/* {Questions.map((question) => ( */}
                  {Questions &&
                    Questions?.map((que, id) => (
                      <div
                        // key={question.ID}
                        key={id}
                        className="col-lg-4 col-12 "
                        style={{ marginBottom: "10px", width: "auto" }}
                      >
                        <div
                          // className={`question_nav_div ${
                          //   question.ID === currentQue ? "current-question ": ""
                          // } ${isQuestionAnswered(question.ID) ? "answered" : ""}

                          // `}
                          className={`question_nav_div text-center ${
                            id + 1 === currentQue ? "current-question" : ""
                          }
                ${isQuestionAnswered(que.que_id) ? "answered" : ""}
                   
                    ${states[id + 1] === "Attempted" ? "not-answered" : ""}
                    `}
                          onClick={() => handleQuestionClick(id + 1)}
                          // className="question_nav_div answered "
                        >
                          <p className="question_nav_number">{id + 1}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="d-flex row assess_indication_main_div">
                <div className="d-flex assess_indication_wrapper  col-12 col-lg-5">
                  <div className="stu_assess_indication answered"></div>
                  <div>
                    <p className="stu_assess_indi_text">Answered</p>
                  </div>
                </div>
                <div className="d-flex assess_indication_wrapper col-12 col-lg-7">
                  <div className="stu_assess_indication not-answered"></div>
                  <div>
                    <p className="stu_assess_indi_text"> Not Answered</p>
                  </div>
                </div>
                <div className="d-flex assess_indication_wrapper col-12 col-lg-5">
                  <div className="stu_assess_indication yet-to-visit"></div>
                  <div>
                    <p className="stu_assess_indi_text">Yet to Visit</p>
                  </div>
                </div>
                <div className="d-flex assess_indication_wrapper col-12 col-lg-7">
                  <div className="stu_assess_indication current-question"></div>
                  <div>
                    <p className="stu_assess_indi_text">Current Question</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default StudentAssessment;
