import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import assessmentClipboard from "../Assets/Images/Dashboard/assessment_clipboard.svg";
import lock from "../Assets/Images/Dashboard/assessment_locked.svg";
import CircularProgress from "../Components/CircularProgress";
import videoImg from "../Assets/Images/Dashboard/dummyVideoIcon.svg";
import tickIcon from "../Assets/Images/Dashboard/tick_icon_circled.svg";
import notPlayed from "../Assets/Images/Dashboard/playing_video.svg";
import videoPlayingIcon from "../Assets/Images/Dashboard/videoPaused.svg";
import textIcon from "../Assets/Images/Dashboard/text_data_icon.svg";
import coloredClipboard from "../Assets/Images/Dashboard/colored_clipboard.svg";
import startAssessmentArrows from "../Assets/Images/Dashboard/start_assessment_arrows.svg";
import completedAssign from "../Assets/Images/Dashboard/completed_clipboard.svg";
import trophy from "../Assets/Images/Dashboard/trophy.svg";
import checklist from "../Assets/Images/Assessment/checklist_icon.png";
import time_icon from "../Assets/Images/Assessment/time_icon.png";
import green from "../Assets/Images/Assessment/green.png";
import white from "../Assets/Images/Assessment/white.png";
import yellow from "../Assets/Images/Assessment/yellow.png";
import purple from "../Assets/Images/Assessment/purple.png";
import Lottie from "react-lottie";
import loader from "../Assets/Images/Dashboard/loader_lottie.json";
import { API_START_POINT } from "../Components/AllAPI";
const ChapterVideoList = (props) => {
  // let studentSidebar = sidebarData.Student;
  let syllabus = props.syllabusData;
  // console.log("syllabus datta", syllabus);
  const location = useLocation();
  const videoCompleted = props.videoCompleted;
  const onAllVideosCompleted = props.onAllVideosCompleted;
  // let assessment_id = syllabus.assessment[0].assessment_id;
  const assessment_id = syllabus.assessment?.[0]?.assessment_id;
  // console.log("vid completed", videoCompleted);
  // const assessmentData = syllabus.assessment[0].attempted;
  // const questionNos = syllabus.assessment[0].questions;
  const assessmentData =
    syllabus.assessment &&
    syllabus.assessment[0] &&
    syllabus.assessment[0].attempted;
  const questionNos =
    syllabus.assessment &&
    syllabus.assessment[0] &&
    syllabus.assessment[0].questions;
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(null);
  const [score, setScore] = useState(0);
  const [newData, setNewData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const setActiveContent = (index) => {
    setIsActive(index);
    localStorage.setItem("ind", index);
  };
  const fetchVideoData = () => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(`${API_START_POINT}/getChapters/${syllabus.chapter_id}`, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setNewData(data.videos);
        setIsLoading(false);

        // console.log("fetched data", data);
      });
  };
  useEffect(() => {
    fetchVideoData();
  }, [videoCompleted, location.pathname]);

  // console.log("new data", newData);

  // useEffect(() => {
  //   if (videoCompleted === true || newData.vid_progress === 1) {
  //     fetchVideoData();
  //   }
  // }, [videoCompleted]);

  useEffect(() => {
    const storedIndex = localStorage.getItem("ind");
    if (location.pathname === "/particularchaptervideo") {
      if (storedIndex !== null) {
        setIsActive(parseInt(storedIndex));
      }
    }
  }, []);
  const navigateToAssessment = () => {
    navigate("/student/studentassessment", {
      state: {
        syllabus,
      },
    });
    window.location.reload();
  };

  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(`${API_START_POINT}/calculate-correct-answers/${assessment_id}`, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setScore(data[0]);
      });
  };

  useEffect(() => {
    fetchVideoData();
  }, [videoCompleted]);

  useEffect(() => {
    fetchData();
  }, [videoCompleted]);

  const handleAllVideosCompletion = () => {
    if (onAllVideosCompleted) {
      onAllVideosCompleted();
    }
  };

  let count = 0;
  function countProgress() {
    newData.forEach((element) => {
      if (element.video.vid_progress === 1) {
        count++;
      }
    });
  }

  countProgress();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {/* <Sidebar sidebarLinks={studentSidebar} /> */}
      {loading ? (
        <Lottie options={defaultOptions} height={100} width={100} />
      ) : (
        <div className="chapterVideos">
          {syllabus.assessment.length !== 0 ? (
            <div>
              {assessmentData === 1 && newData.length === count ? (
                /* assessment completed */
                <div className="assessmentCompleted d-flex">
                  <img
                    src={completedAssign}
                    alt="completedAssign"
                    className="me-4"
                  />
                  <div className="text-center">
                    <p className="your_score_title mb-0">Your Score</p>
                    <p className="scoreNo">
                      {score.total_correct_answers}/{score.total_questions}
                    </p>
                  </div>
                  <div className="ms-auto">
                    <img src={trophy} alt="trophy" className="ms-5 mt-3" />
                  </div>
                </div>
              ) : newData.length === count ? (
                /* assessment starting */
                <div
                  className="assessmentStart d-flex"
                  data-bs-toggle="modal"
                  data-bs-target="#startAssessmentModal"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={coloredClipboard}
                    alt="coloredClipboard"
                    className="me-4"
                  />
                  <div className="text-center">
                    <p className="starAssessment mb-0">Start Assessment</p>
                    <p className="watchAllVideos">
                      {questionNos !== null && (
                        <p>{questionNos.length} Questions</p>
                      )}
                    </p>
                  </div>
                  <div className="ms-auto">
                    <img
                      src={startAssessmentArrows}
                      alt="startAssessmentArrows"
                      className="ms-5 mt-3"
                    />
                  </div>
                </div>
              ) : (
                /* assessment pending */

                <div className="assessmentPending d-flex">
                  <img
                    src={assessmentClipboard}
                    alt="assessmentClipboard"
                    className="me-4"
                  />
                  <div className="text-center">
                    <p className="starAssessment mb-0">Start Assessment</p>
                    <p className="watchAllVideos">Watch all videos to unlock</p>
                  </div>
                  <img src={lock} alt="lock" className="ms-5" />
                </div>
              )}
            </div>
          ) : (
            <p>No assessment available</p>
          )}

          {/* topics list */}
          <div className="Topics-Covered-List mt-4">
            <div className="d-flex">
              <p className="topicTitle">Topics</p>
              <div className="ms-auto d-flex">
                <span className="topicCompletedText me-3">
                  {count} / {syllabus.videos.length}
                </span>
                <span>
                  <CircularProgress
                    watchedVideo={count}
                    totalVideo={syllabus.videos.length}
                  />
                </span>
              </div>
            </div>
            <>
              {newData.map((list, ind) => {
                // console.log("vlist", list);
                const mapVidID = list.video.vid_id;
                return (
                  <Link
                    key={ind}
                    to="/particularchaptervideo"
                    style={{ color: "black", textDecoration: "none" }}
                    state={{
                      videoData: list.video,
                      syllabus: syllabus,
                      ind: ind,
                    }}
                    onClick={() => {
                      // Reset videoCompleted state to false if true
                      if (videoCompleted) {
                        props.onAllVideosCompleted(false);
                      }
                    }}
                  >
                    <div
                      onClick={() => setActiveContent(ind)}
                      className={
                        isActive === ind
                          ? "activeVideo Content-1 mt-2 w-100"
                          : "Content-1 mt-2 w-100 "
                      }
                    >
                      <div className="row w-100">
                        <div className="col-lg-2 my-auto">
                          {list.video.vid_path === null ||
                          list.video.vid_path === "" ? (
                            <img
                              src={assessmentClipboard}
                              alt="textIcon"
                              className="ms-3"
                            />
                          ) : list.video.vid_thumbnail === "" ||
                            list.video.vid_thumbnail === null ||
                            list.video.vid_thumbnail === "somepath" ? (
                            <img src={videoImg} alt="videoImg" width="80" />
                          ) : (
                            <img src={list.vid_thumbnail} alt="videoImg" />
                          )}
                        </div>
                        <div className="col-lg-8 ps-5 my-auto">
                          <div className="inner-topics">
                            {list.video.vid_title}
                            {/* <p className="videoTime">32:42</p>{" "} */}
                          </div>
                        </div>
                        <div className="col-lg-2">
                          {list.video.vid_path === null ||
                          list.vid_path === "" ? (
                            isActive === ind ? (
                              list.video.vid_progress === 1 ? (
                                <img
                                  src={tickIcon}
                                  alt="tickIcon"
                                  width={25}
                                  className="mt-3 ms-5 me-3"
                                  style={{ float: "right" }}
                                />
                              ) : (
                                <img
                                  src={videoPlayingIcon}
                                  alt="videoPlayingIcon"
                                  width={25}
                                  className="mt-1 ms-5 me-3"
                                  style={{ float: "right" }}
                                />
                              )
                            ) : list.video.vid_progress === 1 ? (
                              <img
                                src={tickIcon}
                                alt="tickIcon"
                                width={25}
                                className="mt-3 ms-5 me-3"
                                style={{ float: "right" }}
                              />
                            ) : (
                              <img
                                src={textIcon}
                                alt="textIcon"
                                width={25}
                                className="mt-3 ms-5 me-3"
                                style={{ float: "right" }}
                              />
                            )
                          ) : isActive === ind ? (
                            list.video.vid_progress === 1 ? (
                              <img
                                src={tickIcon}
                                alt="tickIcon"
                                width={25}
                                className="mt-3 ms-5 me-3"
                                style={{ float: "right" }}
                              />
                            ) : (
                              <img
                                src={videoPlayingIcon}
                                alt="videoPlayingIcon"
                                width={25}
                                className="mt-1 ms-5 me-3"
                                style={{ float: "right" }}
                              />
                            )
                          ) : list.video.vid_progress === 1 ? (
                            <img
                              src={tickIcon}
                              alt="tickIcon"
                              width={25}
                              className="mt-3 ms-5 me-3"
                              style={{ float: "right" }}
                            />
                          ) : (
                            <img
                              src={notPlayed}
                              alt="notPlayed"
                              width={25}
                              className="mt-3 ms-5 me-3"
                              style={{ float: "right" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="startAssessmentModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="container p-5">
              <div className="row">
                <div className="col-lg-8 col-12">
                  <div className="header_text ms-5 ps-5">
                    <p>Assessment</p>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{ float: "right" }}
                  ></button>
                </div>
              </div>
              <div className="topic_div">
                <p>{syllabus.chapter_name}</p>
              </div>
              <div className="top_module">
                <div className="rule_div_1 row">
                  <div className="icon_div_1 col-2 m-auto">
                    <img
                      src={checklist}
                      className="checklist"
                      width={40}
                      height={40}
                      alt="checklist"
                    />
                  </div>
                  <div className="assessment_text col-10">
                    <div className="assessment_text_header">
                      {/* <p>{questionNos.length} MCQs</p> */}
                      <p>{questionNos && questionNos.length} MCQs</p>
                    </div>
                    <div className="assessment_text_content">
                      <p>1 mark per question</p>
                    </div>
                  </div>
                </div>
                <div className="rule_div_1 row">
                  <div className="icon_div_1 col-2 m-auto">
                    <img
                      src={time_icon}
                      className="time_icon"
                      alt="time_icon"
                      width={40}
                    />
                  </div>
                  <div className="assessment_text col-10">
                    <div className="assessment_text_header">
                      {/* <p>{syllabus.assessment[0].assessment_time} mins</p> */}
                      <p>
                        {syllabus.assessment[0] &&
                          syllabus.assessment[0].assessment_time}{" "}
                        mins
                      </p>
                    </div>
                    <div className="assessment_text_content">
                      <p>Total time duration for the Assessment</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="instructions">
                <div className="header_instructions">
                  <p>
                    Please read the text below carefully so you can understand
                    it
                  </p>
                </div>
                <div className="sub-instructions">
                  <ul>
                    <li>Tap to options to select the correct answer.</li>
                    <li>
                      Click submit if you want to complete all the quizzes.
                    </li>
                    <li>
                      No quitting or closing the assessment once started.
                      Completion is mandatory.
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="answered_div">
                      <div className="green_icon">
                        <img src={green} className="icons" alt="" />
                      </div>
                      <div className="answered_text">
                        <p>Answered</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="answered_div">
                      <div className="green_icon">
                        <img src={yellow} className="icons" alt="" />
                      </div>
                      <div className="answered_text">
                        <p>Not Answered</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="answered_div">
                      <div className="green_icon">
                        <img src={white} className="icons" alt="" />
                      </div>
                      <div className="answered_text">
                        <p>Yet to Visit</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="answered_div">
                      <div className="green_icon">
                        <img src={purple} className="icons" alt="" />
                      </div>
                      <div className="answered_text">
                        <p>Current</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="button_div" onClick={navigateToAssessment}>
                <button className="submit_button">Start Assessment</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChapterVideoList;
