import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import videoWatchedIcon from '../Assets/Images/Dashboard/videoWatchedIcon.svg';

const CircularProgress = ({ watchedVideo, totalVideo }) => {
  const percentage = (watchedVideo / totalVideo) * 100;

  return (
    <div style={{ width: 30 }}>
      {watchedVideo === totalVideo ? (
        <img src={videoWatchedIcon} alt="videoWatchedIcon" />
      ) : (
        <CircularProgressbar
          value={percentage}
          strokeWidth={12}
          styles={{
            root: { width: "100%" },
            path: { stroke: percentage ? "#4caf50" : "#f3f3f3" },
            trail: { stroke: "#f3f3f3" },
          }}
        />
      )}
    </div>
  );
};

export default CircularProgress;
