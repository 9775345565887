import React, { useState } from "react";
import * as XLSX from "xlsx";
import download from "../Assets/Images/StudentList/download.png";
import upload from "../Assets/Images/StudentList/upload.png";
import upload_icon from "../Assets/Images/StudentList/download_icon.png";

const TrainerBulkImport = (props) => {
  const [selectedFileName, setSelectedFileName] = useState("Bulk Upload");
  const [values, setValues] = useState([]);
  var name_pattern = /^[A-Z a-z]+$/;
  var email_pattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z][a-z.A-Z]+$/;
  var phonenumber_pattern = new RegExp(/^[6789][0123456789]{9}$/);

  function importFile(event) {
    var excelFile = event.target.files[0];
    if (excelFile) {
      setSelectedFileName(excelFile.name);
      var reader = new FileReader();
      reader.onload = (event) => {
        var workBook = XLSX.read(event.target.result, {
          type: "binary",
        });
        if (workBook.SheetNames.length > 0) {
          if (workBook.SheetNames.length < 3) {
            var row = XLSX.utils.sheet_to_json(workBook.Sheets["Data Sheet"], {
              header: 1,
            });

            var obj = JSON.parse(JSON.stringify(row));
            var values = Object.keys(obj).map(function (key) {
              return obj[key];
            });
            // Filter out empty arrays
            values = values.filter((arr) => arr.length > 0);
            setValues(values);
            // console.log("Main data", JSON.stringify(values));
            // console.log("length",values.length);
            if (values.length > 1) {
              // console.log("log1", JSON.stringify(values));
              //iterate on the list which has column names also
              var emailIDs = [];
              loop1: for (let i = 0; i < values.length; i++) {
                // console.log("log2", values[0].length);

                if (i === 0) {
                  // console.log("log3", values[0]);
                  //0th index has all the column names
                  var columnNames = values[0];
                  //Check the size of columns
                  if (columnNames.length === 6) {
                    //Check all the field names
                    if (
                      columnNames[0] === "Trainer Name *" &&
                      columnNames[1] === "Email Address *" &&
                      columnNames[2] === "Mobile Number *" &&
                      columnNames[3] === "Trainer Address" &&
                      columnNames[4] === "Trainer Qualification" &&
                      columnNames[5] === "Trainer Experience"
                    ) {
                    } else {
                      alert("Some field name are incorrect");
                      break;
                    }
                    columnNames.forEach(function (columnName) {});
                  } else if (columnNames.length > 6) {
                    alert(
                      "Found more columns than expected... Please refer template"
                    );
                    break;
                  } else {
                    alert(
                      "Some columns are missing than expected...  Please refer template"
                    );
                    break;
                  }
                } else {
                  //rest index have the data
                  //check the data is valid or not
                  var data = values[i];

                  // if (data.length === 0) {
                  //   // Skip empty rows
                  //   continue;
                  // }
                  if (emailIDs.includes(data[1])) {
                    // console.log('duplicate email',data[1]);
                    alert(
                      "The file you have chosen contains duplicate entries of Email address"
                    );
                    break loop1;
                  }
                  emailIDs.push(data[1]);

                  if (data[0] === ("" || null) || !name_pattern.test(data[0])) {
                    //Trainers name
                    // console.log("console 1 trainer name", data[0]);
                    alert(
                      " The file you have chosen includes empty fields or invalid data for the Trainers name."
                    );
                    break loop1;
                  } else if (
                    data[1] === "" ||
                    data[1] === null ||
                    !email_pattern.test(data[1])
                  ) {
                    // console.log("Values array:", values);
                    // console.log("Data row:", data);

                    //Mail id
                    // console.log("console 2 mail id",data[1]);
                    alert(
                      "The file you have chosen includes empty fields or invalid data for the Email address."
                    );
                    break loop1;
                  } else if (
                    data[2] === ("" || null) ||
                    !phonenumber_pattern.test(data[2])
                  ) {
                    //Mobile no
                    // console.log("console 3 mobile no", data[2]);
                    alert(
                      "The file you have chosen includes empty fields or invalid data for the Mobile no."
                    );
                    break loop1;
                  } else if (data[3] === ("" || null)) {
                    //trainer address
                    // console.log("console 4 trainer address", data[3]);
                    alert(
                      " The file you have chosen includes empty fields or invalid data for the Address."
                    );
                    break loop1;
                  } else if (data[4] === ("" || null)) {
                    //Qualification
                    // console.log("console 5 qualification", data[4]);
                    alert(
                      " The file you have chosen includes empty fields or invalid data for the Qualification."
                    );
                    break loop1;
                  } else if (data[5] === ("" || null)) {
                    //Experience
                    // console.log("console 6 experience", data[5]);
                    alert(
                      " The file you have chosen includes empty fields or invalid data for the Experience."
                    );
                    break loop1;
                  } else {
                    if (i === values.length - 1) {
                      alert(
                        "Hurreehh! Sheet is correct, your data is inserted correctly."
                      );
                    }
                  }
                }
              }
            } else {
              // console.log("data else", values.length);
              alert("The file you have chosen is invalid.");
            }
            // });
          } else {
            alert("Found more than 2 sheets");
          }
        } else {
          alert("No sheets available");
        }
        const fileInput = document.getElementById("fileInput");
        const fileName = fileInput ? fileInput.files[0].name : "";
        document.getElementById("dropdownMenuButton1").innerText = fileName;
      };
      reader.readAsBinaryString(excelFile);
    } else {
      alert("Failed to load the excel file! Please try again.");
    }
  }

  const browseAndUploadClicked = () => {
    document.getElementById("fileInput").click();
  };

  const generateEmptyTemplate = () => {
    // Assuming your template file is named "template.xlsx"
    try {
      const templateFilePath =
        "https://phnelearn.s3.ap-south-1.amazonaws.com/trainer-bulk-upload-instruction-sheet.xlsx";
      const link = document.createElement("a");
      // link.href = "../Assets/ExcelSheet/students_bulkupload_template.xlsx";
      link.href = templateFilePath;

      link.download = "trainer_bulkupload_template.xlsx";
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading template:", error);
      // Handle error appropriately, e.g., show an error message to the Fuser
    }
  };

  const uploadHandler = async (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("fileInput");
    if (!fileInput.files || fileInput.files.length === 0) {
      alert("Please select a file before clicking on Bulk Upload.");
      return;
    }
    var token = localStorage.getItem("authToken");
    var validData = [];
    var emailIDs = [];

    try {
      //  let emptyFile = values.filter((arr) => arr.length > 0);
      // if (emptyFile.length === 0) {
      //   alert("No data available in the file.");
      //   return;
      // }
      for (let i = 1; i < values.length; i++) {
        var data = values[i];

        if (
          data[0] === "" ||
          data[0] === null ||
          !name_pattern.test(data[0]) ||
          data[1] === "" ||
          data[1] === null ||
          !email_pattern.test(data[1]) ||
          data[2] === "" ||
          data[2] === null ||
          !phonenumber_pattern.test(data[2]) ||
          data[3] === ("" || null) ||
          data[4] === ("" || null) ||
          data[5] === ("" || null)
        ) {
          alert(
            "Some data fields are incorrect or empty. Please refer to the template sheet."
          );
          return;
        }

        if (emailIDs.includes(data[1])) {
          alert(
            "The file you have chosen contains duplicate entries of Email address."
          );
          return;
        }
        emailIDs.push(data[1]);

        validData.push({
          user_name: data[0],
          email: data[1],
          user_phone: data[2],
          tra_address: data[3],
          tra_qualification: data[4],
          tra_experience: data[5],
        });
      }

      let res = await fetch(props.bulkuploadAPI, {
        method: "POST",
        headers: {
          authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          trainers: validData,
        }),
      });

      let resJson = await res.json();
      // console.log(resJson);
      const msg = resJson.message;
      // console.log(msg);
      if (msg === "Trainers created successfully") {
        alert("Trainers added successfully");
        setTimeout(window.location.reload(), 50);
      } else if (msg === "Trainer with this email already exists.") {
        alert("Trainer with email id already exist");
      }
      fileInput.value = null;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="bulk-upload-mainDiv" style={{ marginTop: "8px" }}>
      <div className="d-flex align-items-center">
        <div className="Dropdown me-2 bulkuploaddropdown">
          <button
            className="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ maxWidth: "150px", overflow: "hidden" }}
            title={selectedFileName}
          >
            {selectedFileName}
          </button>
          <ul
            className="dropdown-menu p-2"
            aria-labelledby="dropdownMenuButton1"
            style={{ minWidth: "100px" }}
          >
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default behavior
                  generateEmptyTemplate(); // Call your function
                }}
                //  onClick={generateEmptyTemplate}
              >
                Download template
                <img
                  src={download}
                  alt="schoolExportIcon"
                  className="ms-3"
                  style={{ width: "24px", height: "24px" }}
                />
              </a>
            </li>
            <li className="border-top">
              <div
                className="dropdown-item"
                // href="#"
                style={{ cursor: "pointer" }}
                onClick={browseAndUploadClicked}
              >
                Browse and Upload
                <img
                  src={upload}
                  alt="trainerExportIcon"
                  className="ms-3"
                  style={{ width: "24px", height: "24px" }}
                />
                <input
                  id="fileInput"
                  type="file"
                  className="d-none" // Hide the input element
                  onChange={importFile}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </div>
            </li>
          </ul>
        </div>
        <button className="btn" type="button" onClick={uploadHandler}>
          <img src={upload_icon} style={{ width: "24px", height: "24px" }} />
        </button>
      </div>
    </div>

    // <div className="d-flex">
    //   <input
    //     id="fileInput"
    //     type="file"
    //     className="bulkuploadfile me-2"
    //     onChange={importFile}
    //     accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    //   />
    //   <button
    //     type="button"
    //     className="bulk_upload_btn btn btn-primary"
    //     onClick={uploadHandler}
    //   >
    //     Bulk Upload
    //   </button>
    // </div>
  );
};

export default TrainerBulkImport;
