import React, { useEffect, useState } from "react";
import profileImg from "../Assets/Images/StudentList/student_profile.svg";
import { GET_PROFILE_SUPER_ADMIN } from "../Components/AllAPI";

const ProfileSuperAdmin = () => {
  const [profile, setProfile] = useState({});
  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_PROFILE_SUPER_ADMIN, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log('Profile data: ',data)
        setProfile(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section className="profile">
        <div className="container">
          <div>
            <p className="profile_title">Profile</p>
            <img src={profileImg} alt="" />
          </div>
          <p className="student_name">{profile.su_adm_name}</p>

          <div className="stud_data">
            <div className="row">
              {/* <div className="col-6">
                <p className="data_title mb-1">PHN Employee id</p>
                <p className="data_subititle">{profile.su_adm_id}</p>
              </div> */}
              <br />
              <div className="col-6">
                <p className="data_title mb-1">Mobile no</p>
                <p className="data_subititle">{profile.su_adm_phone}</p>
              </div>
              <div className="col-6">
                <p className="data_title mb-1">Role</p>
                <p className="data_subititle">Super Admin</p>
              </div>

              <br />
              <div className="col-12">
                <p className="stud_addres_title mb-1">Email Id</p>
                <p className="stud_address_subtitle">{profile.su_adm_email}</p>
              </div>

              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileSuperAdmin;
