import React from "react";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import { useLocation } from "react-router-dom";
import DayDateProfile from "../Components/DayDateProfile";
import schoolLogo from "../Assets/Images/Dashboard/school_logo.svg";
const SingleSchoolTrainer = () => {
  let managerSidebar = sidebarData.Manager;

  const location = useLocation();
  const schoolData = location.state;

  function convertToRoman(num) {
    const romanNumerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    let result = "";

    for (let key in romanNumerals) {
      while (num >= romanNumerals[key]) {
        result += key;
        num -= romanNumerals[key];
      }
    }

    return result;
  }

  function convertListToRoman(input) {
    if (!input || typeof input !== "string") return "Invalid input";

    const numbers = input.split(",").map(Number);
    return numbers.map(convertToRoman).join(",");
  }

  const inputString = schoolData.sch_phn_enrolled_grades;
  const romanNumeralsString = convertListToRoman(inputString);

  return (
    <>
      <Sidebar sidebarLinks={managerSidebar} />
      <section className="schoolProfile sidebody">
        <div className="container">
          <DayDateProfile />
          <p className="profile_header mt-5">
            <p className="school_data">School Profile</p>
          </p>
          <div>
            <img
              src={
                schoolData.sch_logo === null || schoolData.sch_logo === ""
                  ? schoolLogo
                  : schoolData.sch_logo
              }
              alt="School Icon"
              className="school_logo"
            />
            <div className="school-name-div mt-4">
              <p className="school_data">
                {schoolData.sch_name === null || schoolData.sch_name === ""
                  ? "-"
                  : schoolData.sch_name}
              </p>
            </div>
          </div>
          <hr />
          <div className="Enrolled_div">
            <p className="sub-header mb-0">Enrolled Grades:</p>

            <p className="school_data">
              {" "}
              {romanNumeralsString === null ||
              romanNumeralsString === "" ||
              romanNumeralsString === undefined
                ? "-"
                : romanNumeralsString}
            </p>
          </div>
          <div class="row">
            <div class="col ">
              <div className="Contact-div">
                <p className="sub-header mb-0">Contact No.</p>

                <p className="school_data">
                  {" "}
                  {schoolData.sch_poc_contact === null ||
                  schoolData.sch_poc_contact === ""
                    ? "-"
                    : schoolData.sch_poc_contact}
                </p>
              </div>
              <div className="Enrolled-date-div">
                <p className="sub-header mb-0">PHN Enrolled Date:</p>

                <p className="school_data">
                  {schoolData.sch_phn_enrollment_date === null ||
                  schoolData.sch_phn_enrollment_date === ""
                    ? "-"
                    : schoolData.sch_phn_enrollment_date}
                </p>
              </div>
              <div className="Address-div">
                <p className="sub-header mb-0">Address:</p>

                <p className="school_data">
                  {" "}
                  {schoolData.sch_address === null ||
                  schoolData.sch_address === ""
                    ? "-"
                    : schoolData.sch_address}
                </p>
              </div>
            </div>
            <div class="col">
              <div className="Email-div">
                <p className="sub-header mb-0">Email:</p>

                <p className="school_data">
                  {schoolData.sch_poc_email === null ||
                  schoolData.sch_poc_email === ""
                    ? "-"
                    : schoolData.sch_poc_email}
                </p>
              </div>
              <div className="Intake-div">
                <p className="sub-header mb-0">Enrolled Students :</p>

                <p className="school_data">
                  {schoolData.sch_phn_intake === null ||
                  schoolData.sch_phn_intake === ""
                    ? "-"
                    : schoolData.sch_phn_intake}
                  /{" "}
                  {schoolData.sch_total_intake === null ||
                  schoolData.sch_total_intake === ""
                    ? "-"
                    : schoolData.sch_total_intake}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleSchoolTrainer;
