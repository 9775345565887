import React, { useEffect, useState } from "react";
import "../Assets/Styles/ManagerDashboard.css";
import editIcon from "../Assets/Images/ManagerDashboard/edit-icon.svg";
import { Link } from "react-router-dom";
import viewAllArrow from "../Assets/Images/Dashboard/viewAllArrow.svg";
import { GET_LEADER_BOARD } from "../Components/AllAPI";

const LeaderBoard = () => {
  const [schoolData, setSchoolData] = useState([]);

  useEffect(() => {
    var token = localStorage.getItem("authToken");
    fetch(GET_LEADER_BOARD, {
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log('LeaderBoard',json);
        setSchoolData(json.schools);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-12 col-lg-8">
          <p className="recentlyAddedTitle">Leader Board</p>
        </div>
        <div className="col-sm-12 col-md-12 col-12 col-lg-4">
          <Link to="/superadmin/schoollist" style={{ float: "right" }}>
            <button className="btn view_all_btn">
              View all <img src={viewAllArrow} alt="viewAllArrow" />{" "}
            </button>
          </Link>
        </div>
      </div>

      <table className="table table-borderless studentTable">
        <thead>
          <tr>
            <th scope="col">Sr.No.</th>
            <th scope="col">Name</th>
            <th scope="col">School POC</th>
            <th scope="col">POC Mobile</th>
            <th scope="col">Location</th>
            <th scope="col">Progress</th>
          </tr>
        </thead>
        <tbody>
          {schoolData.length !== 0 ? (
            schoolData.slice(0, 5).map((data, ind) => {
              return (
                <tr key={ind}>
                  <td>{ind + 1}</td>
                  <td>{data.sch_name}</td>
                  <td>{data.sch_poc_name}</td>
                  <td>{data.sch_poc_contact}</td>
                  <td>{data.sch_district}</td>
                  <td>{data.progress}%</td>
                </tr>
              );
            })
          ) : (
            <div className="text-center mt-3 pt-3">
              <div className="noData">No data available</div>
            </div>
          )}
        </tbody>
      </table>
    </>
  );
};

export default LeaderBoard;
