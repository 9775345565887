import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import DayDateProfile from "../Components/DayDateProfile";
import sidebarData from "../Assets/Json/sidebarJson.json";
import ProfileStudent from "./ProfileStudent";
import { useNavigate } from "react-router-dom";
import StudentCompletion from "./StudentCompletion";
import LottieAnimation from "../Components/LottieAnimation";
import Continuechapters from "./Continuechapters";
import StudentAttendanceChart from "../Components/StudentAttendanceChart";

function StudentDashboard() {
  let studentSidebar = sidebarData.Student;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.history.replaceState(null, null, "/studentdashboard");
  });
  useEffect(() => {
    // Prevent going back to the previous page after logging out
    const clearHistory = () => {
      window.history.pushState(null, window.location.pathname);
      window.onpopstate = () => {
        navigate("/studentdashboard");
      };
    };
    clearHistory();
    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <Sidebar sidebarLinks={studentSidebar}></Sidebar>
      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          <section className="sidebody">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-12">
                  {/* <DayDateProfile /> */}
                  <StudentCompletion />
                  <StudentAttendanceChart />
                  {/* <Charts /> */}
                  <Continuechapters />
                </div>
                <div
                  className="col-lg-4 col-md-12 col-12 mt-5 pt-5"
                  style={{ borderLeft: "2px solid #F3F5F7" }}
                >
                  <ProfileStudent />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default StudentDashboard;
