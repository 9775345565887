import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import viewAllArrow from "../Assets/Images/Dashboard/viewAllArrow.svg";
import loader from "../Assets/Images/Dashboard/loader_lottie.json";
import syllabusImg from "../Assets/Images/Dashboard/firstLesson.png";
import Lottie from "react-lottie";
import { GET_CONTINUE_CHAPTERS } from "../Components/AllAPI";
const Continuechapters = () => {
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const fetchData = () => {
    var token = localStorage.getItem("authToken");
    setLoading(true);
    fetch(GET_CONTINUE_CHAPTERS, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setChapters(data.videoAssessment);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="mt-5 pt-5">
          <Lottie options={defaultOptions} height={100} width={100} />
        </div>
      ) : (
        <>
          <div className="d-flex mt-5">
            <p className="continueChapterTitle">Continue Chapters</p>
            <div className="ms-auto me-4">
              <Link to="/student/courseslist" className="viewAllChapter" onClick={()=>localStorage.setItem("courseChapterActive","Chapter")}>
                View all <img src={viewAllArrow} alt="viewAllArrow" />
              </Link>
            </div>
          </div>

          <div className="row">
            {chapters.length > 0 ? (
              chapters.slice(0,4).map((list, ind) => {
                // console.log("chapters", list);
                return (
                  <div className="col-lg-6 col-12 col-sm-6" key={ind}>
                    <Link
                      to="/student/courseparticularchapter"
                      style={{ color: "black", textDecoration: "none" }}
                      state={{
                        courses: list,
                      }}
                    >
                      <>
                        {list.chapter_thumbnail === "" ||
                        list.chapter_thumbnail === null ? (
                          <img
                            src={syllabusImg}
                            alt="courses_img"
                            className="Syllabus_Images"
                            width={300}
                          />
                        ) : (
                          <img
                            src={list.chapter_thumbnail}
                            alt="courses_img"
                            className="Syllabus_Images"
                            width={300}
                            style={{borderRadius:"16px"}}
                          />
                        )}

                        <div className="chapter_desc">
                          <p className="chapter_number">{`Chapter ${list.chapter_no}`}</p>
                          <p className="chapter_title">{list.chapter_name}</p>
                        </div>
                      </>
                    </Link>
                  </div>
                );
              })
            ) : (
              <div className="text-center mb-5">
                <div className="noData">No data available</div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Continuechapters;
