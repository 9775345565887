import React, { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import trainersAllocated from "../Assets/Images/ManagerDashboard/trainers-allocated-icon.svg";
import schoolsEnrolled from "../Assets/Images/ManagerDashboard/enrolled-icon.svg";
import { GET_SUPER_ADMIN_CHART } from "../Components/AllAPI";

const ChartSuperAdmin = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var token = localStorage.getItem("authToken");

        const response = await fetch(GET_SUPER_ADMIN_CHART, {
          method: "GET",
          headers: {
            authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json"
          },
        });
        // const data = await response.json();
        // setChartData(data);
        if (response.status === 200) {
          const data = await response.json();
          // console.log("chart ", data.data);
          setChartData(data.data);
        } else {
          setChartData([
            { month: "Jan", average: 0 },
            { month: "Feb", average: 0 },
            { month: "Mar", average: 0 },
            { month: "Apr", average: 0 },
            { month: "May", average: 0 },
            { month: "Jun", average: 0 },
            { month: "Jul", average: 0 },
            { month: "Aug", average: 0 },
            { month: "Sep", average: 0 },
            { month: "Oct", average: 0 },
            { month: "Nov", average: 0 },
            { month: "Dec", average: 0 },
          ]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <p className="chart_title mt-5 mb-0">School and Trainers</p>
      <div
        className="p-5"
        // style={{
        //   borderRadius: "16px",
        //   border: "1px solid var(--text-20-stroke, #E7E6EA)",
        // }}
      >
        <div className="chart-info d-flex" style={{ marginLeft: "50%" }}>
          <p>
            <img src={schoolsEnrolled} alt="schoolsEnrolledImg" />
            <span className="graphAbout ms-2">Students</span>
          </p>
          <p style={{ marginLeft: "10px" }}>
            <img src={trainersAllocated} alt="" />
            <span className="graphAbout ms-2">Trainers</span>
          </p>
        </div>
        <ResponsiveContainer aspect={2} width={700}>
          <BarChart data={chartData}>
            <XAxis dataKey="month" />
            <YAxis domain={[0, 100]} tickFormatter={(tick) => `${tick}%`} />
            <Bar
              dataKey="average_students"
              fill="rgba(250, 95, 28, 1)"
              radius={[20, 20, 0, 0]}
            />
            <Bar
              dataKey="average_trainers"
              fill="#FEDFD2"
              radius={[20, 20, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default ChartSuperAdmin;
