import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import { Link, useLocation } from "react-router-dom";
import DayDateProfile from "../Components/DayDateProfile";
// import DummyVideos from "./DummyVideos";
import nextArrow from "../Assets/Images/Dashboard/next_icon.png";
import ChapterVideo from "./ChapterVideoList";
import ChapterVideoList from "./ChapterVideoList";
function CourseParticularChapter() {
  const location = useLocation();
  const syllabusData = location.state.courses;
//  console.log('particular chapter',syllabusData);
  return (
    <>
      <Sidebar sidebarLinks={sidebarData.Student} />
      <div className="sidebody">
        <div className="container">
          {/* <DayDateProfile /> */}
          {syllabusData && (
            // syllabus details
            <div className="ChapterDetails mt-4 mb-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/student/courseslist" className="prevPageTitle">
                      Courses
                    </Link>
                  </li>
                  <img src={nextArrow} alt="nextArrow" />
                  <p
                    className="currentPageTitle mt-0 mb-0"
                    style={{ color: "#211C37" }}
                  >
                    {`Chapter ${syllabusData.chapter_no}`}
                  </p>
                </ol>
              </nav>
              <div className="row mt-4">
                <div className="col-lg-7 col-md-7">
                  <div className="Header-video-div">
                    <div className="chapter-div">
                      <p style={{ marginBottom: "0px" }}>
                        {`Chapter ${syllabusData.chapter_no}`}
                      </p>
                    </div>
                    <div className="Topic-div">
                      <p className="Header-Video">
                        {syllabusData.chapter_name}
                      </p>
                    </div>
                  </div>

                  <div className="Image-Div mt-4">
                    <img
                      className="Video-Image"
                      src={syllabusData.chapter_thumbnail}
                      alt="Video-Preview"
                      width="96%"
                    />
                  </div>
                  <div className="Description-Div">
                    <div className="Description-Header-Text">
                      <p>Description</p>
                    </div>
                    <div className="Description-Text">
                      <p className="inner-text">
                        {syllabusData.chapter_description}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-5 col-md-5 second-column ps-4"
                  style={{ borderLeft: "1px solid #E7E6EA" }}
                >
                  <div className="Topics-Covered-Div">
                    <p className="Header-Topics">Assessment</p>
                  </div>
                  <ChapterVideoList syllabusData={syllabusData} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CourseParticularChapter;
