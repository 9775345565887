import React, { useEffect, useState } from "react";
import "../Assets/Styles/ManagerDashboard.css";
import editIcon from "../Assets/Images/ManagerDashboard/edit-icon.svg";
import { Link } from "react-router-dom";
import viewAllArrow from "../Assets/Images/Dashboard/viewAllArrow.svg";
import { GET_RECENTLY_ADDED_SCHOOLS } from "../Components/AllAPI";

const RecentlyAddedSchools = () => {
  const [schoolData, setSchoolData] = useState([]);

  useEffect(() => {
    var token = localStorage.getItem("authToken");
    fetch(GET_RECENTLY_ADDED_SCHOOLS, {
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
        setSchoolData(json.recent_schools.schools);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="row" style={{marginBottom:'-20px'}}>
        <div className="col-sm-12 col-md-12 col-12 col-lg-8">
          <p className="recentlyAddedTitle mb-0">
            Recently Added and Unallocated Schools
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-12 col-lg-4">
          <Link to="/manager/schoollist" style={{ float: "right" }}>
            <button className="btn view_all_btn">
              View all <img src={viewAllArrow} alt="viewAllArrow" />
            </button>
          </Link>
        </div>
      </div>

      <table className="table table-borderless studentTable" style={{position:'relative'}}>
        <thead>
          <tr>
            <th scope="col">Sr.No.</th>
            <th scope="col">Name</th>
            <th scope="col">School POC</th>
            <th scope="col">Location</th>
            <th scope="col">Intake</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        {schoolData?.length > 0 ? (
          <tbody>
            {schoolData.slice(0, 4).map((data, ind) => {
              return (
                <tr key={ind}>
                  <td>{ind + 1}</td>
                  <td>{data.sch_name}</td>
                  <td>{data.sch_poc_name}</td>
                  <td>{data.sch_address}</td>
                  <td>
                    {data.sch_phn_intake}/{data.sch_total_intake}
                  </td>
                  <td style={{ cursor: "pointer" }}>
                    <Link to={"/manager/editschool"} state={{ data }}>
                      {" "}
                      <img src={editIcon} alt="EditIcon" />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <>
            <p className="recentlyAddedTitle text-center py-3" style={{position:'absolute',left:'30%'}}>
              No Data Available
            </p>
          </>
        )}
      </table>
    </>
  );
};

export default RecentlyAddedSchools;
