import React, { useEffect, useState } from "react";
import profileImg from "../Assets/Images/StudentList/student_profile.svg";
import { GET_MGR_PROFILE_MANAGER } from "../Components/AllAPI";

const ProfileManager = () => {
  const [profile, setProfile] = useState({});
  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_MGR_PROFILE_MANAGER, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setProfile(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section className="profile">
        <div className="container">
          <p className="profile_title">Profile</p>
          <div className="profilePlaceholder">
            <img src={profileImg} alt="" />
          </div>
          <p className="student_name">{profile.mgr_name}</p>

          <div className="stud_data">
            <div className="row">
              <div className="col-6">
                <p className="data_title mb-1">PHN Employee id</p>
                <p className="data_subititle">{profile.mgr_emp_id}</p>
              </div>
              <br />
              <div className="col-6">
                <p className="data_title mb-1">Blood Group</p>
                <p className="data_subititle">{profile.mgr_blood_grp}</p>
              </div>

              <div className="col-6">
                <p className="data_title mb-1">Mobile no</p>
                <p className="data_subititle">{profile.mgr_phone}</p>
              </div>
              <br />
              <div className="col-12">
                <p className="stud_addres_title mb-1">Email Id</p>
                <p className="stud_address_subtitle">{profile.mgr_email}</p>
              </div>

              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileManager;
