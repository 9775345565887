import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import { POST_MGR_CREATE_SUPER_ADMIN_API } from "../Components/AllAPI";
import { GET_MGR_SUPER_ADMIN_SCH_LIST_API } from "../Components/AllAPI";
import defaultSchoolLogo from "../Assets/Images/Dashboard/school_logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DayDateProfile from "../Components/DayDateProfile";
import { ToastContainer, toast } from "react-toastify";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const AddSuperAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // console.log("state", location.state?.data);
  const preData = location.state?.data;
  // let preDataSch_id;
  // if (preData && preData.associated_schools) {
  //   preData.associated_schools.map((schoolData) => {
  //     preDataSch_id = schoolData.sch_id;
  //   });
  //   console.log("id", preDataSch_id);
  // }
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [schools, setSchools] = useState([]);
  const [originalSchools, setOriginalSchools] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [preSchools, setPreSchools] = useState(preData);
  const [errorMessage, setErrorMessage] = useState("");
  // console.log("preSchools", preSchools);
  const [formData, setFormData] = useState({
    superAdminName: preData ? preData.su_adm_name : "",
    mobileNumber: preData ? preData.mobile_number : "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [hoveredSchool, setHoveredSchool] = useState(null);
  const [checkboxValidationMessage, setCheckboxValidationMessage] =
    useState("");
  useEffect(() => {
    if (preData && preData.associated_schools) {
      const preselectedSchoolIds = preData.associated_schools.map(
        (schoolData) => schoolData.sch_id
      );
      setSelectedSchools(preselectedSchoolIds);
    }
  }, [preData]);
  let managerSidebar = sidebarData.Manager;
  var token = localStorage.getItem("authToken");
  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    const nameRegx = /^[A-Z a-z]+$/;
    if (!formData.superAdminName.trim()) {
      newErrors.superAdminName = "Please enter super admin name.";
      valid = false;
    } else if (!nameRegx.test(formData.superAdminName.trim())) {
      newErrors.superAdminName = "Please enter valid super admin name.";
      valid = false;
    }
    const mobileNumberRegex = /^[6789][0123456789]{9}$/;
    if (!formData.mobileNumber.trim()) {
      newErrors.mobileNumber = "Please enter mobile no";
      valid = false;
    } else if (!mobileNumberRegex.test(formData.mobileNumber.trim())) {
      newErrors.mobileNumber = "Please enter valid mobile no.";
      valid = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      newErrors.email = "Please enter email.";
      valid = false;
    } else if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Please enter valid email.";
      valid = false;
    }
    if (selectedSchools.length === 0) {
      setCheckboxValidationMessage("Please select at least one school.");
      valid = false;
    } else {
      setCheckboxValidationMessage("");
    }
    setErrors(newErrors);
    return valid;
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // setErrors({
    //   ...errors,
    //   [name]: "",
    // });
    // setCheckboxValidationMessage("");
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await fetch(GET_MGR_SUPER_ADMIN_SCH_LIST_API, {
          method: "GET",
          headers: {
            Authorization: `bearer ${token}`,
          },
        });
        // console.log(response)
        if (!response.ok) {
          setErrorMessage(
            "Something went wrong while fetching data. Please try again later."
          );

          throw new Error("Failed to fetch schools data");
        }
        const data = await response.json();
        // console.log("data", data);
        setOriginalSchools(data);
        setSchools(data);
      } catch (error) {
        console.error("Error fetching schools data:", error);
        setErrorMessage(
          "Something went wrong while fetching data. Please try again later."
        );
      }
    };

    fetchSchools();
  }, []);

  useEffect(() => {
    if (preData === undefined) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    // setCheckboxValidationMessage("");
    // const updatedSelectedSchools =preData.associated_schools[0];
    // console.log('updated',updatedSelectedSchools);
    // const index = updatedSelectedSchools.indexOf(school.sch_id);
    // if (index === -1) {
    //   updatedSelectedSchools.push(school.sch_id);
    // } else {
    //   updatedSelectedSchools.splice(index, 1);
    // }
    // setSelectedSchools(updatedSelectedSchools);
  }, [preData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    const filteredSchools = originalSchools.filter(
      (school) =>
        school.sch_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        school.sch_district
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setSchools(filteredSchools);
  };

  const handleCheckboxChange = (school) => {
    setCheckboxValidationMessage("");
    const updatedSelectedSchools = [...selectedSchools];
    const index = updatedSelectedSchools.indexOf(school.sch_id);
    if (index === -1) {
      updatedSelectedSchools.push(school.sch_id);
    } else {
      updatedSelectedSchools.splice(index, 1);
    }
    setSelectedSchools(updatedSelectedSchools);
  };

  const handleRemoveSchool = (schoolId) => {
    const updatedSelectedSchools = selectedSchools.filter(
      (id) => id !== schoolId
    );
    setSelectedSchools(updatedSelectedSchools);
  };

  const handleCancel = () => {
    // setFormData({
    //   superAdminName: "",
    //   mobileNumber: "",
    //   email: "",
    // });
    // setErrors({});
    // setSelectedSchools([]);
    // setCheckboxValidationMessage("");
    navigate("/manager/superadmindetails");
  };

  const handleAddAdmin = async () => {
    if (validateForm()) {
      const selected_school = selectedSchools.join(",");
      // console.log("selected", selected_school);
      const data = {
        user_name: formData.superAdminName,
        email: formData.email,
        user_phone: formData.mobileNumber,
        su_adm_schools: selected_school,
      };

      // console.log("data", data);
      // const seschool = selectedSchools.join(",");
      // console.log('seschool: ',seschool);

      try {
        const response = await fetch(POST_MGR_CREATE_SUPER_ADMIN_API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        let resJson = await response.json();
        const msg = resJson.message;
        console.log("msg", msg);

        if (msg === "Super Admin created successfully") {
          toast.success("Admin created successfully", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => {
              navigate("/manager/superadmindetails");
            },
          });
        } else if (msg === "User with this email already exists.") {
          alert("user with this email already exists.");
        }
      } catch (err) {
        console.error(`Error! ${err}`);
      }
    } else {
      console.log("Form validation failed");
    }
  };

  return (
    <>
      {errorMessage}
      <Sidebar sidebarLinks={managerSidebar} />
      <div className="sidebody">
        <div className="container detail-section">
          <div>
            {/* <DayDateProfile /> */}
            <p className="adminDetailHeader-text mt-4">Admin Details</p>
            <ToastContainer
              position="top-center"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
            <div className="adminDetail-inputs-main">
              <div className="adminDetail-inputs">
                <input
                  type="text"
                  placeholder="Super Admin Name*"
                  className="adminInputField"
                  name="superAdminName"
                  // value={
                  //   preData === undefined
                  //     ? formData.superAdminName
                  //     : preData.su_adm_name
                  // }
                  value={formData.superAdminName}
                  // disabled={isDisabled}
                  onChange={handleInputChange}
                />
                {errors.superAdminName && (
                  <p className="error-msg mt-1">{errors.superAdminName}</p>
                )}
              </div>

              <div className="adminDetail-inputs">
                <input
                  type="text"
                  placeholder="Mobile Number*"
                  className="adminInputField"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  maxLength={10}
                  minLength={10}
                />
                {errors.mobileNumber && (
                  <p className="error-msg mt-1">{errors.mobileNumber}</p>
                )}
              </div>

              <div className="adminDetail-inputs">
                <input
                  type="text"
                  placeholder="Email Id*"
                  className="adminInputField"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && (
                  <p className="error-msg mt-1">{errors.email}</p>
                )}
              </div>

              <div className="dropdown card adminInputDropdown-card mb-0">
                <div className="card-body adminDropdown_card-body">
                  <div
                    className="adminDropdownField "
                    onClick={toggleDropdown}
                    style={{ border: "none", marginBottom: "0px" }}
                  >
                    Select Schools*
                    <p className={`icon ${isOpen ? "iconup" : ""}`}>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="fa-chevron-up "
                      />
                    </p>
                  </div>
                  {isOpen && (
                    <div className="adminDetail-dropdown-content">
                      <div className="dropdown-divider"></div>
                      <input
                        type="search"
                        placeholder="Search Schools"
                        className="searchInput adminDropdown-search"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      {schools.length === 0 ? (
                        <div className="text-center my-4">
                          <p className="no-data-message">No data found.</p>
                        </div>
                      ) : (
                        <div className="adminSchoolList">
                          <ul className="list-group">
                            {schools.map((school, index) => (
                              <li
                                className="list-group-item adminlist-group-item"
                                key={index}
                              >
                                <div className="d-flex adminSchoolListingDiv">
                                  <div className="d-flex">
                                    <img
                                      src={
                                        school.sch_logo
                                          ? school.sch_logo
                                          : defaultSchoolLogo
                                      }
                                      alt="logo"
                                      style={{
                                        width: "22px",
                                        height: "22px",
                                      }}
                                      onError={(e) => {
                                        e.target.src = defaultSchoolLogo;
                                      }}
                                      className="admin_DropdownList_logo"
                                    />
                                    <p className="schoolList-schoolname">
                                      {school.sch_name}
                                    </p>
                                  </div>
                                  <div className="d-flex">
                                    <p className="schoolList-districtname">
                                      {school.sch_district}
                                    </p>
                                    <input
                                      type="checkbox"
                                      checked={selectedSchools.includes(
                                        school.sch_id
                                      )}
                                      className="form-check-input adminSchoolListCheckbox"
                                      style={{
                                        width: "24px",
                                        height: "24px",
                                        margin: "0",
                                      }}
                                      onChange={() =>
                                        handleCheckboxChange(school)
                                      }
                                    />
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {checkboxValidationMessage && (
                <p className="error-msg mt-1">{checkboxValidationMessage}</p>
              )}
            </div>
            <div style={{ width: "100%" }}>
              {selectedSchools.length !== 0 && (
                <p className="adminSelectedSchool-text mt-3">
                  Selected Schools
                </p>
              )}
              <div className="row w-100 row-cols-auto">
                {selectedSchools &&
                  selectedSchools.map((schoolId, index) => (
                    <div
                      className="col col-lg-4 col-md-6 col-sm-12"
                      key={index}
                    >
                      <div className="card adminSelectedSchool">
                        <div
                          className="card-body adminSelectedSchool_card-body"
                          onMouseEnter={() => setHoveredSchool(schoolId)}
                          onMouseLeave={() => setHoveredSchool(null)}
                          //   onClick={handleCheckboxChange(schoolId)}
                        >
                          <div className="d-flex">
                            <img
                              src={
                                schools.find(
                                  (school) => school.sch_id === schoolId
                                )?.sch_logo || defaultSchoolLogo
                              }
                              alt="logo"
                              style={{
                                width: "36px",
                                height: "36px",
                              }}
                              className="adminDetail_school-logo"
                              onError={(e) => {
                                e.target.src = defaultSchoolLogo;
                              }}
                            />
                            <p className="selectedSchoolName">
                              {
                                schools.find(
                                  (school) => school.sch_id === schoolId
                                )?.sch_name
                              }
                            </p>
                          </div>
                          {schoolId && (
                            <p style={{ margin: "6px" }}>
                              <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() => handleRemoveSchool(schoolId)}
                                style={{ cursor: "pointer", color: "red" }}
                              />
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="next_prev_button_div">
            <button
              type="button"
              className="btn cancel-btn-admin"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn add-btn-admin"
              onClick={handleAddAdmin}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSuperAdmin;
