import React, { useEffect, useState } from "react";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import DayDateProfile from "../Components/DayDateProfile";
import editIcon from "../Assets/Images/ManagerDashboard/edit-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import Excelexport from "../Excelexport";
import AddTrainer from "./AddTrainer";
import deleteIcon from "../Assets/Images/ManagerDashboard/delete-icon.svg";
import BulkImport from "./BulkImport";
import TrainerBulkImport from "../TrainerPages/TrainerBulkImport";
import LottieAnimation from "../Components/LottieAnimation";
import prevArrow from "../Assets/Images/SuperAdmin/prev_arrow.png";
import nextArrow from "../Assets/Images/SuperAdmin/next_arrow.png";
import { GET_MGR_TRAINERS_LIST, API_START_POINT } from "../Components/AllAPI";
import { ToastContainer, toast } from "react-toastify";

const TrainerList = () => {
  const initialData = {
    tra_name: "",
    tra_qualification: "",
    tra_experience: "",
  };
  const [editedData, setEditedData] = useState(initialData);
  const [searchInput, setSearchInput] = useState("");
  const [trainerData, setTrainerData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [apiResult, setApiResult] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const navigate = useNavigate();

  let managerSidebar = sidebarData.Manager;

  $(document).ready(function () {
    $(".schoolList .nav-link").click(function () {
      $(".schoolList .nav-link").removeClass("active");
      $(this).addClass("active");
    });
  });

  useEffect(() => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(GET_MGR_TRAINERS_LIST, {
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log("trainer list", json);
        setApiResult(json);
        setTrainerData(json.allTrainers);
        setIsLoading(false);
      });
  }, []);

  const editTrainer = (ind) => {
    setEditedData(ind);
  };
  const updateTrainer = (e) => {
    e.preventDefault();
    var token = localStorage.getItem("authToken");
    fetch(`${API_START_POINT}/trainer/update/${editedData.tra_id}`, {
      method: "PUT",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tra_name: editedData.tra_name,
        tra_qualification: editedData.tra_qualification,
        tra_experience: editedData.tra_experience,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log(json.message);
        const msg = json.message;
        if (msg === "Trainer updated successfully") {
          toastify("Trainer updated successfully");
        }
        // setTrainerData(json.allTrainers)
      });
  };

  const handlerTrainerDetails = (e) => {
    const { name, value } = e.target;
    setEditedData((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const deleteTrainer = () => {
    setShowModal(true);
  };
  const toastify = (msg) => {
    toast.success(msg, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      onClose: () => {
        window.location.reload();
      },
    });
  };
  const yesBtnHandler = (e) => {
    e.preventDefault();
    // console.log("yes");
    var token = localStorage.getItem("authToken");
    fetch(`${API_START_POINT}/trainer/delete/${editedData.tra_id}`, {
      method: "DELETE",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const msg = json.message;
        if (msg === "Trainer profile deleted successfully") {
          toastify("Trainer profile deleted successfully");
        }
      });
  };

  const hideEditModal = () => {
    window.location.reload();
  };

  const getBgColor = (school_name_value) => {
    if (school_name_value === "Unallocated") {
      return "#FFF4F0";
    } else if (school_name_value === "allocated") {
      return "#fff";
    }
  };
  useEffect(() => {
    if (trainerData && trainerData.length > 0) {
      const filtered = trainerData.filter((data) => {
        return searchInput.toLowerCase() === ""
          ? data
          : data.tra_name.toLowerCase().includes(searchInput) ||
              data.tra_sch_id.toString().includes(searchInput) ||
              data.tra_phone.toString().includes(searchInput) ||
              data.tra_email.toLowerCase().includes(searchInput) ||
              data.tra_qualification.toLowerCase().includes(searchInput);
      });
      setResults(filtered);
    } else {
      setResults([]);
    }
  }, [trainerData, searchInput]);

  const totalItems = results ? results.length : 0;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = results
    ? results.slice(indexOfFirstItem, indexOfLastItem)
    : [];
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const PagePerItem = Math.min(totalItems - indexOfFirstItem, indexOfLastItem);

  // Function to handle changing pages
  const handlePageChange = (pageNumber) => {
    setCurrentPage(parseInt(pageNumber));
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [trainerData]);

  // Function to go to the previous page
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Function to go to the next page
  const nextPage = () => {
    if (indexOfLastItem < totalItems) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  return (
    <>
      <Sidebar sidebarLinks={managerSidebar} />

      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          {" "}
          <div className="sidebody schoolList">
            <div className="container">
              {/* <DayDateProfile /> */}
              <div className="pt-4">
                <div className="row w-100">
                  <div className="col-lg-9 col-sm-12 col-md-12 col-12">
                    <p className="studentListTitle">Trainers List</p>
                  </div>

                  <div className="col-lg-3 col-sm-12 col-md-12 col-12">
                    <div
                      className="input-group mb-3"
                      style={{ float: "right" }}
                    >
                      <span className="input-group-text" id="basic-addon1">
                        <span className="fa fa-search"></span>
                      </span>
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <nav className="navbar navbar-expand-lg navbar-light">
                      <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav mr-auto">
                          <li className="nav-item me-3">
                            <Link
                              className="nav-link active"
                              to="#"
                              onClick={() => {
                                setTrainerData(apiResult.allTrainers);
                              }}
                            >
                              All
                            </Link>
                          </li>
                          <li className="nav-item me-3">
                            <Link
                              className="nav-link"
                              to="#"
                              onClick={() => {
                                setTrainerData(apiResult.allocatedTrainers);
                              }}
                            >
                              Allocated
                            </Link>
                          </li>
                          <li className="nav-item me-3">
                            <Link
                              className="nav-link"
                              to="#"
                              onClick={() => {
                                setTrainerData(apiResult.unAllocatedTrainers);
                              }}
                            >
                              Unallocated
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                  <div className="col-lg-3 col-12">
                    {/* <Bulkupload
                  bulkuploadAPI={"https://phnelearn.phntechnology.com/v1/api/trainer-data"}
                /> */}

                    <div className="ms-5">
                      <TrainerBulkImport
                        bulkuploadAPI={`${API_START_POINT}/import-trainer`}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-12 ps-0">
                    <div className="d-flex">
                      <Excelexport
                        excelData={trainerData}
                        fileName={"Trainer List"}
                      />

                      <AddTrainer />
                    </div>
                  </div>
                </div>
                <table className="table table-borderless studentTable text-center">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">School Allocated</th>
                      <th scope="col">Trainer Mobile</th>
                      <th scope="col">Email id</th>
                      <th scope="col">Qualification</th>
                      <th scope="col">Experience</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(results) && results.length > 0 ? (
                      currentItems.map((data, index) => {
                        const schools = data.school_name.split(",");
                        return (
                          <tr
                            key={index}
                            style={{
                              background: getBgColor(data.school_name),
                            }}
                          >
                            <td>{index + indexOfFirstItem + 1}</td>
                            <td
                              onClick={() =>
                                navigate("/specificTrainerProfile", {
                                  state: { data },
                                })
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {data.tra_name}
                            </td>
                            <td>
                              <div class="more-container">
                                {schools[0] === "Unallocated" ||
                                schools.length - 1 === 0 ? (
                                  schools[0]
                                ) : (
                                  <>
                                    {schools[0]}
                                    <p class="more-text">
                                      + {schools.length - 1} more
                                    </p>
                                    <div class="hide">
                                      <p>
                                        {schools.map((sch_list, ind) => {
                                          return ind !== 0 ? (
                                            <ul
                                              className="schoolBullets"
                                              style={{ textAlign: "left" }}
                                            >
                                              <li key={ind}>{sch_list}</li>
                                            </ul>
                                          ) : (
                                            ""
                                          );
                                        })}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                            <td>{data.tra_phone}</td>
                            <td>{data.tra_email}</td>
                            <td>{data.tra_qualification}</td>
                            <td>{data.tra_experience}</td>
                            <td onClick={() => editTrainer(data)}>
                              <img
                                src={editIcon}
                                alt="EditIcon"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop1"
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="8" className="noDataContainer">
                          <div className="noData">No data available</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {Array.isArray(results) && results.length > 0 && (
                <div className="pagination row">
                  <div className="result_msg col-12 col-lg-3 col-sm-7 col-md-7">
                    <p
                      className="result_msg_text"
                      style={{ marginBottom: "0px" }}
                    >
                      Displaying {PagePerItem} results of {totalItems}
                    </p>
                  </div>

                  <div className="button_module col-12 col-lg-9 col-sm-5 col-md-5 d-flex ">
                    <div
                      className="d-flex"
                      style={{
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <p className="paginate_msg_text">Page</p>
                      <div>
                        <select
                          className="form-select pageDropdown"
                          value={currentPage}
                          onChange={(e) => handlePageChange(e.target.value)}
                        >
                          {Array.from(
                            { length: Math.ceil(totalItems / itemsPerPage) },
                            (_, i) => i + 1
                          ).map((pageNum) => (
                            <option value={pageNum} key={pageNum}>
                              {pageNum}&nbsp;
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="paginate_msg_text">
                        of {Math.ceil(totalItems / itemsPerPage)}
                      </p>
                    </div>

                    <div className=" d-flex">
                      <button
                        type="button"
                        className="bton"
                        onClick={prevPage}
                        disabled={currentPage === 1}
                      >
                        <img src={nextArrow} alt="next" className="arrow_btn" />
                      </button>

                      <button
                        type="button"
                        className="bton"
                        onClick={nextPage}
                        disabled={indexOfLastItem >= totalItems}
                      >
                        <img src={prevArrow} alt="prev" className="arrow_btn" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <!-- Modal --> */}
          <div
            className="modal fade"
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
              <div className="modal-content">
                <div className="modal-header border-0">
                  <p className="modal-title">Edit Trainer</p>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="container mt-3">
                  <form>
                    {" "}
                    <input
                      type="text"
                      placeholder="Name"
                      name="tra_name"
                      className="form-control"
                      value={editedData.tra_name}
                      onChange={handlerTrainerDetails}
                    />
                    <input
                      type="text"
                      placeholder="Mobile"
                      maxLength={10}
                      minLength={10}
                      name="tra_phone"
                      className="form-control mt-4"
                      value={editedData.tra_phone}
                      readOnly
                    />
                    <input
                      type="text"
                      placeholder="Email id"
                      name="tra_email"
                      className="form-control mt-4"
                      value={editedData.tra_email}
                      readOnly
                    />
                    <input
                      type="text"
                      placeholder="Qualification"
                      name="tra_qualification"
                      className="form-control mt-4"
                      value={editedData.tra_qualification}
                      onChange={handlerTrainerDetails}
                    />
                    <div className="my-4">
                      <select
                        name="tra_experience"
                        id="workExpDropdown"
                        onChange={handlerTrainerDetails}
                      >
                        <option value={editedData.tra_experience}>
                          {editedData.tra_experience}
                        </option>
                        <option value="Fresher">Fresher</option>
                        <option value="1-2 yrs">1-2 yrs</option>
                        <option value="2-3 yrs">2-3 yrs</option>
                        <option value="3-4 yrs">3-4 yrs</option>
                        <option value="4-5 yrs">4-5 yrs</option>
                        <option value="5-6 yrs">5-6 yrs</option>
                        <option value="6-7 yrs">6-7 yrs</option>
                        <option value="7-8 yrs">7-8 yrs</option>
                        <option value="8-9 yrs">8-9 yrs</option>
                        <option value="9-10 yrs">9-10 yrs</option>
                        <option value="above 10 yrs">Above 10 yrs</option>
                      </select>
                    </div>
                    <div className="text-center mt-5 mb-4">
                      <input
                        type="submit"
                        className="submit_btn"
                        value="Update"
                        id="submit_btn"
                        onClick={updateTrainer}
                      />
                    </div>
                    <div className="text-center mb-5">
                      <button
                        type="button"
                        className="btn deleteIcon"
                        onClick={deleteTrainer}
                      >
                        {" "}
                        <img src={deleteIcon} alt="deleteIcon" /> Delete Trainer
                      </button>

                      {showModal ? (
                        <div className="confirmDelete text-center">
                          <p className="deleteTitle">Delete Trainer</p>
                          <p className="deleteMsg">
                            Are you sure you want to delete trainer{" "}
                          </p>
                          <div className="row">
                            <div className="col-lg-6 col-sm-12 col-md-6">
                              <button
                                className="btn yes_btn"
                                onClick={yesBtnHandler}
                              >
                                Yes
                              </button>
                            </div>

                            <div className="col-lg-6 col-sm-12 col-md-6">
                              <button
                                className="btn no_btn"
                                onClick={hideEditModal}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TrainerList;
