import React, { useEffect, useState } from "react";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import DayDateProfile from "../Components/DayDateProfile";
import editIcon from "../Assets/Images/ManagerDashboard/edit-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import Excelexport from "../Excelexport";
import LottieAnimation from "../Components/LottieAnimation";
import { GET_SUPER_ADMIN_SCHOOLS_API } from "../Components/AllAPI";
import prevImg from "../Assets/Images/SuperAdmin/prev_arrow.png";
import nextImg from "../Assets/Images/SuperAdmin/next_arrow.png";
import sortArrow from "../Assets/Images/SuperAdmin/sorting_arrows.png";

const SchoolListSuperAdmin = () => {
  const [searchInput, setSearchInput] = useState("");
  const [schoolsData, setSchoolsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);
  const itemsPerPage = 10;
  const [sortImg, setSortImg] = useState("downArrow");
  const [currentPage, setCurrentPage] = useState(1);

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      // setSortDirection((prevDirection) =>
      //   prevDirection === "asc" ? "desc" : "asc"
      // );
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      setSortImg("upArrow");
    } else {
      setSortImg("downArrow");
      setSortBy(column);
      setSortDirection("asc");
    }
  };

  const navigate = useNavigate();
  let superAdminSidebar = sidebarData.SuperAdmin;

  const allocatedSchools = () => {
    const allocatedFileteredData = schoolsData.filter((data) => {
      return data.trainer_name !== "Unallocated";
    });

    setSchoolsData(allocatedFileteredData);
  };

  const unAllocatedSchools = () => {
    const unallocatedFilteredData = schoolsData.filter((list) => {
      return list.trainer_name === "Unallocated";
    });
    setSchoolsData(unallocatedFilteredData);
  };

  useEffect(() => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");

    fetch(GET_SUPER_ADMIN_SCHOOLS_API, {
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log("schools list", json);
        setSchoolsData(json);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getBgColor = (school_name_value) => {
    if (school_name_value === "Unallocated") {
      return "#FFF4F0";
    } else if (school_name_value === "allocated") {
      return "#fff";
    }
  };

  $(document).ready(function () {
    $(".schoolList .nav-link").click(function () {
      $(".schoolList .nav-link").removeClass("active");
      $(this).addClass("active");
    });
  });

  const navigateToTrainerDetails = (sch_id) => {
    navigate("/superadmin/trainerschooldetails", {
      state: sch_id,
    });
  };
  useEffect(() => {
    if (schoolsData && schoolsData.length > 0) {
      const filtered = schoolsData.filter((data) => {
        return searchInput.toLowerCase() === ""
          ? data
          : data.trainer_name.toLowerCase().includes(searchInput) ||
              data.sch_name.toLowerCase().includes(searchInput) ||
              data.sch_poc_name.toLowerCase().includes(searchInput) ||
              data.sch_poc_contact.toString().includes(searchInput) ||
              data.sch_address.toLowerCase().includes(searchInput);
      });
      setResults(filtered);
    } else {
      setResults([]);
    }
  }, [schoolsData, searchInput]);

  const sortedData = sortBy
    ? schoolsData.slice(indexOfFirstItem, indexOfLastItem).sort((a, b) => {
        const keyA = a[sortBy];
        const keyB = b[sortBy];
        if (sortDirection === "asc") {
          return keyA.localeCompare(keyB);
        } else {
          return keyB.localeCompare(keyA);
        }
      })
    : schoolsData.slice(indexOfFirstItem, indexOfLastItem);

  const totalItems = schoolsData.length;
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const numberofItems = Math.min(
    totalItems - indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <>
      <Sidebar sidebarLinks={superAdminSidebar} />
      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          <div className="sidebody schoolList">
            <div className="container">
              {/* <DayDateProfile /> */}
              <div className="pt-4">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-md-12 col-12">
                    <p className="studentListTitle">School List</p>
                  </div>
                  <div className="col-lg-2 col-sm-12 col-md-12 col-12">
                    <div style={{ float: "right" }}>
                      <Excelexport
                        excelData={schoolsData}
                        fileName={"Student List"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-md-12 col-12">
                    <div
                      className="input-group mb-3"
                      style={{ float: "right" }}
                    >
                      <span className="input-group-text" id="basic-addon1">
                        <span className="fa fa-search"></span>
                      </span>
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <table className="table table-borderless studentTable mt-4">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No.</th>
                      <th scope="col" className="py-0" style={{ cursor: "pointer" }}>
                        School
                        <button
                          className="sort_button btn"
                          type="button"
                          onClick={() => handleSort("sch_name")}
                        >
                          {" "}
                          <img src={sortArrow} />
                        </button>
                      </th>
                      <th className="py-0" 
                        scope="col"
                        onClick={() => handleSort("trainer_name")}
                        style={{ cursor: "pointer" }}
                      >
                        Trainer
                        <button
                          className="sort_button btn"
                          type="button"
                          onClick={() => handleSort("trainer_name")}
                        >
                          {" "}
                          <img src={sortArrow} />
                        </button>
                      </th>
                      <th
                        scope="col"
                        onClick={() => handleSort("sch_poc_name")}
                        style={{ cursor: "pointer" }} className="py-0" 
                      >
                        School POC
                        <button
                          className="sort_button btn"
                          type="button"
                          onClick={() => handleSort("sch_poc_name")}
                        >
                          {" "}
                          <img src={sortArrow} />
                        </button>
                      </th>
                      <th scope="col">POC Mobile </th>
                      <th
                        scope="col"
                        onClick={() => handleSort("sch_address")}
                        style={{ cursor: "pointer" }} className="py-0" 
                      >
                        Location{" "}
                        <button
                          className="sort_button btn"
                          type="button"
                          onClick={() => handleSort("sch_address")}
                        >
                          {" "}
                          <img src={sortArrow} />
                        </button>
                      </th>
                      <th scope="col">Avg. Attend.</th>
                      <th scope="col">Avg. Course Prog.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(results) && results.length > 0 ? (
                      sortedData.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              background: getBgColor(data.trainer_name),
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              navigateToTrainerDetails(data.sch_id)
                            }
                          >
                            <td>{index + 1}</td>
                            <td>{data.sch_name}</td>
                            <td>{data.trainer_name}</td>
                            <td>{data.sch_poc_name}</td>
                            <td>{data.sch_poc_contact}</td>
                            <td>{data.sch_address}</td>
                            <td>96%</td>
                            <td>96%</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7" className="noDataContainer">
                          <div className="noData">No data available</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {Array.isArray(results) && results.length > 0 && (
                <div className="pagination row mt-5">
                  <div className="result_msg col-12 col-lg-3 col-sm-8 col-md-8">
                    <p
                      className="result_msg_text"
                      style={{ marginBottom: "0px" }}
                    >{`Displaying ${
                      numberofItems > 10 ? itemsPerPage : numberofItems
                    } results of ${totalItems} `}</p>
                  </div>

                  <div
                    className="button_module col-12 col-lg-9 col-sm-4 col-md-4 d-flex "
                    // style={{ marginTop: "8px" }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <p className="paginate_msg_text">Page</p>
                      <div
                      // style={{width:'100%'}}
                      >
                        <select
                          className="form-select pageDropdown"
                          value={currentPage}
                          onChange={(e) => handlePageChange(e.target.value)}
                          style={{width:'116%'}}
                        >
                          {Array.from(
                            { length: pageCount },
                            (_, i) => i + 1
                          ).map((pageNum) => (
                            <option value={pageNum} key={pageNum}>
                              {pageNum}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="paginate_msg_text">
                        {/* {` of ${pageCount}`} */}
                        of {pageCount}
                      </p>
                    </div>

                    <div className=" d-flex">
                      <button
                        type="button"
                        className="bton"
                        onClick={prevPage}
                        disabled={currentPage === 1}
                      >
                        <img src={nextImg} alt="prev" className="arrow_btn" />
                      </button>

                      <button
                        type="button"
                        className="bton"
                        onClick={nextPage}
                        disabled={indexOfLastItem >= sortedData.length}
                      >
                        <img src={prevImg} alt="next" className="arrow_btn" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SchoolListSuperAdmin;
