import React, { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { GET_CHARTS_API } from "./AllAPI";

const Charts = () => {
  const [chartData, setChartData] = useState([]);
  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_CHARTS_API, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const msg = data.message;
        if (msg === "Students do not have access to this report") {
          setChartData([
            { month: "Jan", average: 0 },
            { month: "Feb", average: 0 },
            { month: "Mar", average: 0 },
            { month: "Apr", average: 0 },
            { month: "May", average: 0 },
            { month: "Jun", average: 0 },
            { month: "Jul", average: 0 },
            { month: "Aug", average: 0 },
            { month: "Sep", average: 0 },
            { month: "Oct", average: 0 },
            { month: "Nov", average: 0 },
            { month: "Dec", average: 0 },
          ]);
        } else {
          setChartData(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <p className="chart_title mt-5">Student Attendance</p>

      <div className="container mt-5 ">
        <ResponsiveContainer aspect={2}>
          <BarChart data={chartData}>
            <XAxis dataKey="month" />
            <YAxis tickFormatter={(tick) => `${tick}%`} domain={[0, 100]} />
            {/* <Tooltip /> */}
            <Bar
              dataKey="average"
              fill="rgba(250, 95, 28, 1)"
              radius={[20, 20, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default Charts;
