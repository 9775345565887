import React, { useEffect, useState } from "react";
import phoneLogo from "../Assets/Images/Dashboard/phone_logo.png";
import locationLogo from "../Assets/Images/Dashboard/location_logo.png";
import emailLogo from "../Assets/Images/Dashboard/email_logo.png";
import { GET_SCHOOL_POC } from "../Components/AllAPI";

const SchoolPOC = (props) => {
  const [pocDetail, setPocDetail] = useState("");
  const [schMsg, setSchMsg] = useState(false);
  const sch_id = props.schoolsId;

  useEffect(() => {
    fetchData();
  }, [props.schoolsId]);

  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_SCHOOL_POC, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
        schoolId: `${sch_id}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("poc data", data);
        if (data.error === "School not found") {
          setSchMsg(data.error);
        }
        // console.log("poc msg", data.error);
        setPocDetail(data);
      });
  };

  return (
    <>
      <p className="recentlyVideoTitle mt-5">School POC</p>
      <div className="card poc_card mt-4">
        <p className="poc_name mb-0">
          {schMsg ? <span>-</span> : pocDetail.sch_poc_name}
        </p>
        <hr />
        <div className="contact_details">
          <div className="d-flex">
            <p className="phone_no">
              <img src={phoneLogo} alt="phoneLogo" className="me-2" />
              {schMsg ? <span>-</span> : pocDetail.sch_poc_contact}
            </p>
            <p className="address ms-4">
              <img src={locationLogo} alt="locationLogo" className="me-2" />
              {schMsg ? <span>-</span> : pocDetail.sch_address}
            </p>
          </div>
          <p className="poc_email d-flex">
            <img src={emailLogo} alt="emailLogo" className="me-2" />
            {schMsg ? <span>-</span> : pocDetail.sch_poc_email}
          </p>
        </div>
      </div>
    </>
  );
};

export default SchoolPOC;
