import React, { useEffect } from "react";
import Lottie from "lottie-react";
import trophyAnimation from "../Assets/Json/trophyAnimation.json";
import { Link, useLocation } from "react-router-dom";
import {useNavigate } from "react-router-dom";
const AssessmentCompletion = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const score = location.state.responseData;
  const chapterName = location.state.syllabus.chapter_name;
  // console.log("Data: ", location.state);
  useEffect(()=>
{
  window.history.replaceState(null,null, "/studentdashboard");
})
useEffect(() => {
  // Prevent going back to the previous page after logging out
  const clearHistory = () => {
    window.history.pushState(null, window.location.pathname);
    window.onpopstate = () => {
      navigate("/studentdashboard");
    };
  };
  clearHistory();
  return () => {
    window.onpopstate = null;
  };
}, [navigate]);


  return (
    <div className="container mt-5 pt-5">
      <div className="row">
        <div className="col">
          <div className="animation_div">
            <Lottie
              animationData={trophyAnimation}
              style={{ width: "200px", height: "200px" }}
            />
          </div>

          <div className="text_main_div">
            <div className="assessment_name_div">
              <p>Assessment - {chapterName}</p>
            </div>
            <div className="marks_div">
              <p>Marks Obtained</p>
              <div className="internal_marks_div">
                <p className="marks">
                  <span style={{ color: "#68953B" }}>
                    {score.total_correct_answers}/
                  </span>
                  {score.total_questions}
                </p>
              </div>
            </div>
          </div>

          <div className="button_div text-center m-auto">
            <Link to="/studentdashboard">
              <button
                type="button"
                className="next_button"
                style={{ borderRadius: "32px" }}
              >
                Go to Dashboard
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentCompletion;
