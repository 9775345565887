export const API_START_POINT = "https://phnelearntest.phntechnology.com/backend/api";

export const POST_FORGOT_PASS_API = `${API_START_POINT}/forget-password`;
export const POST_RESET_PASS_API = `${API_START_POINT}/reset-password`;
export const LOGIN_API = `${API_START_POINT}/login`;

//MANAGER API's
export const GET_MANAGER_CHART_API = `${API_START_POINT}/mgr-graph`;
export const GET_MGR_SUPER_ADMIN_LIST_API = `${API_START_POINT}/super-admins-list`;
export const GET_MGR_SUPER_ADMIN_SCH_LIST_API = `${API_START_POINT}/getSchools`;
export const POST_MGR_CREATE_SUPER_ADMIN_API = `${API_START_POINT}/create-super-admin`;
export const POST_MGR_ADD_SCHOOL_API = `${API_START_POINT}/create-school`;
export const GET_MGR_ADD_SCHOOL_ALLOCATE_TRAINER_API = `${API_START_POINT}/trainerAllocListCreate`;
export const POST_MGR_ADD_TRAINER_API = `${API_START_POINT}/create-trainer`;
export const GET_MGR_DASHBOARD_COUNT = `${API_START_POINT}/mgr-dash-count`;
export const GET_MGR_PROFILE_MANAGER = `${API_START_POINT}/get-mgr-info`;
export const GET_MGR_SCHOOLS_LIST = `${API_START_POINT}/schoolsList`;
export const GET_RECENTLY_ADDED_SCHOOLS = `${API_START_POINT}/recent-schools`;
export const GET_MGR_SINGLE_TRAINER_PROFILE = `${API_START_POINT}/trainerProfile`;
export const GET_MGR_TRAINERS_LIST = `${API_START_POINT}/trainersList`;

//SUPER ADMIN API's

export const GET_SUPER_ADMIN_SCHOOLS_API = `${API_START_POINT}/super-admin-schools`;
export const GET_SUPER_ADMIN_TRAINERS_API = `${API_START_POINT}/super-admin-trainers`;
export const GET_SUPER_ADMIN_CHART = `${API_START_POINT}/suAverageAttendance`;
export const GET_LEADER_BOARD = `${API_START_POINT}/superAdminDashLeaderBoard`;
export const GET_PROFILE_SUPER_ADMIN = `${API_START_POINT}/get-super-admin-info`;
export const GET_SUPER_ADMIN_DASHBOARD = `${API_START_POINT}/superAdminDashboard`;

//STUDENTS API

export const GET_PROJECT_LIST_API = `${API_START_POINT}/list-projects`;
export const GET_CHAPTER_LIST_API = `${API_START_POINT}/getChapters`;
export const GET_ALL_PROFILE_STUDENT = `${API_START_POINT}/get-student-info`;
export const GET_CALENDER_API = `${API_START_POINT}/studentAttendanceReportWeb`;
export const GET_CONTINUE_CHAPTERS = `${API_START_POINT}/continuechapters`;
export const GET_PROFILE_PRINCIPLE = `${API_START_POINT}/get-trainer-info`;
export const GET_PROFILE_STUDENT = `${API_START_POINT}/get-student-info`;
export const GET_SIGNLE_CHAPTER = `${API_START_POINT}/add-progress`;
export const GET_STUDENT_ASSESSMENT_GET_CHAPTERS = `${API_START_POINT}/getChapters`;
export const POST_STUDENT_ASSESSMENT_SUBMIT_ANSWERS = `${API_START_POINT}/submitAnswers`;
export const GET_STUDENT_COMPLETION = `${API_START_POINT}/get-student-progress`;
export const GET_STUDENT_PRINCIPAL_DATA = `${API_START_POINT}/get-studentlist-principal`;
export const GET_SYLLABUS = `${API_START_POINT}/getSyllabus`;
export const GET_PROJECTS = `${API_START_POINT}/getProjects`;
//COMPONENTS API

export const POST_ADD_STUDENT = `${API_START_POINT}/create-student`;
export const ALL_COURSE_LIST_GET_CHAPTERS_LIST = `${API_START_POINT}/getChaptersWeb`;
export const ALL_PARTICULAR_CHAPTER_ADD_PROGRESS = `${API_START_POINT}/add-progress`;
export const GET_ATTENDANCE_LIST = `${API_START_POINT}/attendance`;
export const POST_MARK_ATTENDANCE = `${API_START_POINT}/mark-attendance`;
export const GET_ATTEND_DATA_API = `${API_START_POINT}/get-studentlist-trainer`;
export const GET_CHARTS_API = `${API_START_POINT}/attendance-report`;
export const GET_PROGRESS_API = `${API_START_POINT}/getCourseCompletion`;
export const GET_STUDENT_ATTENDANCE_CHART = `${API_START_POINT}/studentAttendanceReport`;
export const STUD_LIST_STRUCTURE_STUDLIST_TRAINER = `${API_START_POINT}/get-studentlist-trainer`;
export const GET_STUDENT_PROGRESS = `${API_START_POINT}/get-student-progress`;
export const GET_TOTAL_STUDENT = `${API_START_POINT}/student-count`;

//PRINCIPAL API

export const GET_PRINCIPAL_PROGRESS = `${API_START_POINT}/getDashboardDataPS`;
export const GET_PRI_SCHOOL_PROFILE = `${API_START_POINT}/show-school-info`;

//TRAINER API

export const GET_SCHOOL_DROPDOWN = `${API_START_POINT}/getSchoolsForTrainer`;
export const GET_SCHOOL_POC = `${API_START_POINT}/schoolPocDetails`;
export const GET_TRAINER_CHART = `${API_START_POINT}/attendance-report`;
export const GET_TRAINER_DASHBOARD_COUNT = `${API_START_POINT}/getDashboardDataPS`;
export const GET_TRAINER_PROFILE = `${API_START_POINT}/trainerProfile`;
