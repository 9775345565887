import "../Assets/Styles/StudentList.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import Excelexport from "../Excelexport";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import DayDateProfile from "./DayDateProfile";
import prevArrow from "../Assets/Images/SuperAdmin/prev_arrow.png";
import nextArrow from "../Assets/Images/SuperAdmin/next_arrow.png";

const StudentListStructurePrinciple = (props) => {
  const studentListData = props.studentListData;

  const [studentData, setStudentData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    setStudentData(studentListData.all_students);
  }, [studentListData.all_students]);

  useEffect(() => {
    $(".studentList .nav-link").click(function () {
      $(".studentList .nav-link").removeClass("active");
      $(this).addClass("active");
    });

    $(".gradeCircle").click(function () {
      $(".gradeCircle").removeClass("active");
      $(this).addClass("active");
    });
  }, []);

  function convertToRoman(num) {
    const romanNumerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    let result = "";

    for (let key in romanNumerals) {
      while (num >= romanNumerals[key]) {
        result += key;
        num -= romanNumerals[key];
      }
    }

    return result;
  }
  useEffect(() => {
    if (studentData && studentData.length > 0) {
      const filtered = studentData.filter((data) => {
        const {
          stu_fname,
          stu_mname,
          stu_lname,
          stu_father_name,
          stu_email,
          stu_grade,
          stu_father_contact,
          average_attendance,
        } = data;
        const searchInputLowerCase = searchInput.toLowerCase();
        return (
          searchInputLowerCase === "" ||
          (stu_fname &&
            stu_fname.toLowerCase().includes(searchInputLowerCase)) ||
          (stu_mname &&
            stu_mname.toLowerCase().includes(searchInputLowerCase)) ||
          (stu_lname &&
            stu_lname.toLowerCase().includes(searchInputLowerCase)) ||
          (stu_grade && stu_grade.toString().includes(searchInputLowerCase)) ||
          (stu_father_contact &&
            stu_father_contact.toString().includes(searchInputLowerCase)) ||
          (stu_email && stu_email.toString().includes(searchInputLowerCase)) ||
          (stu_father_name &&
            stu_father_name.toLowerCase().includes(searchInputLowerCase)) ||
          (average_attendance &&
            average_attendance.toString().includes(searchInputLowerCase))
        );
      });

      setResults(filtered);
    } else {
      setResults([]);
    }
  }, [studentData, searchInput]);

  useEffect(() => {
    setCurrentPage(1);
  }, [studentData]);

  const totalItems = results ? results.length : 0;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = results
    ? results.slice(indexOfFirstItem, indexOfLastItem)
    : [];
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const PagePerItem = Math.min(totalItems - indexOfFirstItem, indexOfLastItem);
  const lastItemNumber = Math.min(indexOfLastItem, totalItems);

  // Function to handle changing pages
  const handlePageChange = (pageNumber) => {
    setCurrentPage(parseInt(pageNumber));
  };

  // Function to go to the previous page
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Function to go to the next page
  const nextPage = () => {
    if (indexOfLastItem < totalItems) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  return (
    <>
      <section className="studentList sidebody">
        <div className="container">
          {/* <DayDateProfile /> */}

          <div className="tableNavbar">
            <div className="row mt-5">
              <div className="col-lg-9 col-sm-12 col-md-12 col-12">
                <p className="studentListTitle">Students List</p>
              </div>
              <div className="col-lg-3 col-sm-12 col-md-12 col-12">
                <div className="input-group mb-3" style={{ float: "right" }}>
                  <span className="input-group-text" id="basic-addon1">
                    <span className="fa fa-search"></span>
                  </span>
                  <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="grades col-lg-9">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mr-auto">
                      <li
                        className="nav-item me-3"
                        onClick={() =>
                          setStudentData(studentListData.all_students)
                        }
                      >
                        <Link className="nav-link active" to="#">
                          All
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudentData(studentListData.grade_1)}
                      >
                        <Link className="nav-link" to="#">
                          I
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudentData(studentListData.grade_2)}
                      >
                        <Link className="nav-link" to="#">
                          II
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudentData(studentListData.grade_3)}
                      >
                        <Link className="nav-link" to="#">
                          III
                        </Link>
                      </li>
                      <li className="nav-item me-3">
                        <Link
                          className="nav-link"
                          to="#"
                          onClick={() =>
                            setStudentData(studentListData.grade_4)
                          }
                        >
                          IV
                        </Link>
                      </li>
                      <li className="nav-item me-3">
                        <Link
                          className="nav-link"
                          to="#"
                          onClick={() =>
                            setStudentData(studentListData.grade_5)
                          }
                        >
                          V
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudentData(studentListData.grade_6)}
                      >
                        <Link className="nav-link" to="#">
                          VI
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudentData(studentListData.grade_7)}
                      >
                        <Link className="nav-link" to="#">
                          VII
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudentData(studentListData.grade_8)}
                      >
                        <Link className="nav-link" to="#">
                          VIII
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudentData(studentListData.grade_9)}
                      >
                        <Link className="nav-link" to="#">
                          IX
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudentData(studentListData.grade_10)}
                      >
                        <Link className="nav-link" to="#">
                          X
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudentData(studentListData.grade_11)}
                      >
                        <Link className="nav-link" to="#">
                          XI
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudentData(studentListData.grade_12)}
                      >
                        <Link className="nav-link" to="#">
                          XII
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="ms-3" style={{ float: "right" }}>
                <Excelexport
                  excelData={studentData}
                  fileName={"Student List"}
                />
              </div>
            </div>
            <div>
              <table className="table table-borderless">
                <thead>
                  <tr className="table_heading">
                    <th scope="col">Sr No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Grade</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Email id</th>
                    <th scope="col">Parents name</th>
                    <th scope="col">Attendance</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(results) && results.length > 0 ? (
                    currentItems.map((data, ind) => {
                      return (
                        <tr key={ind}>
                          <td className="stud_id">
                            {indexOfFirstItem + ind + 1}
                          </td>
                          <td className="stud_name">
                            <Link
                              to="/studentAttendance"
                              style={{
                                color: "black",
                                textDecoration: "none",
                              }}
                              state={data}
                            >
                              {data.stu_fname}
                              {data.stu_mname}
                              {data.stu_lname}
                            </Link>
                          </td>
                          <td>{convertToRoman(data.stu_grade)}</td>
                          <td>{data.stu_father_contact}</td>
                          <td>{data.stu_email}</td>
                          <td>{data.stu_father_name}</td>
                          <td>{data.average_attendance}%</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="7" className="noDataContainer">
                        <div className="noData">No data available</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {Array.isArray(results) && results.length > 0 && (
              <div className="pagination row">
                <div className="result_msg col-12 col-lg-3 col-sm-7 col-md-7">
                  <p
                    className="result_msg_text"
                    style={{ marginBottom: "0px" }}
                  >
                    Displaying {indexOfFirstItem + 1} - {lastItemNumber} results of {totalItems}
                  </p>
                </div>

                <div className="button_module col-12 col-lg-9 col-sm-5 col-md-5 d-flex ">
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <p className="paginate_msg_text">Page</p>
                    <div>
                      <select
                        className="form-select pageDropdown"
                        value={currentPage}
                        onChange={(e) => handlePageChange(e.target.value)}
                        style={{ width: "116%" }}
                      >
                        {Array.from(
                          { length: Math.ceil(totalItems / itemsPerPage) },
                          (_, i) => i + 1
                        ).map((pageNum) => (
                          <option value={pageNum} key={pageNum}>
                            {pageNum}&nbsp;
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="paginate_msg_text d-flex gap-2">
                      <span>of</span> {Math.ceil(totalItems / itemsPerPage)}
                    </p>
                  </div>

                  <div className=" d-flex">
                    <button
                      type="button"
                      className="bton"
                      onClick={prevPage}
                      disabled={currentPage === 1}
                    >
                      <img src={nextArrow} alt="next" className="arrow_btn" />
                    </button>

                    <button
                      type="button"
                      className="bton"
                      onClick={nextPage}
                      disabled={indexOfLastItem >= totalItems}
                    >
                      <img src={prevArrow} alt="prev" className="arrow_btn" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default StudentListStructurePrinciple;
