import React, { useEffect, useState } from "react";
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import $ from "jquery";
import LottieAnimation from "../Components/LottieAnimation";
import { GET_CALENDER_API } from "../Components/AllAPI";
const Calendar = () => {
  const [array, setArray] = useState([]);
  const [datafromapi, setDataFromApi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();

  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_CALENDER_API, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDataFromApi(data);
        console.log("data calender", data);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  function getClassNameAttribute() {
    let getMonthValue = $(".fc-toolbar-title").text();
    const getMonth = getMonthValue.split(" ");
    const getMonthName = getMonth[0];
    // for (let index = 0; index < datafromapi.length; index++) {
    //   const element = datafromapi[index].month;
    // }
    for (let i = 0; i < datafromapi.length; i++) {
      if (datafromapi[i].month === getMonthName) {
        setArray(datafromapi[i].attendance);
      }
    }
  }

  useEffect(() => {
    window.addEventListener("click", getClassNameAttribute);
    let monthname = monthNames[d.getMonth()];
    for (let i = 0; i < datafromapi.length; i++) {
      if (datafromapi[i].month === monthname) {
        setArray(datafromapi[i].attendance);
      }
    }
  });
  console.log("arr data", array);
  let present = 0;
  let absent = 0;

  array.forEach((elem) => {
    // console.log("elem", elem);
    if (elem.title === "present") {
      present++;
    } else if (elem.title === "absent") {
      absent++;
    }
  });
  // console.log("arr", array);
  let studentSidebar = sidebarData.Student;
  return (
    <>
      <Sidebar sidebarLinks={studentSidebar} />

      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          <div className="sidebody">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12">
                  <p className="chart_title" style={{ fontSize: "32px" }}>
                    Attendance
                  </p>
                </div>
                <div className="col-lg-4 col-12">
                  <div style={{textAlign:'right'}}>
                    <span className="presentCount">Present : {present} </span>
                    <span className="absentCount mx-3">Absent : {absent} </span>
                  </div>
                </div>
              </div>

              <Fullcalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                events={array}
                initialView="dayGridMonth"
                headerToolbar={{
                  start: "title",
                  end: "prev,next",
                }}
                height={"100vh"}
                eventContent={(events) => {
                  const { event } = events;
                  const backgroundColor =
                    event.title === "present"
                      ? "rgb(61, 145, 68)"
                      : "rgb(233, 107, 107)";
                  return (
                    <div
                      style={{
                        backgroundColor,
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {event.title === "present" ? "Present" : "Absent"}
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Calendar;
