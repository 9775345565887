import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import DayDateProfile from "../Components/DayDateProfile";
import sidebarData from "../Assets/Json/sidebarJson.json";
import LottieAnimation from "../Components/LottieAnimation";
import LeaderBoard from "./LeaderBoard";
import ChartSuperAdmin from "./ChartSuperAdmin";
import ProfileSuperAdmin from "./ProfileSuperAdmin";
import "../Assets/Styles/SuperAdminRole.css";
import { GET_SUPER_ADMIN_DASHBOARD } from "../Components/AllAPI";
import { useNavigate } from "react-router-dom";

function SuperAdminDashboard() {
  const superAdminSidebar = sidebarData.SuperAdmin;
  const [dashboardData, setDashboardData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(GET_SUPER_ADMIN_DASHBOARD, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log("data: ", json);
        setDashboardData(json);
        setIsLoading(false);
      });
  }, []);

  // console.log(dashboardData);

  useEffect(() => {
    // Prevent going back to the previous page after logging out
    const clearHistory = () => {
      window.history.pushState(null, window.location.pathname);
      window.onpopstate = () => {
        navigate("/super_admindashboard");
      };
    };
    clearHistory();
    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);

  return (
    <>
      <Sidebar sidebarLinks={superAdminSidebar}></Sidebar>

      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          {" "}
          <section className="sidebody superAdmin">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-12">
                  <div className="row">
                    <div className="col-lg-8 col-md-12 col-12">
                      {/* <DayDateProfile /> */}
                    </div>
                    <div className="col-lg-4 col-4">
                      {/* <div className="exportDropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={exportIcon}
                            alt="exportIcon"
                            className="me-2"
                          />{" "}
                          Export
                        </button>
                        <ul
                          className="dropdown-menu p-2"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              Schools List{" "}
                              <img
                                src={schoolExportIcon}
                                alt="schoolExportIcon"
                                className="ms-3"
                              />
                            </a>
                          </li>
                          <li className="border-top">
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleExport}
                            >
                              Trainers List{" "}
                              <img
                                src={trainerExportIcon}
                                alt="trainerExportIcon"
                                className="ms-3"
                              />
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="row mt-5  w-100">
                      <div className="col-lg-3 col-3">
                        <section className="totalStudent">
                          <div
                            className="card total_students"
                            style={{
                              backgroundColor: "#F2F6EF",
                              height: "6.5rem",
                            }}
                          >
                            <p className="totalNo mb-0">
                              {dashboardData?.schools}
                            </p>
                            <p
                              className="totalTitle"
                              style={{ fontWeight: "bolder" }}
                            >
                              Schools
                            </p>
                          </div>
                        </section>
                      </div>
                      <div className="col-lg-3 col-3">
                        <div
                          className="card avg_completion"
                          style={{
                            backgroundColor: "#EDF3FC",
                            height: "6.5rem",
                          }}
                        >
                          <p className="totalNo mb-0">
                            {dashboardData.trainers === null || ""
                              ? 0
                              : dashboardData.trainers}
                          </p>
                          <p
                            className="totalTitle"
                            style={{ fontWeight: "bolder" }}
                          >
                            Trainers
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-3">
                        <div
                          className="card avg_completion"
                          style={{
                            backgroundColor: "#FFF4F0",
                            height: "6.5rem",
                          }}
                        >
                          <p className="totalNo mb-0">
                            {dashboardData?.students}
                          </p>
                          <p
                            className="totalTitle"
                            style={{ fontWeight: "bolder" }}
                          >
                            Students
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-3">
                        <div
                          className="card avgCourseCompletion"
                          style={{
                            backgroundColor: "#FFF4F0",
                            height: "6.5rem",
                          }}
                        >
                          <p className="totalNo mb-0">
                            {dashboardData?.average_progress}%
                          </p>
                          <p
                            className="totalTitle"
                            style={{ fontWeight: "bolder" }}
                          >
                            Avg. Course Completion
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ChartSuperAdmin />
                  <br />

                  <div>
                    <LeaderBoard />
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-12 col-12 mt-5 pt-5 ps-4"
                  style={{ borderLeft: "2px solid #F3F5F7" }}
                >
                  <ProfileSuperAdmin />
                  <div className="mb-5"></div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default SuperAdminDashboard;
