import React, { useEffect, useState } from "react";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import DayDateProfile from "../Components/DayDateProfile";
import editIcon from "../Assets/Images/ManagerDashboard/edit-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { GET_MGR_SUPER_ADMIN_LIST_API } from "../Components/AllAPI";
import LottieAnimation from "../Components/LottieAnimation";
import Excelexport from "../Excelexport";
import plusIcon from "../Assets/Images/StudentList/plusIcon.svg";

const ManagerSuperAdminDetails = () => {
  const [searchInput, setSearchInput] = useState("");
  const [adminList, setAdminList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);

  const navigate = useNavigate();

  let managerSidebar = sidebarData.Manager;

  useEffect(() => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(GET_MGR_SUPER_ADMIN_LIST_API, {
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log("admin list", json);
        setAdminList(json.super_admins);
        setIsLoading(false);
      });
  }, []);
  // console.log("adminss", adminList);
  useEffect(() => {
    if (adminList && adminList.length > 0) {
      const filtered = adminList.filter((data) => {
        return (
          searchInput.toLowerCase() === "" ||
          (data.su_adm_name &&
            data.su_adm_name.toLowerCase().includes(searchInput)) ||
          (data.school_name &&
            data.school_name.toLowerCase().includes(searchInput))
        );
      });

      setResults(filtered);
    } else {
      setResults([]);
    }
  }, [adminList, searchInput]);
  return (
    <>
      <Sidebar sidebarLinks={managerSidebar} />

      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          {" "}
          <div className="sidebody schoolList">
            <div className="container">
              {/* <DayDateProfile /> */}
              <div className="pt-4">
                <div className="row">
                  <div className="col-lg-5 col-sm-12 col-md-12 col-12">
                    <p className="studentListTitle">Group of School - Admin</p>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-md-12 col-12">
                    <div className="d-flex" style={{ float: "right" }}>
                      <Excelexport
                        excelData={adminList}
                        fileName={"super admin List"}
                      />
                      <button
                        type="button"
                        className="btn addStudentBtn"
                        onClick={() => navigate("/manager/addsuperadmin")}
                      >
                        Add Admin
                        <img
                          src={plusIcon}
                          alt="plusIcon"
                          className="plusIcon ms-2"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-md-12 col-12">
                    <div
                      className="input-group mb-3"
                      style={{ float: "right" }}
                    >
                      <span className="input-group-text" id="basic-addon1">
                        <span className="fa fa-search"></span>
                      </span>
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <table className="table table-borderless studentTable text-center">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No.</th>
                      <th scope="col">Super Admin</th>
                      <th scope="col">Schools</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(results) && results.length > 0 ? (
                      results.map((data, index) => {
                        let schools = data.school_name.split(",");
                        // console.log("schoolsname", schools);
                        return (
                          <tr>
                            <td key={index}>{index + 1}</td>
                            <td>{data.su_adm_name}</td>
                            <td>
                              <div className="more-schools">
                                {schools[0] === "Unallocated" ||
                                schools.length - 1 === 0 ? (
                                  schools[0]
                                ) : (
                                  <>
                                    {schools[0]}
                                    <p className="more-school-text">
                                      + {schools.length - 1} more
                                    </p>
                                    <div className="hideMoreSchools">
                                      <p>
                                        {schools.map((sch_list, ind) => {
                                          return ind !== 0 ? (
                                            <ul
                                              className="schoolBullets"
                                              style={{ textAlign: "left" }}
                                            >
                                              <li key={ind}>{sch_list}</li>
                                            </ul>
                                          ) : (
                                            ""
                                          );
                                        })}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                            {/* <td>{data.su_adm_schools}</td> */}
                            <td>
                              <img
                                src={editIcon}
                                alt="EditIcon"
                                onClick={() =>
                                  navigate("/manager/editsuperadmin", {
                                    state: { data },
                                  })
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="8" className="noDataContainer">
                          <div className="noData">No data available</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ManagerSuperAdminDetails;
