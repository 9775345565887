
import StudentListStructure from "../Components/StudentListStructure";
import React, { useState } from "react";
const StudentsListData = (props) => {
  const [studentData, setStudentData] = useState([]);
  // console.log("from app in data ", props.schoolsId);

  const studSchId =  props.schoolsId;

  return (
    <>
      <StudentListStructure
        studentListData={studentData}
        studSchId={studSchId}
      />
    </>
  );
};

export default StudentsListData;


