import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import { Link, useLocation } from "react-router-dom";
import greenTick from "../Assets/Images/Dashboard/tick.png";
import DayDateProfile from "../Components/DayDateProfile";
import nextArrow from "../Assets/Images/Dashboard/next_icon.png";
import ChapterVideoList from "./ChapterVideoList";
import ReactPlayer from "react-player";
import markAsCompletedImg from "../Assets/Images/Dashboard/markAsCompleted.svg";
import tickIcon from "../Assets/Images/Dashboard/tick_icon_circled.svg";
import { GET_SIGNLE_CHAPTER } from "../Components/AllAPI";
function SingleChapter() {
  const location = useLocation();
  const videoData = location.state.videoData;
  // console.log("videoData", videoData);
  let syllabus = location.state.syllabus;
  // console.log("syll Data", syllabus);
  const [videoCompleted, setVideoCompleted] = useState(false);
  const [showTextCompleted, setShowTextCompleted] = useState(false);
  //   const videoData = location.state.videoData;

  const handleSubmit = async () => {
    var token = localStorage.getItem("authToken");
    try {
      let res = await fetch(
        GET_SIGNLE_CHAPTER,
        {
          method: "POST",
          headers: {
            authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            video_id: videoData.vid_id,
          }),
        }
      );
      let resJson = await res.json();
      // console.log(resJson);
      const msg = resJson.message;
      if (msg === "Progress added successfully") {
        // console.log("completed video");
        // setVideoCompleted(true);
      }
      setVideoCompleted(true);
      // console.log(msg);
    } catch (err) {
      console.log(err);
    }
  };

  const handleVideoEnded = () => {
    // console.log("Before setting videoCompleted:", videoCompleted);
    handleSubmit();
    // setVideoCompleted(true);
    // console.log("After setting videoCompleted:", videoCompleted);
  };
  useEffect(() => {
    if (videoCompleted) {
      // console.log("yes completed successfully");
      // handleSubmit();
    }
  }, [videoCompleted]);

  const sendTextData = () => {
    handleSubmit();
    setShowTextCompleted(true);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  };

  const handleVideoCompletion = () => {
    setVideoCompleted(false);
    setShowTextCompleted(false);
  };

  return (
    <>
      <Sidebar sidebarLinks={sidebarData.Student} />
      <div className="sidebody">
        <div className="container">
          {/* <DayDateProfile /> */}

          {/* video details */}
          <div className="ChapterDetails mt-4 mb-5">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/student/courseslist" className="prevPageTitle">
                    Courses
                  </Link>
                </li>
                <img src={nextArrow} alt="nextArrow" />
                <li className="breadcrumb-item">
                  <Link
                    className="prevPageTitle"
                    to="/student/courseparticularchapter"
                    state={{ courses: syllabus || {} }}
                  >
                    {`Chapter ${syllabus?.chapter_no || ""}`}
                  </Link>
                </li>
                <img src={nextArrow} alt="nextArrow" />

                <p
                  className="currentPageTitle mt-0 mb-0"
                  style={{ color: "#211C37" }}
                >
                  Topic
                </p>
              </ol>
            </nav>
            <p className="Header-Video">{syllabus.chapter_name}</p>

            <div className="row mt-5">
              <div className="col-lg-7 col-md-7">
                <div className="Image-Div">
                  {videoData.vid_path === null || videoData.vid_path === "" ? (
                    <img
                      className="Video-Image mb-4"
                      src={videoData.vid_thumbnail}
                      alt="Video-Preview"
                      width="96%"
                    />
                  ) : (
                    <ReactPlayer
                      url={videoData.vid_path}
                      playing
                      width="90%"
                      // height="30rem"
                      controls={true}
                      onEnded={handleVideoEnded}
                    />
                  )}
                </div>{" "}
                <div className="Header-video-div mb-0 pb-0">
                  <div className="Topic-div row">
                    <div className="col-lg-8 col-12">
                      <p className="videoTitle">{videoData.vid_title}</p>
                    </div>
                    <div className="col-lg-4 col-12">
                      {showTextCompleted === true || videoData.vid_progress === 1 ?(
                        <p className="completedText mt-4 ms-3 ps-5">
                          <img
                            src={tickIcon}
                            alt="complted text"
                            className="me-2"
                          />
                          Completed
                        </p>
                      ):""}
                    </div>
                  </div>
                  {/* <div className="chapter-div">
                    <p
                      style={{ marginBottom: "0px",marginTop:'0px',border:'2px solid black' }}
                      className="videoSubTitle"
                    >
                      {videoData.chapterSubTitle}
                    </p>
                  </div> */}
                </div>
                <div className="Description-Div border-top pt-3 mt-0">
                  <div className="Description-Header-Text">
                    <p>Description</p>
                  </div>
                  <div className="Description-Text">
                    <p className="desc">{videoData.vid_description}</p>
                  </div>
                </div>
                {videoData.vid_path === null || videoData.vid_path === "" ? (
                  showTextCompleted === true || videoData.vid_progress === 1 ? (
                    ""
                  ) : (
                    <div className="markAsComplted mt-5">
                      <button
                        className="btn markAsCompletedBtn"
                        onClick={sendTextData}
                      >
                        <img
                          src={markAsCompletedImg}
                          alt="Mark as Completed"
                          className="me-2"
                        />{" "}
                        Mark as Completed
                      </button>
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
              <div
                className="col-lg-5 col-md-5 second-column ps-4"
                style={{ borderLeft: "1px solid #E7E6EA" }}
              >
                <div className="Topics-Covered-Div">
                  <p className="Header-Topics">Assessment</p>
                </div>
                <ChapterVideoList
                  syllabusData={syllabus}
                  videoCompleted={videoCompleted}
                  video_id={videoData.vid_id}
                  onAllVideosCompleted={handleVideoCompletion}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleChapter;
