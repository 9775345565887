import React, { useEffect, useState } from "react";
import "../Assets/Styles/StudentList.css";
import { Link, useLocation } from "react-router-dom";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Excelexport from "../Excelexport";
import Sidebar from "./Sidebar";
import $ from "jquery";
import LottieAnimation from "./LottieAnimation";
import Checkbox from "./ChecboxTrail";
import Lottie from "react-lottie";
import loader from "../Assets/Images/Dashboard/loader_lottie.json";
import { GET_ATTENDANCE_LIST, POST_MARK_ATTENDANCE } from "./AllAPI";
import { ToastContainer, toast } from "react-toastify";

const AttendanceList = (props) => {
  const sch_id = props.schoolsId;
  const [student, setStudent] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isActive, setActive] = useState("I");
  const [tableLoading, setTableLoading] = useState(false);
  const [results, setResults] = useState([]);

  var token = localStorage.getItem("authToken");

  const [checkedItems, setCheckedItems] = useState("present");

  const handleCheckboxChange = (itemId) => {
    setCheckedItems({
      ...checkedItems,
      [itemId]: checkedItems[itemId] === "present" ? "absent" : "present",
    });
  };

  function convertToRoman(num) {
    const romanNumerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    let result = "";

    for (let key in romanNumerals) {
      while (num >= romanNumerals[key]) {
        result += key;
        num -= romanNumerals[key];
      }
    }

    return result;
  }

  let trainerSidebar = sidebarData.Trainer;
  async function fetchData() {
    try {
      const response = await fetch(GET_ATTENDANCE_LIST, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
          schoolId: `${sch_id}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      setStudentData(json.schools[0].students);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setLoading(true);
    $(".studentList .nav-link").click(function () {
      $(".studentList .nav-link").removeClass("active");
      $(this).addClass("active");
    });
    setLoading(true);
    var token = localStorage.getItem("authToken");

    fetchData();
  }, [props.schoolsId]);

  const submitAttendance = async () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const day = String(today.getDate()).padStart(2, "0");
    const date = `${year}-${month}-${day}`;

    const attendanceArray = Object.keys(checkedItems).map((key) => ({
      stu_user_id: key,
      attn_status: checkedItems[key],
    }));

    const jsonData = {
      attn_date: date,
      attendance: attendanceArray,
    };

    // console.log("JSON Data:", jsonData);

    const response = await fetch(POST_MARK_ATTENDANCE, {
      method: "post",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonData),
    });

    const json = await response.json();
    if (json.message === "Attendance marked successfully") {
      toast.success("Attendance updated successfully", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        onClose: () => {
          fetchData();
          setTableLoading(false);
        },
      });
    }
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    if (isActive === "I") {
      setStudent(studentData.grade_1);
    } else if (isActive === "II") {
      setStudent(studentData.grade_2);
    } else if (isActive === "III") {
      setStudent(studentData.grade_3);
    } else if (isActive === "IV") {
      setStudent(studentData.grade_4);
    } else if (isActive === "V") {
      setStudent(studentData.grade_5);
    } else if (isActive === "VI") {
      setStudent(studentData.grade_6);
    } else if (isActive === "VII") {
      setStudent(studentData.grade_7);
    } else if (isActive === "VIII") {
      setStudent(studentData.grade_8);
    } else if (isActive === "IX") {
      setStudent(studentData.grade_9);
    } else if (isActive === "X") {
      setStudent(studentData.grade_10);
    } else if (isActive === "XI") {
      setStudent(studentData.grade_11);
    } else if (isActive === "XII") {
      setStudent(studentData.grade_12);
    }
  }, [studentData, isActive]);

  useEffect(() => {
    if (isActive === "I" && studentData.grade_1) {
      const initialCheckedState = {};
      studentData.grade_1.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    } else if (isActive === "II" && studentData.grade_2) {
      const initialCheckedState = {};
      studentData.grade_2.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    } else if (isActive === "III" && studentData.grade_3) {
      const initialCheckedState = {};
      studentData.grade_3.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    } else if (isActive === "IV" && studentData.grade_4) {
      const initialCheckedState = {};
      studentData.grade_4.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    } else if (isActive === "V" && studentData.grade_5) {
      const initialCheckedState = {};
      studentData.grade_5.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    } else if (isActive === "VI" && studentData.grade_6) {
      const initialCheckedState = {};
      studentData.grade_6.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    } else if (isActive === "VII" && studentData.grade_7) {
      const initialCheckedState = {};
      studentData.grade_7.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    } else if (isActive === "VIII" && studentData.grade_8) {
      const initialCheckedState = {};
      studentData.grade_8.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    } else if (isActive === "IX" && studentData.grade_9) {
      const initialCheckedState = {};
      studentData.grade_9.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    } else if (isActive === "X" && studentData.grade_10) {
      const initialCheckedState = {};
      studentData.grade_10.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    } else if (isActive === "XI" && studentData.grade_11) {
      const initialCheckedState = {};
      studentData.grade_11.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    } else if (isActive === "XII" && studentData.grade_12) {
      const initialCheckedState = {};
      studentData.grade_12.forEach((item) => {
        initialCheckedState[item.stu_user_id] =
          item.stu_attn_status ?? "present";
      });
      setCheckedItems(initialCheckedState);
    }
  }, [studentData, isActive]);

  useEffect(() => {
    if (student && student.length > 0) {
      const filtered = student.filter((data) => {
        const lowercaseSearchInput = searchInput.toLowerCase();
        return (
          lowercaseSearchInput === "" ||
          data.stu_id.toString().includes(lowercaseSearchInput) ||
          (data.stu_fname &&
            data.stu_fname.toLowerCase().includes(lowercaseSearchInput)) ||
          (data.stu_mname &&
            data.stu_mname.toLowerCase().includes(lowercaseSearchInput)) ||
          (data.stu_lname &&
            data.stu_lname.toLowerCase().includes(lowercaseSearchInput)) ||
          (data.stu_grade &&
            data.stu_grade.toString().includes(lowercaseSearchInput)) ||
          (data.stu_father_contact &&
            data.stu_father_contact
              .toString()
              .includes(lowercaseSearchInput)) ||
          (data.stu_email &&
            data.stu_email.toString().includes(lowercaseSearchInput))
        );
      });
      setResults(filtered);
    } else {
      setResults([]);
    }
  }, [student, searchInput]);

  return (
    <>
      <Sidebar sidebarLinks={trainerSidebar} />

      {loading ? (
        <div className="loading-screen text-center m-auto">
          <LottieAnimation />
        </div>
      ) : (
        <>
          <section className="studentList sidebody">
            <div className="container">
              <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
              <div className="tableNavbar mt-5">
                <div className="row">
                  <div className="col-lg-9 col-sm-12 col-md-12 col-12">
                    <p className="studentListTitle">Attendance</p>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="basic-addon1">
                        <span className="fa fa-search"></span>
                      </span>
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="grades col-lg-9 col-9">
                    <nav className="navbar navbar-expand-lg navbar-light">
                      <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav mr-auto">
                          <li
                            className={`${
                              isActive === "I"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("I")}
                            onClick={() => setStudent(studentData.grade_1)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "I" ? "white" : "",
                              }}
                            >
                              I
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "II"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("II")}
                            onClick={() => setStudent(studentData.grade_2)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "II" ? "white" : "",
                              }}
                            >
                              II
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "III"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("III")}
                            onClick={() => setStudent(studentData.grade_3)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "III" ? "white" : "",
                              }}
                            >
                              III
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "IV"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("IV")}
                            onClick={() => setStudent(studentData.grade_4)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "IV" ? "white" : "",
                              }}
                            >
                              IV
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "V"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("V")}
                            onClick={() => setStudent(studentData.grade_5)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "V" ? "white" : "",
                              }}
                            >
                              V
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "VI"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("VI")}
                            onClick={() => setStudent(studentData.grade_6)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "VI" ? "white" : "",
                              }}
                            >
                              VI
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "VII"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("VII")}
                            onClick={() => setStudent(studentData.grade_7)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "VII" ? "white" : "",
                              }}
                            >
                              VII
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "VIII"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("VIII")}
                            onClick={() => setStudent(studentData.grade_8)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "VIII" ? "white" : "",
                              }}
                            >
                              VIII
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "IX"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("IX")}
                            onClick={() => setStudent(studentData.grade_9)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "IX" ? "white" : "",
                              }}
                            >
                              IX
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "X"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("X")}
                            onClick={() => setStudent(studentData.grade_10)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "X" ? "white" : "",
                              }}
                            >
                              X
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "XI"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("XI")}
                            onClick={() => setStudent(studentData.grade_11)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "XI" ? "white" : "",
                              }}
                            >
                              XI
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "XII"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("XII")}
                            onClick={() => setStudent(studentData.grade_12)}
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "XII" ? "white" : "",
                              }}
                            >
                              XII
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                  <div className="col-lg-3 col-3">
                    <Excelexport
                      excelData={student}
                      fileName={"Attendance Data"}
                    />
                  </div>
                </div>

                <div>
                  <table
                    className="table table-borderless studentTable"
                    id="studentTable"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Sr No.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Grade</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Email id</th>
                        <th scope="col">Present/Absent</th>
                      </tr>
                    </thead>
                    {studentData && Object.keys(studentData).length > 0 ? (
                      tableLoading ? (
                        <div className="noDataContainer">
                          <Lottie
                            options={defaultOptions}
                            height={100}
                            width={100}
                          />
                        </div>
                      ) : (
                        <>
                          <tbody>
                            {Array.isArray(results) && results.length > 0 ? (
                              results.length > 0 ? (
                                results.map((data, ind) => {
                                  // console.log(data.stu_user_id, data.stu_attn_status);
                                  return (
                                    <tr key={ind}>
                                      <td>{ind + 1}</td>
                                      <td>
                                        {data.stu_fname}
                                        {data.stu_mname}
                                        {data.stu_lname}
                                      </td>
                                      <td>{convertToRoman(data.stu_grade)}</td>
                                      <td>{data.stu_father_contact}</td>
                                      <td>{data.stu_email}</td>
                                      <td key={ind}>
                                        <div className="text-Left ms-5">
                                          <Checkbox
                                            isChecked={
                                              checkedItems[data.stu_user_id] ===
                                              "present"
                                            }
                                            onChange={() =>
                                              handleCheckboxChange(
                                                data.stu_user_id
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td></td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="7" className="noDataContainer">
                                    <div className="noData">
                                      No data available
                                    </div>
                                  </td>
                                </tr>
                              )
                            ) : (
                              <tr>
                                <td colSpan="7" className="noDataContainer">
                                  <div className="noData">
                                    No data available
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                          {student &&
                            student.length > 0 &&
                            results.length > 0 && (
                              <button
                                type="button"
                                className="btn submit_btn attendance_submit_btn mt-5 w-75"
                                onClick={() => {
                                  setTableLoading(true);
                                  submitAttendance(student[0].stu_sch_id);
                                }}
                              >
                                Submit Attendance
                              </button>
                            )}
                        </>
                      )
                    ) : (
                      <tr>
                        <td colSpan="7" className="noDataContainer">
                          <div className="noData">No data available</div>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AttendanceList;
