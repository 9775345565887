import React, { useEffect, useState } from "react";
import StudentListStructurePrinciple from "../Components/StudentListStructurePrinciple";
import LottieAnimation from "../Components/LottieAnimation";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import { GET_STUDENT_PRINCIPAL_DATA } from "../Components/AllAPI";

const StudentsPrincipleListData = () => {
  const [studentData, setStudentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let trainerSidebar = sidebarData.Principle;

  const fetchData = () => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");

    fetch(GET_STUDENT_PRINCIPAL_DATA, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStudentData(data.students);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Sidebar sidebarLinks={trainerSidebar} />

      {isLoading ? (
        <LottieAnimation />
      ) : (
        <StudentListStructurePrinciple studentListData={studentData} />
      )}
    </>
  );
};

export default StudentsPrincipleListData;
