import React, { useEffect, useRef, useState } from "react";
import DayDateProfile from "../Components/DayDateProfile";
import Sidebar from "../Components/Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import { useLocation, useNavigate } from "react-router-dom";
import uploadIcon from "../Assets/Images/ManagerDashboard/upload-icon.svg";
import deleteIcon from "../Assets/Images/ManagerDashboard/delete-icon.svg";
import "../Assets/Styles/EditStudentProfile.css";
import LottieAnimation from "../Components/LottieAnimation";
import { API_START_POINT } from "../Components/AllAPI";
import SimpleLoader from "../Components/SimpleLoader";
import { ToastContainer, toast } from "react-toastify";

const EditStudentProfile = () => {
  let studentSidebar = sidebarData.Student;
  const location = useLocation();
  const preData = location.state.profile;
  const navigate = useNavigate();
  const [editedData, setEditedData] = useState(preData);
  const [errors, setErrors] = useState({});
  const [imgFetched, setImgFetched] = useState(editedData.stu_image);
  const [selectLogo, setSelectLogo] = useState(null);
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEditedData((values) => ({
      ...values,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(timeout);
  // }, []);

  const validateForm = (values) => {
    const error = {};
    const userNameRegx = /^[A-Za-z]+$/;
    const mobileRegx = /^[6789][0-9]{9}$/;
    const fatherNameRegx = /^[A-Za-z]+(?: [A-Za-z]+)*$/;

    if (!values.stu_fname || values.stu_fname === "NA") {
      error.stu_fname = "Please enter first name.";
    } else if (!userNameRegx.test(values.stu_fname)) {
      error.stu_fname = "Please enter valid first name.";
    }

    if (!values.stu_mname || values.stu_mname === "NA") {
      error.stu_mname = "Please enter middle name.";
    } else if (!userNameRegx.test(values.stu_mname)) {
      error.stu_mname = "Please enter valid middle name.";
    }

    if (!values.stu_lname || values.stu_lname === "NA") {
      error.stu_lname = "Please enter last name.";
    } else if (!userNameRegx.test(values.stu_lname)) {
      error.stu_lname = "Please enter valid last name.";
    }

    if (!values.stu_dob || values.stu_dob === "NA") {
      error.stu_dob = "Please enter date of birth.";
    }

    if (!values.stu_blood_grp || values.stu_blood_grp === "NA") {
      error.stu_blood_grp = "Please select blood group.";
    }

    if (!values.stu_father_name || values.stu_father_name === "NA") {
      error.stu_father_name = "Please enter father name.";
    } else if (!fatherNameRegx.test(values.stu_father_name)) {
      error.stu_father_name = "Please enter valid father name.";
    }

    if (!values.stu_mother_name || values.stu_mother_name === "NA") {
      error.stu_mother_name = "Please enter mother name.";
    } else if (!userNameRegx.test(values.stu_mother_name)) {
      error.stu_mother_name = "Please enter valid mother name.";
    }

    if (!values.stu_mother_contact || values.stu_mother_contact === "NA") {
      error.stu_mother_contact = "Please enter mobile no.";
    } else if (!mobileRegx.test(values.stu_mother_contact)) {
      error.stu_mother_contact = "Please enter valid mobile no.";
    }

    if (!values.stu_address || values.stu_address === "NA") {
      error.stu_address = "Please enter address.";
    }

    return error;
  };

  const submitData = async (e) => {
    e.preventDefault();

    const formErrors = validateForm(editedData);
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      setIsLoading(true);
      var token = localStorage.getItem("authToken");
      try {
        const formData = new FormData();
        if (selectLogo) {
          formData.append("stu_image", selectLogo);
        } else if (imgFetched === null) {
          formData.append("stu_image", null);
        } else {
          console.log("else condition");
        }
        const jsonData = {
          stu_fname: editedData.stu_fname,
          stu_mname: editedData.stu_mname,
          stu_lname: editedData.stu_lname,
          stu_dob: editedData.stu_dob,
          stu_blood_grp: editedData.stu_blood_grp,
          stu_father_name: editedData.stu_father_name,
          stu_mother_name: editedData.stu_mother_name,
          stu_mother_contact: editedData.stu_mother_contact,
          stu_address: editedData.stu_address,
        };

        for (const key in jsonData) {
          formData.append(key, jsonData[key]);
        }

        let res = await fetch(
          `${API_START_POINT}/student/update/${editedData.stu_id}`,
          {
            method: "POST",
            headers: {
              authorization: "Bearer " + token,
              Accept: "application/json",
            },
            body: formData,
          }
        );
        let resJson = await res.json();
        // console.log(resJson);
        const msg = resJson.message;
        if (msg === "Student profile updated successfully") {
          toast.success("Student profile updated successfully", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => {
              setIsLoading(false);
              navigate("/studentdashboard");
              setTimeout(window.location.reload());
            },
          });
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    }
  };

  const uploadHandler = (e) => {
    setSelectLogo(e.target.files[0]);
    // console.log(e.target.files[0]);
  };
  const imgClickHandler = () => {
    inputRef.current.click();
  };
  const deleteHandler = () => {
    setImgFetched(null);
    setSelectLogo(null); // Add this line to reset selectLogo

    inputRef.current.value = null;
  };

  return (
    <>
      <Sidebar sidebarLinks={studentSidebar} />
      {isLoading && (
        <div className="simple-loader">
          <SimpleLoader />
        </div>
      )}
      <section className="EditStudentProfile sidebody">
        <div className="container">
          {/* <DayDateProfile /> */}
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <form>
            <div className="row mt-5 w-50">
              <p className="profile_title">Edit Profile</p>
              <div className="file-upload mt-4">
                {imgFetched === null || imgFetched === "" ? (
                  !selectLogo ? (
                    <>
                      <div onClick={imgClickHandler}>
                        <img src={uploadIcon} alt="upload-icon" height={80} />
                        <button
                          type="button"
                          className="btn mt-3"
                          accept="image/png, image/jpeg"
                          style={{ float: "right" }}
                          // onClick={imgClickHandler}
                        >
                          Upload
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src={URL.createObjectURL(selectLogo)}
                        alt="upload-icon"
                        height={100}
                        width={100}
                        className=""
                        id="imgSelected"
                        style={{ borderRadius: "50px" }}
                      />
                      {/* <p></p> */}
                      <button
                        type="button"
                        className="btn mt-4"
                        accept="image/png, image/jpeg"
                        style={{ float: "right" }}
                        onClick={deleteHandler}
                      >
                        <img src={deleteIcon} alt="deleteIcon" />
                      </button>
                    </>
                  )
                ) : (
                  <>
                    <img
                      src={imgFetched}
                      alt="upload-icon"
                      height={100}
                      width={100}
                      className=""
                      id="imgSelected"
                      style={{ borderRadius: "50px" }}
                    />
                    <button
                      type="button"
                      className="btn mt-4"
                      accept="image/png, image/jpeg"
                      style={{ float: "right" }}
                      onClick={deleteHandler}
                    >
                      <img src={deleteIcon} alt="deleteIcon" />
                    </button>
                  </>
                )}
                <input
                  type="file"
                  onChange={uploadHandler}
                  accept="image/png, image/jpeg"
                  ref={inputRef}
                  style={{ display: "none" }}
                />
              </div>
              <hr className="mt-3" />
              <div className="col-lg-6 col-6 mt-3">
                <label>First Name</label>
                <input
                  type="text"
                  name="stu_fname"
                  placeholder="First name"
                  value={editedData.stu_fname}
                  onChange={handleChange}
                  className="form-control"
                />
                <span className="error_msg">{errors.stu_fname}</span>
              </div>
              <div className="col-lg-6 col-6  mt-3">
                <label>Middle Name</label>

                <input
                  type="text"
                  placeholder="Middle name"
                  name="stu_mname"
                  value={editedData.stu_mname}
                  onChange={handleChange}
                  className="form-control"
                />
                <span className="error_msg">{errors.stu_mname}</span>
              </div>{" "}
              <div className="col-lg-6 col-6">
                <label>Last Name</label>
                <input
                  type="text"
                  placeholder="Last name"
                  name="stu_lname"
                  onChange={handleChange}
                  value={editedData.stu_lname}
                  className="form-control"
                />
                <span className="error_msg">{errors.stu_lname}</span>
              </div>
              <div className="col-lg-6 col-6">
                <label>Email Id</label>
                <input
                  type="text"
                  placeholder="Email Id"
                  name="stu_email"
                  value={editedData.stu_email}
                  onChange={handleChange}
                  className="form-control"
                  readOnly
                />
                <span className="error_msg">{errors.stu_email}</span>
              </div>
              <div className="col-lg-6 col-6">
                <label>DOB</label>
                <input
                  type="date"
                  name="stu_dob"
                  onChange={handleChange}
                  value={editedData.stu_dob}
                  className="form-control"
                  id="date"
                  pattern="\d{2}\/\d{2}\/\d{4}"
                />
                <span className="error_msg">{errors.stu_dob}</span>
              </div>
              <div className="col-lg-6 col-6">
                <label>Blood Group</label>
                <select
                  name="stu_blood_grp"
                  id="workExpDropdown"
                  className="bloodGroupSelect"
                  onChange={handleChange}
                >
                  <option value={editedData.stu_blood_grp}>
                    {editedData.stu_blood_grp}
                  </option>
                  <option value="">Blood Group</option>
                  <option value="A+">A+</option>
                  <option value="B+">B+</option>
                  <option value="AB+">AB+</option>
                  <option value="O+">O+</option>
                  <option value="A-">A-</option>
                  <option value="B-">B-</option>
                  <option value="O-">O-</option>
                  <option value="AB-">AB-</option>
                </select>
                <span className="error_msg">{errors.stu_blood_grp}</span>
              </div>
              <p className="profile_title mt-4">Parents Details</p>
              <div className="col-lg-6 col-6">
                <label>Father Name</label>
                <input
                  type="text"
                  placeholder="Fathers name"
                  name="stu_father_name"
                  onChange={handleChange}
                  value={editedData.stu_father_name}
                  className="form-control "
                />
                <span className="error_msg">{errors.stu_father_name}</span>
              </div>
              <div className="col-lg-6 col-6">
                <label>Father Contact</label>
                <input
                  type="text"
                  placeholder="Fathers mobile"
                  name="stu_father_contact"
                  onChange={handleChange}
                  value={editedData.stu_father_contact}
                  className="form-control "
                  readOnly
                />
                <span className="error_msg">{errors.stu_father_contact}</span>
              </div>
              <div className="col-lg-6 col-6">
                <label>Mother Name</label>
                <input
                  type="text"
                  placeholder="Mothers name"
                  name="stu_mother_name"
                  onChange={handleChange}
                  value={editedData.stu_mother_name}
                  className="form-control "
                />

                <span className="error_msg">{errors.stu_mother_name}</span>
              </div>
              <div className="col-lg-6 col-6">
                <label>Mother Contact</label>
                <input
                  type="text"
                  placeholder="Mothers mobile"
                  name="stu_mother_contact"
                  onChange={handleChange}
                  value={editedData.stu_mother_contact}
                  className="form-control "
                  maxLength={10}
                  minLength={10}
                />
                <span className="error_msg">{errors.stu_mother_contact}</span>
              </div>
              <div className="col-lg-6 col-6">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="Address"
                  name="stu_address"
                  onChange={handleChange}
                  value={editedData.stu_address}
                  className="form-control "
                />
                <span className="error_msg">{errors.stu_address}</span>
              </div>
              <div className="text-center my-5">
                <input
                  type="submit"
                  className="update_btn"
                  value="Update"
                  onClick={submitData}
                  id="submit_btn"
                />
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default EditStudentProfile;
