import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import LottieAnimation from "../Components/LottieAnimation";
import { API_START_POINT } from "../Components/AllAPI";
import { useLocation } from "react-router-dom";
import profileImg from "../Assets/Images/StudentList/student_profile.svg";
import "../Assets/Styles/Calendar.css";

const StudentsAttendanceInCalendar = () => {
  const [array, setArray] = useState([]);
  const [datafromapi, setDataFromApi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [attendanceSummary, setAttendanceSummary] = useState({
    present: 0,
    absent: 0,
    total: 0,
  });

  const location = useLocation();
  const studentData = location.state;
  const stu_id = studentData.stu_user_id;

  const api = `${API_START_POINT}/studentAttendanceReportWeb/${stu_id}`;

  const fetchData = () => {
    const token = localStorage.getItem("authToken");

    fetch(api, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDataFromApi(data);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const handleMonthChange = (info) => {
    const start = info.start;
    const end = info.end;
    const allDatesInMonth = [];
    const today = new Date(); // Get today's date

    // Generating an array of all dates in the displayed month
    let currentDate = new Date(start);
    while (currentDate <= end) {
      allDatesInMonth.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Retrieving attendance data for the new month
    const newMonthName = info.view.title.split(" ")[0];
    const newData = datafromapi.find((item) => item.month === newMonthName);
    const attendanceArray = newData ? newData.attendance : [];

    // Mapping each date in the month to an event
    const events = allDatesInMonth.map((date) => {
      // Check if the date is before today or not
      const isPastDate = date < today;
      // Find the attendance record for the current date
      const attendanceEvent = attendanceArray.find(
        (event) => new Date(event.date).toDateString() === date.toDateString()
      );
      // If it's a past date, use the attendance data if available, otherwise set it as "NA"
      // If it's a future date, set it as "NA"
      return {
        date: date.toISOString().split("T")[0],
        title: isPastDate
          ? attendanceEvent
            ? attendanceEvent.title
            : "NA"
          : "NA",
      };
    });
    const presentCount = events.filter(
      (event) => event.title === "present"
    ).length;
    const absentCount = events.filter(
      (event) => event.title === "absent"
    ).length;
    const totalCount = new Date(
      start.getFullYear(),
      start.getMonth() + 1,
      0
    ).getDate();

    // Setting the events array to state
    setArray(events);
    setAttendanceSummary({
      present: presentCount,
      absent: absentCount,
      total: totalCount,
    });
  };

  //   const handleMonthChange = (info) => {
  //     console.log(info);
  //     const start = info.start;
  //     const end = info.end;
  //     const allDatesInMonth = [];

  //     let currentDate = new Date(start);
  //     while (currentDate <= end) {
  //       allDatesInMonth.push(new Date(currentDate));
  //       currentDate.setDate(currentDate.getDate() + 1);
  //     }

  //     console.log(allDatesInMonth);

  //     const newMonthName = info.view.title.split(" ")[0];
  //     const newData = datafromapi.find((item) => item.month === newMonthName);
  //     const attendanceArray = newData ? newData.attendance : [];

  //     // Mapping each date in the month to an event, adding "NA" for days without attendance data
  //     const attendanceDates = attendanceArray.map(event => new Date(event.date));
  //     const events = allDatesInMonth.map(date => {
  //         const attendanceEvent = attendanceArray.find(event => new Date(event.date).toDateString() === date.toDateString());
  //         return attendanceEvent ? attendanceEvent : { date: date.toISOString().split('T')[0], title: 'NA' };
  //     });

  //     // Setting the events array to state
  //     setArray(events);
  //     // if (newData) {
  //     //   setArray(newData.attendance);
  //     // } else {
  //     //   setArray([]);
  //     // }
  //   };

  const formatDOB = (dob) => {
    return dob ? dob.split("-").reverse().join("-") : "-";
  };

  const formatPhnEnrollDate = (phnEnrollDate) => {
    return phnEnrollDate ? phnEnrollDate.split("-").reverse().join("-") : "-";
  };

  const convertToRoman = (num) => {
    const romanNumerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    let result = "";

    for (let key in romanNumerals) {
      while (num >= romanNumerals[key]) {
        result += key;
        num -= romanNumerals[key];
      }
    }

    return result;
  };

  const convertListToRoman = (input) => {
    if (!input || typeof input !== "string") return "-";

    const numbers = input.split(",").map(Number);
    return numbers.map(convertToRoman).join(",");
  };

  const romanNumeralsString = convertListToRoman(studentData.stu_grade);

  const userRole = localStorage.getItem("user_role");
  let sidebarLinks;
  if (userRole === "Trainer") {
    sidebarLinks = sidebarData.Trainer;
  } else if (userRole === "Principal") {
    sidebarLinks = sidebarData.Principle;
  }

  return (
    <>
      <Sidebar sidebarLinks={sidebarLinks} />

      {isLoading ? (
        <LottieAnimation />
      ) : (
        <div className="sidebody">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <div className="row">
                  <div className="col-lg-8 col-12">
                    <p className="chart_title" style={{ fontSize: "32px" }}>
                      Attendance
                    </p>
                  </div>
                  <div className="col-lg-4 col-12"></div>
                </div>

                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin]}
                  events={array}
                  initialView="dayGridMonth"
                  // showNonCurrentDates={false}
                  headerToolbar={{
                    start: "title",
                    end: "prev,next",
                  }}
                  height={"100vh"}
                  eventContent={(events) => {
                    const { event } = events;
                    const today = new Date();
                    const eventDate = new Date(event.start);
                    if (eventDate > today) {
                      return (
                        <div
                          style={{
                            backgroundColor: "#cbc7c7",
                            color: "black",
                            display: "block",
                            textAlign: "center",
                            paddingBottom: "14px",
                            paddingTop: "12px",
                          }}
                        >
                          N/A
                        </div>
                      );
                    } else {
                      const backgroundColor =
                        event.title === "present"
                          ? "rgb(215 239 217)"
                          : "rgb(243 199 199)";
                      const color =
                        event.title === "present"
                          ? "#56c333"
                          : "rgb(241 12 12)";
                      const content =
                        event.title === "present" ? "Present-P" : "Absent-A";
                      return (
                        <div
                          style={{
                            backgroundColor,
                            color,
                            display: "block",
                            textAlign: "center",
                            paddingBottom: "14px",
                            paddingTop: "12px",
                          }}
                        >
                          {content}
                        </div>
                      );
                    }
                  }}
                  datesSet={handleMonthChange}
                />
                <div>
                  {/* <div className="month-summary">
                    <div className="">
                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th>Total Days</th> <th>Present Days</th>{" "}
                            <th>Absent Days</th>{" "}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{attendanceSummary.total}</td>
                            <td>{attendanceSummary.present}</td>
                            <td>{attendanceSummary.absent}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> */}
                </div>
              </div>
              <div
                className="col-3 ms-4"
                style={{ borderLeft: "2px solid #F3F5F7" }}
              >
                <section className="profile">
                  <div className="container">
                    <div className="row">
                      <div className="col-7">
                        <p className="profile_title">Profile</p>
                      </div>
                    </div>
                    <div className="w-25 h-25" style={{ borderRadius: "50px" }}>
                      {studentData.stu_image === null ||
                      studentData.stu_image === "" ? (
                        <img
                          src={profileImg}
                          alt="profile_image"
                          width={100}
                          height={100}
                          style={{ borderRadius: "50px" }}
                          onError={(e) => (e.target.src = profileImg)}
                        />
                      ) : (
                        <img
                          src={studentData.stu_image}
                          alt="profile_image"
                          width={100}
                          height={100}
                          style={{ borderRadius: "50px" }}
                          onError={(e) => (e.target.src = profileImg)}
                        />
                      )}
                    </div>
                    <p className="student_name">
                      {studentData.stu_fname || "-"}{" "}
                      {studentData.stu_mname || "-"}{" "}
                      {studentData.stu_lname || "-"}
                    </p>
                    <p className="grade">
                      Grade:{" "}
                      <span style={{ fontWeight: "600", color: "black" }}>
                        {romanNumeralsString || "-"}
                      </span>
                    </p>

                    <div className="stud_data">
                      <div className="row">
                        <div className="col-6">
                          <p className="data_title mb-0">DOB</p>
                          <p className="data_subititle">
                            {formatDOB(studentData.stu_dob)}
                          </p>

                          <p className="data_title mb-0">PHN Enrolled Date</p>
                          <p className="data_subititle">
                            {formatPhnEnrollDate(
                              studentData.stu_phn_enroll_date
                            )}
                          </p>
                        </div>
                        <div className="col-6">
                          <p className="data_title mb-0">Blood Group</p>
                          <p className="data_subititle">
                            {studentData.stu_blood_grp || "-"}
                          </p>
                          <p className="stud_addres_title mb-0">Address</p>
                          <p className="stud_address_subtitle">
                            {studentData.stu_address || "-"}
                          </p>
                        </div>
                        <div className="col-6">
                          <p className="stud_addres_title mb-0">
                            Father Contact
                          </p>
                          <p className="stud_address_subtitle">
                            {studentData.stu_father_contact || "-"}
                          </p>

                          <p className="data_title mb-0">Mother Name</p>
                          <p className="data_subititle">
                            {studentData.stu_mother_name || "-"}
                          </p>
                        </div>

                        <div className="col-6">
                          <p className="stud_addres_title mb-0">Father Name</p>
                          <p className="stud_address_subtitle">
                            {studentData.stu_father_name || "-"}
                          </p>
                          <p className="data_title mb-0">Email</p>
                          <p className="data_subititle">
                            {studentData.stu_email || "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentsAttendanceInCalendar;
