import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Components/Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import "../Assets/Styles/AddSchool.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DeleteSchool from "./DeleteSchool";
import DayDateProfile from "../Components/DayDateProfile";
import uploadIcon from "../Assets/Images/ManagerDashboard/upload-icon.svg";
import deleteIcon from "../Assets/Images/ManagerDashboard/delete-icon.svg";
import { API_START_POINT } from "../Components/AllAPI";
import SimpleLoader from "../Components/SimpleLoader";
import { ToastContainer, toast } from "react-toastify";

const EditSchool = () => {
  const location = useLocation();
  const preData = location.state.data;
  let managerSidebar = sidebarData.Manager;
  const [isLoading, setIsLoading] = useState(false);
  const [trainerData, setTrainerData] = useState([]);
  const [editedData, setEditedData] = useState(preData);
  const [pocChecked, setPocChecked] = useState(false);
  const [trainerId, setTrainerID] = useState(editedData.sch_tra_id);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(true);
  const [secondArray, setSecondArray] = useState([]);
  const [imgFetched, setImgFetched] = useState(preData.sch_logo);
  const [selectLogo, setSelectLogo] = useState(null);
  const [checkboxCheckedPOC, setCheckboxCheckedPOC] = useState();
  const inputRef = useRef(null);
  // console.log("edited data", editedData);
  useEffect(() => {
    if (
      preData.sch_princi_contact === preData.sch_poc_contact &&
      preData.sch_princi_email === preData.sch_poc_email &&
      preData.sch_princi_name === preData.sch_poc_name
    ) {
      const checkbox = document.getElementById("pocCheckedDefault");
      if (checkbox) {
        checkbox.checked = true;
        setCheckboxCheckedPOC(true);
      }
      // setPocChecked(true);
    }
  }, []);
  // console.log('aksdfk,j',checkboxCheckedPOC);
  //part for the principle details
  const [principalDetails, setPrincipalDetails] = useState({
    sch_princi_name: "",
    sch_princi_contact: "",
    sch_princi_email: "",
  });
  const navigate = useNavigate();
  const [schoolPOC, setSchoolPOC] = useState({
    sch_poc_contact: "",
    sch_poc_email: "",
    sch_poc_name: "",
  });

  // const str = editedData.sch_phn_enrolled_grades;
  // let arr = str.split(",");
  const str = editedData?.sch_phn_enrolled_grades;
  let arr;

  if (str && str.trim() !== "") {
    arr = str.split(",");
  } else {
    arr = [];
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "sch_princi_name") {
      setPrincipalDetails((prevDetails) => ({
        ...prevDetails,
        sch_princi_name: value,
      }));
    }
    if (e.target.name === "sch_princi_email") {
      setPrincipalDetails((prevDetails) => ({
        ...prevDetails,
        sch_princi_email: value,
      }));
    }
    if (e.target.name === "sch_princi_contact") {
      setPrincipalDetails((prevDetails) => ({
        ...prevDetails,
        sch_princi_contact: value,
      }));
    }
    setEditedData((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const handlePOCCheckboxChange = (e) => {
    const { checked } = e.target;

    if (checked) {
      setPocChecked(true);
      setShowError(false);
      setPrincipalDetails({
        sch_poc_contact: preData.sch_princi_contact,
        sch_poc_email: preData.sch_princi_email,
        sch_poc_name: preData.sch_princi_name,
      });
    } else {
      setPocChecked(false);
      setShowError(true);
      setCheckboxCheckedPOC(false);
      setSchoolPOC({
        sch_poc_contact: editedData.sch_poc_contact,
        sch_poc_email: editedData.sch_poc_email,
        sch_poc_name: editedData.sch_poc_name,
      });
    }
  };

  const validateDetails = (values) => {
    const error = {};
    const userNameRegx = /^[A-Z a-z . , & ']+$/;
    const mobileRegx = /^[6789][0123456789]{9}$/;
    const intakeRegx = /^[0-9]*$/;
    const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z][a-z.A-Z]+$/;

    // if (Object.keys(secondArray).length - 1 === 0) {
    //   error.grade = "Please select grade";
    // }
    if (Object.keys(secondArray).length === 0) {
      error.grade = "Please select grade";
    }
    if (values.sch_name === "") {
      error.sch_name = "Please enter school name.";
    } else if (userNameRegx.test(values.sch_name) === false) {
      error.sch_name = "Please enter valid school name.";
    }
    if (values.sch_poc_name === "") {
      error.sch_poc_name = "Please enter poc name.";
    } else if (userNameRegx.test(values.sch_poc_name) === false) {
      error.sch_poc_name = "Please enter valid poc name.";
    }

    if (values.sch_poc_contact === "") {
      error.sch_poc_contact = "Please enter poc mobile no.";
    } else if (mobileRegx.test(values.sch_poc_contact) === false) {
      error.sch_poc_contact = "Please enter valid mobile no.";
    }

    if (values.sch_poc_email === "") {
      error.sch_poc_email = "Please enter poc email id.";
    } else if (emailRegx.test(values.sch_poc_email) === false) {
      error.sch_poc_email = "Please enter valid email id.";
    }
    if (values.sch_address === "") {
      error.sch_address = "Please enter address.";
    }

    if (values.trainer_name === "") {
      error.trainer_name = "Please select allocated trainer.";
    }

    if (values.sch_phn_intake === "") {
      error.sch_phn_intake = "Please enter PHN intake.";
    } else if (intakeRegx.test(values.sch_phn_intake) === false) {
      error.sch_phn_intake = "Please enter valid PHN intake.";
    }
    if (values.sch_total_intake === "") {
      error.sch_total_intake = "Please enter total school intake.";
    } else if (intakeRegx.test(values.sch_total_intake) === false) {
      error.sch_total_intake = "Please enter valid total school intake.";
    }
    return error;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const toStr = secondArray.toString();

    const formErrors = validateDetails(editedData);
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      setIsLoading(true);
      var token = localStorage.getItem("authToken");
      try {
        const formData = new FormData();

        if (selectLogo) {
          formData.append("sch_logo", selectLogo);
        } else if (imgFetched === null) {
          formData.append("sch_logo", null);
        } else {
          console.log("else condition");
        }

        const jsonData = {
          sch_name: editedData.sch_name,
          sch_address: editedData.sch_address,
          sch_total_intake: editedData.sch_total_intake,
          sch_phn_intake: editedData.sch_phn_intake,
          sch_phn_enrolled_grades: toStr,
          sch_poc_name: pocChecked
            ? principalDetails.sch_poc_name
            : editedData.sch_poc_name,
          sch_poc_contact: pocChecked
            ? principalDetails.sch_poc_contact
            : editedData.sch_poc_contact,
          sch_poc_email: pocChecked
            ? principalDetails.sch_poc_email
            : editedData.sch_poc_email,
          sch_tra_id: trainerId,
        };

        for (const key in jsonData) {
          formData.append(key, jsonData[key]);
        }

        let res = await fetch(
          `${API_START_POINT}/school/update/${editedData.sch_id}`,
          {
            method: "POST",
            headers: {
              authorization: "Bearer " + token,
              Accept: "application/json",
            },
            body: formData,
          }
        );
        let resJson = await res.json();
        // console.log(resJson);
        const msg = resJson.message;
        const status_code = resJson.status_code;
        if (msg === "School updated successfully") {
          toast.success("School updated successfully", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => {
              setIsLoading(false);
              navigate("/manager/schoollist");
              // setTimeout(window.location.reload());
            },
          });
        } else if (status_code === 422) {
          alert("Principal with this email id already exists with us");

          toast.error("Principal with this email id already exists with us", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setIsLoading(false);

          // setTimeout(window.location.reload());
        }
      } catch (err) {
        setIsLoading(false);

        console.log(err);
      }
    }
  };

  const gradeList = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
  ];
  const yesBtnHandler = () => {
    navigate("/manager/schoollist");
    window.location.reload();
  };
  useEffect(() => {
    setSecondArray(arr);
    var token = localStorage.getItem("authToken");
    // getChecked();
    fetch(
      `${API_START_POINT}/school/trainer/${editedData.sch_id}`,

      {
        method: "GET",
        headers: {
          authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("trainers data", data.trainers);
        setTrainerData(data.trainers);
      });
    // setEditedData(preData);
  }, []);

  const selectGrade = (ind) => {
    if (ind.checked) {
      secondArray.push(ind.value);
      // console.log("after check secondArray", secondArray);
    } else {
      const index = secondArray.indexOf(ind.value);
      if (index !== -1) {
        secondArray.splice(index, 1);
      }
    }
  };

  const getChecked = (ind) => {
    secondArray.forEach((element) => {
      if (element === ind.toString()) {
        const checkbox = document.getElementById(`myCheck${element}`);
        if (checkbox) {
          checkbox.checked = true;
        }
      }
    });
  };
  const uploadHandler = (e) => {
    setSelectLogo(e.target.files[0]);
  };
  const imgClickHandler = () => {
    inputRef.current.click();
  };
  const deleteHandler = () => {
    setImgFetched(null);
    setSelectLogo(null);
    inputRef.current.value = null;
  };

  return (
    <>
      <Sidebar sidebarLinks={managerSidebar} />
      {isLoading && (
        <div className="simple-loader" style={{ zIndex: "1000" }}>
          <SimpleLoader />
        </div>
      )}
      <section className="AddSchool editeSchool sidebody">
        <div className="container w-75">
          {/* <DayDateProfile /> */}
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <div className="row  mt-5">
            <div className="col-md-6">
              <h3 className="headers">Edit School Details</h3>
              <label className="small-headers mb-2">School Name</label>
              <div className="input-error">
                <div className="input-group inputs">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="School Name*"
                    name="sch_name"
                    aria-label="School Name"
                    onChange={handleChange}
                    value={editedData.sch_name}
                  />
                </div>
                <span className="error_msg">{errors.sch_name}</span>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">Board</label>
                <div className="custom-dropdown inputs">
                  <select
                    className="custom-select"
                    name="sch_board"
                    onChange={handleChange}
                    style={{
                      borderRadius: "16px",
                      border: "1px solid var(--text-20-stroke, #E7E6EA)",
                    }}
                  >
                    <option value={editedData.sch_board}>
                      {editedData.sch_board}
                    </option>
                  </select>
                </div>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">
                  Total School Intake
                </label>
                <div className="input-group inputs">
                  <input
                    type="text"
                    className="form-control"
                    name="sch_total_intake"
                    placeholder="Total School Intake*"
                    onChange={handleChange}
                    value={editedData.sch_total_intake}
                    maxLength={6}
                  />
                </div>
                <span className="error_msg">{errors.sch_total_intake}</span>
              </div>
              <div className="input-error">
                <div className="inputs">
                  <label
                    htmlFor="formFileMultiple"
                    className="form-label small-headers"
                  >
                    School Logo
                  </label>
                  <br />
                  <div className="file-upload">
                    {imgFetched === null || imgFetched === "" ? (
                      !selectLogo ? (
                        <>
                          <div onClick={imgClickHandler}>
                            <img
                              src={uploadIcon}
                              alt="upload-icon"
                              height={80}
                            />
                            <button
                              type="button"
                              className="btn mt-3"
                              accept="image/png, image/jpeg"
                              style={{ float: "right" }}
                              // onClick={imgClickHandler}
                            >
                              Upload
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            src={URL.createObjectURL(selectLogo)}
                            alt="upload-icon"
                            height={80}
                            className=""
                            id="imgSelected"
                          />
                          <button
                            type="button"
                            className="btn"
                            accept="image/png, image/jpeg"
                            style={{ float: "right" }}
                            onClick={deleteHandler}
                          >
                            <img src={deleteIcon} alt="deleteIcon" />
                          </button>
                        </>
                      )
                    ) : (
                      <>
                        <img
                          src={imgFetched}
                          alt="upload-icon"
                          height={80}
                          className=""
                          id="imgSelected"
                        />
                        <button
                          type="button"
                          className="btn"
                          accept="image/png, image/jpeg"
                          style={{ float: "right" }}
                          onClick={deleteHandler}
                        >
                          <img src={deleteIcon} alt="deleteIcon" />
                        </button>
                      </>
                    )}
                    <input
                      type="file"
                      onChange={uploadHandler}
                      accept="image/png, image/jpeg"
                      ref={inputRef}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">State</label>

                <div className="custom-dropdown inputs">
                  <select
                    className="custom-select"
                    name="sch_state"
                    onChange={handleChange}
                    style={{
                      borderRadius: "16px",
                      border: "1px solid var(--text-20-stroke, #E7E6EA)",
                    }}
                  >
                    <option value={editedData.sch_state}>
                      {editedData.sch_state}
                    </option>
                  </select>
                </div>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">District</label>
                <div className="inputs">
                  <select
                    className="custom-select"
                    name="sch_district"
                    onChange={handleChange}
                    style={{
                      borderRadius: "16px",
                      border: "1px solid var(--text-20-stroke, #E7E6EA)",
                    }}
                  >
                    <option value={editedData.sch_district}>
                      {editedData.sch_district}
                    </option>
                  </select>
                </div>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">Address</label>
                <div className="inputs">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address*"
                    name="sch_address"
                    aria-label="Address"
                    onChange={handleChange}
                    value={editedData.sch_address}
                  />
                </div>
                <span className="error_msg">{errors.sch_address}</span>
              </div>
            </div>
            <div className="col-md-6">
              <h3 className="headers contacts-gap"> Edit Contacts</h3>
              <p className="small-headers">Principal Details</p>
              <div className="input-error">
                <label className="small-headers mb-2">Principal Name</label>

                <div className="inputs">
                  <input
                    type="tel"
                    className="form-control"
                    name="sch_princi_name"
                    onChange={handleChange}
                    placeholder="Principal Name* "
                    value={editedData.sch_princi_name}
                    readOnly
                  />
                </div>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">Principal Mobile</label>

                <div className="inputs">
                  <input
                    type="tel"
                    className="form-control"
                    name="sch_princi_contact"
                    onChange={handleChange}
                    placeholder="Principal Mobile* "
                    value={editedData.sch_princi_contact}
                    readOnly
                  />
                </div>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">Principal Email Id</label>

                <div className="inputs">
                  <input
                    type="email"
                    className="form-control"
                    name="sch_princi_email"
                    onChange={handleChange}
                    placeholder="Principal Email Id* "
                    value={editedData.sch_princi_email}
                    readOnly
                  />
                </div>
              </div>
              <div className="boundary-1"></div>
              <p className="small-headers">
                <b></b>School POC
              </p>
              <div className="form-check input-poc ps-0 inputs">
                <div className="POC-inner-div">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="poc-check"
                    onChange={handlePOCCheckboxChange}
                    id="pocCheckedDefault"
                  />
                  <label
                    className="form-check-label ms-3"
                    htmlFor="pocCheckedDefault"
                  >
                    POC same as Principal Details
                  </label>
                </div>
              </div>
              <div className="input-error mt-3">
                <label className="small-headers mb-2">POC Name</label>

                <div className="inputs">
                  <input
                    type="text"
                    className="form-control"
                    name="sch_poc_name"
                    id="poc_name"
                    value={
                      pocChecked
                        ? principalDetails.sch_poc_name
                        : editedData.sch_poc_name
                    }
                    onChange={handleChange}
                    placeholder="POC Name*"
                    readOnly={pocChecked || checkboxCheckedPOC}
                  />
                </div>
                {showError ? (
                  <span className="error_msg">{errors.sch_poc_name}</span>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">POC Mobile</label>

                <div className="inputs">
                  <input
                    type="text"
                    className="form-control"
                    name="sch_poc_contact"
                    id="poc_mono"
                    onChange={handleChange}
                    placeholder="POC Mobile*"
                    minLength={10}
                    maxLength={10}
                    value={
                      pocChecked
                        ? principalDetails.sch_poc_contact
                        : editedData.sch_poc_contact
                    }
                    readOnly={pocChecked || checkboxCheckedPOC}
                  />
                </div>
                {showError ? (
                  <span className="error_msg">{errors.sch_poc_contact}</span>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">POC Email Id</label>
                <div className="inputs">
                  <input
                    type="text"
                    className="form-control"
                    id="poc_email"
                    name="sch_poc_email"
                    onChange={handleChange}
                    placeholder="POC Email Id*"
                    value={
                      pocChecked
                        ? principalDetails.sch_poc_email
                        : editedData.sch_poc_email
                    }
                    readOnly={pocChecked || checkboxCheckedPOC}
                  />
                </div>
                {showError ? (
                  <span className="error_msg" id="hidePocEmail">
                    {errors.sch_poc_email}
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="div-for-grade-tabs"></div>
            </div>
            <div className="boundary-2"></div>
            <p className="headers Enrollment-block">Edit Enrollment</p>
            <div className="col-md-6 enrolment-gap">
              <div className="input-error">
                <label className="small-headers mb-2">PHN Intake</label>
                <div className="inputs">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleDate"
                    placeholder="PHN Intake*"
                    name="sch_phn_intake"
                    onChange={handleChange}
                    value={editedData.sch_phn_intake}
                    maxLength={6}
                  />
                </div>
                <span className="error_msg">{errors.sch_phn_intake}</span>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">
                  PHN Enrollment Date
                </label>
                <div className="inputs">
                  <input
                    type="date"
                    className="form-control"
                    id="exampleDate"
                    placeholder="PHN Enrollment Date*"
                    name="sch_phn_enrollment_date"
                    value={editedData.sch_phn_enrollment_date}
                    // defaultValue={editedData.sch_phn_enrollment_date}
                    readOnly
                  />
                </div>
              </div>
              <div className="input-error">
                <label className="small-headers mb-2">Allocate a Trainer</label>
                <div className="inputs">
                  <select
                    className="custom-select"
                    name="trainer_name"
                    onChange={(e) => setTrainerID(e.target.value)}
                    style={{
                      borderRadius: "16px",
                      border: "1px solid var(--text-20-stroke, #E7E6EA)",
                    }}
                  >
                    {/* <option value={editedData.trainer_name}>
                      {editedData.trainer_name}
                    </option> */}
                    {trainerData.map((data, ind) => {
                      return (
                        <option value={data.tra_id} key={ind}>
                          {data.tra_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <span className="error_msg">{errors.trainer_name}</span>
              </div>
              <div className="Gap"></div>
              {/* <div>
                        <button type="button" className=" btn  cancel-btn"> Cancel</button>
                    </div> <br/><br/><br/> */}
            </div>
            <div className="col-md-6">
              <p className="small-headers">Enrolled Grades*</p>
              <div className="grade_selection">
                <div className="row ms-2">
                  {gradeList.map((grade, ind) => {
                    // console.log("map ind", ind);
                    return (
                      <>
                        <div
                          className="col-sm-2 col-2 col-lg-2 text-center"
                          style={{ cursor: "pointer" }}
                          key={ind}
                        >
                          <input
                            type="checkbox"
                            id={`myCheck${ind + 1}`}
                            className="gradeCheckboxes"
                            onChange={(e) => selectGrade(e.target)}
                            value={ind + 1}
                            onLoad={getChecked(ind + 1)}
                          />
                          <p className="">{grade}</p>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <span className="error_msg">{errors.grade}</span>
            </div>
            <div className="row mt-4 mb-5">
              <div className="col-md-6">
                <div>
                  <div>
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#CancelModal"
                      className=" btn cancel-btn"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <button
                    type="submit"
                    className="btn add-btn"
                    onClick={handleSubmit}
                  >
                    Update
                  </button>
                  {/* 
                  <input
                    type="submit"
                    value="Add"
                    className="btn add-btn"
                    onClick={handleSubmit}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <DeleteSchool sch_id={preData.sch_id} />
        </div>

        {/* <!-- Modal --> */}

        <div
          className="modal fade "
          id="CancelModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content deleteSchoolModal">
              <div className="modal-header">
                <p className="deleteTitle">Cancel School</p>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <p className="deleteMsg ms-3">Are you sure you want to Cancel </p>

              <div className="row mt-3 mb-3">
                <div className="col-lg-6 col-sm-12 col-md-6">
                  <button className="btn yes_btn" onClick={yesBtnHandler}>
                    Yes
                  </button>
                </div>

                <div className="col-lg-6 col-sm-12 col-md-6">
                  <button
                    className="btn no_btn"
                    data-bs-dismiss="modal"
                    // onClick={hideEditModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditSchool;

//edit school with checkbox checked when data is same code of video completed feature branch
