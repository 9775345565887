import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import DayDateProfile from "../Components/DayDateProfile";
import "../Assets/Styles/schoolProfile.css";
import LottieAnimation from "../Components/LottieAnimation";
import schoolLogo from "../Assets/Images/Dashboard/school_logo.svg";
import { GET_PRI_SCHOOL_PROFILE } from "../Components/AllAPI";

const SchoolProfile = () => {
  let principalSidebar = sidebarData.Principle;
  const [schoolData, setSchoolData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");

    fetch(GET_PRI_SCHOOL_PROFILE, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data.students);
        setSchoolData(data.students);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function convertToRoman(num) {
    const romanNumerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    let result = "";

    for (let key in romanNumerals) {
      while (num >= romanNumerals[key]) {
        result += key;
        num -= romanNumerals[key];
      }
    }

    return result;
  }

  function convertListToRoman(input) {
    if (!input || typeof input !== "string") return "Invalid input";

    const numbers = input.split(",").map(Number);
    return numbers.map(convertToRoman).join(",");
  }

  const inputString = schoolData.sch_phn_enrolled_grades;
  const romanNumeralsString = convertListToRoman(inputString);

  return (
    <>
          <Sidebar sidebarLinks={principalSidebar} />

      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          {" "}
          <section className="schoolProfile sidebody">
            <div className="container">
              <DayDateProfile />

              <p className="profile_header mt-5">
                <p className="school_data">School Profile</p>
              </p>
              <div>
                {schoolData.sch_logo === "" || null ? (
                  <img
                    src={schoolLogo}
                    alt="School Icon"
                    className="school_logo"
                  />
                ) : (
                  <img
                    src={schoolData.sch_logo}
                    alt="School Icon"
                    className="school_logo"
                  />
                )}

                <div className="school-name-div mt-4">
                  <p className="school_data">
                    {schoolData.sch_name === "" || null ? (
                      <p>-</p>
                    ) : (
                      schoolData.sch_name
                    )}
                  </p>
                </div>
              </div>
              <hr />
              <div className="Enrolled_div">
                <p className="sub-header mb-0">Enrolled Grades:</p>

                <p className="school_data">
                  {romanNumeralsString === "" || null ? (
                    <p>-</p>
                  ) : (
                    romanNumeralsString
                  )}
                </p>
              </div>
              <div class="row">
                <div class="col ">
                  <div className="Contact-div">
                    <p className="sub-header mb-0">Contact No.</p>

                    <p className="school_data">
                      {schoolData.sch_princi_contact === "" || null ? (
                        <p>-</p>
                      ) : (
                        schoolData.sch_princi_contact
                      )}
                    </p>
                  </div>
                  <div className="Enrolled-date-div">
                    <p className="sub-header mb-0">PHN Enrolled Date:</p>

                    <p className="school_data">
                      {schoolData.sch_phn_enrollment_date === "" || null ? (
                        <p>-</p>
                      ) : (
                        schoolData.sch_phn_enrollment_date
                      )}
                    </p>
                  </div>
                  <div className="Address-div">
                    <p className="sub-header mb-0">Address:</p>

                    <p className="school_data">
                      {schoolData.sch_address === "" || null ? (
                        <p>-</p>
                      ) : (
                        schoolData.sch_address
                      )}
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div className="Email-div">
                    <p className="sub-header mb-0">Email:</p>

                    <p className="school_data">
                      {schoolData.sch_princi_email === "" || null ? (
                        <p>-</p>
                      ) : (
                        schoolData.sch_princi_email
                      )}
                    </p>
                  </div>
                  <div className="Intake-div">
                    <p className="sub-header mb-0">Enrolled Students:</p>

                    <p className="school_data">
                      {schoolData.sch_phn_intake === "" || null ? (
                        <p>-</p>
                      ) : (
                        schoolData.sch_phn_intake
                      )}{" "}
                      /{" "}
                      {schoolData.sch_total_intake === "" || null ? (
                        <p>-</p>
                      ) : (
                        schoolData.sch_total_intake
                      )}
                    </p>
                  </div>
                  <div className="Intake-div">
                    <p className="sub-header mb-0">Board:</p>

                    <p className="school_data">
                      {schoolData.sch_board === "" || null ? (
                        <p>-</p>
                      ) : (
                        schoolData.sch_board
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default SchoolProfile;
