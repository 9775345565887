import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import { Link } from "react-router-dom";
import $ from "jquery";
import DayDateProfile from "../Components/DayDateProfile";
import profileImg from "../Assets/Images/StudentList/student_profile.svg";
import LottieAnimation from "../Components/LottieAnimation";
import { GET_ALL_PROFILE_STUDENT } from "../Components/AllAPI";

const AllProfilesStudent = () => {
  let studentSidebar = sidebarData.Student;
  const [imageLoaded, setImageLoaded] = useState(true);

  const [studentProfile, setStudentProfile] = useState(true);
  const [schoolProfile, setSchoolProfile] = useState(false);
  const [trainerProfile, setTrainerProfile] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [trainerData, setTrainerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  $(document).ready(function () {
    $(".allProfiles .nav-link").click(function () {
      $(".allProfiles .nav-link").removeClass("active");
      $(this).addClass("active");
    });
  });

  const showStudentProfile = () => {
    setStudentProfile(true);
    setSchoolProfile(false);
    setTrainerProfile(false);
  };

  const showSchoolProfile = () => {
    setStudentProfile(false);
    setSchoolProfile(true);
    setTrainerProfile(false);
  };

  const showTrainerProfile = () => {
    setStudentProfile(false);
    setSchoolProfile(false);
    setTrainerProfile(true);
  };

  const fetchData = () => {
    setIsLoading(true);
    setImageLoaded(true);
    var token = localStorage.getItem("authToken");

    fetch(GET_ALL_PROFILE_STUDENT, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setSchoolData(data.school_info);
        setStudentData(data.student_info);
        setTrainerData(data.trainer_info);
        setIsLoading(false);
        setImageLoaded(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function convertToRoman(num) {
    const romanNumerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    let result = "";

    for (let key in romanNumerals) {
      while (num >= romanNumerals[key]) {
        result += key;
        num -= romanNumerals[key];
      }
    }

    return result;
  }

  function convertListToRoman(input) {
    if (!input || typeof input !== "string") return "Invalid input";

    const numbers = input.split(",").map(Number);
    return numbers.map(convertToRoman).join(",");
  }

  const inputString = schoolData.sch_phn_enrolled_grades;
  const romanNumeralsString = convertListToRoman(inputString);

  return (
    <>
      <Sidebar sidebarLinks={studentSidebar} />
      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          <section className="allProfiles sidebody">
            <div className="container">
              {/* <DayDateProfile /> */}
              <p className="profile_title mt-4">Profile</p>
              <div className="d-flex">
                <div className="grades">
                  <nav className="navbar navbar-expand-lg navbar-light">
                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav mr-auto">
                        {/* <li className="nav-item me-3 active">
                        <Link className="nav-link" to="#">
                          All
                        </Link>
                      </li> */}
                        <li
                          className="nav-item me-3"
                          onClick={showStudentProfile}
                        >
                          <Link className="nav-link active" to="#">
                            Your Profile
                          </Link>
                        </li>
                        <li
                          className="nav-item me-3"
                          onClick={showSchoolProfile}
                        >
                          <Link className="nav-link" to="#">
                            School
                          </Link>
                        </li>
                        <li
                          className="nav-item me-3"
                          onClick={showTrainerProfile}
                        >
                          <Link className="nav-link" to="#">
                            Trainer
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              {/* student profile */}
              {studentProfile ? (
                <div className="studentProfile mt-4">
                  <div>
                    {imageLoaded === true ? (
                      <img src={profileImg} alt="profile_image" />
                    ) : studentData.stu_image === null ||
                      studentData.stu_image === "" ? (
                      <img src={profileImg} alt="profile_image" />
                    ) : (
                      <img
                        src={studentData.stu_image}
                        alt="School Icon"
                        className="profile_logo"
                        height={100}
                        width={100}
                        style={{ borderRadius: "50px" }}
                      />
                    )}

                    <div className="school-name-div mt-4">
                      <p className="school_data">
                        {studentData.stu_fname === null ||
                        studentData.stu_fname === "" ? (
                          <p>-</p>
                        ) : (
                          studentData.stu_fname
                        )}{" "}
                        &nbsp;
                        {studentData.stu_mname === null ||
                        studentData.stu_fname === "" ? (
                          <p>-</p>
                        ) : (
                          studentData.stu_mname
                        )}
                        &nbsp;
                        {studentData.stu_lname === null ||
                        studentData.stu_fname === "" ? (
                          <p>-</p>
                        ) : (
                          studentData.stu_lname
                        )}
                      </p>
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div className="col-lg-6 col-6">
                      <p className="sub-header mb-0">DOB:</p>

                      <p className="school_data">
                        {studentData.stu_dob === null ||
                        studentData.stu_dob === "" ? (
                          <p>-</p>
                        ) : (
                          studentData.stu_dob
                        )}
                      </p>
                    </div>

                    <div class="col-lg-6 col-6">
                      <p className="sub-header mb-0">Blood Group</p>

                      <p className="school_data">
                        {studentData.stu_blood_grp === null ||
                        studentData.stu_blood_grp === "" ? (
                          <p>-</p>
                        ) : (
                          studentData.stu_blood_grp
                        )}
                      </p>
                    </div>
                    <div className="col-lg-6 col-6">
                      <p className="sub-header mb-0">PHN Enrolled Date:</p>

                      <p className="school_data">
                        {studentData.stu_phn_enroll_date === null ||
                        studentData.stu_phn_enroll_date === "" ? (
                          <p>-</p>
                        ) : (
                          studentData.stu_phn_enroll_date
                        )}
                      </p>
                    </div>
                    <div className="col-lg-6 col-6">
                      <p className="sub-header mb-0">Email:</p>

                      <p className="school_data">
                        {studentData.stu_email === null ||
                        studentData.stu_email === "" ? (
                          <p>-</p>
                        ) : (
                          studentData.stu_email
                        )}
                      </p>
                    </div>
                    <div className="col-lg-6 col-6">
                      <p className="sub-header mb-0">Address:</p>

                      <p className="school_data">
                        {studentData.stu_address === null ||
                        studentData.stu_address === "" ? (
                          <p>-</p>
                        ) : (
                          studentData.stu_address
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* school profile */}
              {schoolProfile ? (
                <div className="schoolProfile mt-4">
                  <div>
                    {imageLoaded ? (
                      <img src={profileImg} alt="profile_image" />
                    ) : schoolData.sch_logo === null ||
                      schoolData.sch_logo === "" ? (
                      <img src={profileImg} alt="profile_image" />
                    ) : (
                      <img
                        src={schoolData.sch_logo}
                        alt="School Icon"
                        className="profile_logo"
                        height={100}
                        width={100}
                        style={{ borderRadius: "50px" }}
                        onLoad={() => setImageLoaded(false)}
                      />
                    )}
                    <div className="school-name-div mt-4">
                      <p className="school_data">
                        {schoolData.sch_name === null ||
                        schoolData.sch_name === "" ? (
                          <p>-</p>
                        ) : (
                          schoolData.sch_name
                        )}
                      </p>
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-lg-6 col-6">
                      <div className="col-lg-6 col-6">
                        <p className="sub-header mb-0">Enrolled Grades:</p>

                        <p className="school_data">
                          {romanNumeralsString === null ||
                          romanNumeralsString === "" ? (
                            <p>-</p>
                          ) : (
                            romanNumeralsString
                          )}
                        </p>
                      </div>
                      <div className="Contact-div">
                        <p className="sub-header mb-0">Contact No.</p>

                        <p className="school_data">
                          {schoolData.sch_princi_contact === null ||
                          schoolData.sch_princi_contact === "" ? (
                            <p>-</p>
                          ) : (
                            schoolData.sch_princi_contact
                          )}
                        </p>
                      </div>
                      <div className="col-lg-6 col-6">
                        <p className="sub-header mb-0">PHN Enrolled Date:</p>

                        <p className="school_data">
                          {schoolData.sch_phn_enrollment_date === null ||
                          schoolData.sch_phn_enrollment_date === "" ? (
                            <p>-</p>
                          ) : (
                            schoolData.sch_phn_enrollment_date
                          )}
                        </p>
                      </div>

                      <div className="col-lg-6 col-6">
                        <p className="sub-header mb-0">Enrolled Students:</p>

                        <p className="school_data">
                          {schoolData.sch_phn_intake === null ||
                          schoolData.sch_phn_intake === "" ? (
                            <p>-</p>
                          ) : (
                            schoolData.sch_phn_intake
                          )}
                          /
                          {schoolData.sch_total_intake === null ||
                          schoolData.sch_total_intake === "" ? (
                            <p>-</p>
                          ) : (
                            schoolData.sch_total_intake
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-6 col-6">
                      <div className="Email-div">
                        <p className="sub-header mb-0">Email:</p>

                        <p className="school_data">
                          {schoolData.sch_princi_email === null ||
                          schoolData.sch_princi_email === "" ? (
                            <p>-</p>
                          ) : (
                            schoolData.sch_princi_email
                          )}
                        </p>
                      </div>
                      <div className="col-lg-6 col-6">
                        <p className="sub-header mb-0">Board:</p>

                        <p className="school_data">
                          {schoolData.sch_board === null ||
                          schoolData.sch_board === "" ? (
                            <p>-</p>
                          ) : (
                            schoolData.sch_board
                          )}
                        </p>
                      </div>
                      <div className="col-lg-6 col-6">
                        <p className="sub-header mb-0">Address:</p>

                        <p className="school_data">
                          {schoolData.sch_address === null ||
                          schoolData.sch_address === "" ? (
                            <p>-</p>
                          ) : (
                            schoolData.sch_address
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* trainer profile */}

              {trainerProfile ? (
                trainerData === null || trainerData === "" ? (
                  <p className="pt-5">No Trainer Allocated</p>
                ) : (
                  <div className="trainerProfile mt-4">
                    <div>
                      
                      {imageLoaded ? (
                        <img src={profileImg} alt="profile_image" />
                      ) : trainerData.tra_image === null ||
                        trainerData.tra_image === "" ? (
                        <img src={profileImg} alt="profile_image" />
                      ) : (
                        <img
                          src={trainerData.tra_image}
                          alt="School Icon"
                          className="profile_logo"
                          height={100}
                          width={100}
                          style={{ borderRadius: "50px" }}
                          onLoad={() => setImageLoaded(false)} 
                        />
                      )}
                      {/* <img src={profileImg} alt="profile_image" /> */}

                      <div className="school-name-div mt-4">
                        <p className="school_data">
                          {trainerData.tra_name === null ||
                          trainerData.tra_name === "" ? (
                            <p>-</p>
                          ) : (
                            trainerData.tra_name
                          )}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-6 col-6">
                        <p className="sub-header mb-0">Qualification</p>

                        <p className="school_data">
                          {trainerData.tra_qualification === null ||
                          trainerData.tra_qualification === "" ? (
                            <p>-</p>
                          ) : (
                            trainerData.tra_qualification
                          )}
                        </p>
                      </div>
                      <div className="col-lg-6 col-6">
                        <p className="sub-header mb-0">Email</p>

                        <p className="school_data">
                          {trainerData.tra_email === null ||
                          trainerData.tra_email === "" ? (
                            <p>-</p>
                          ) : (
                            trainerData.tra_email
                          )}
                        </p>
                      </div>

                      <div className="col-lg-6 col-6">
                        <p className="sub-header mb-0">Experience</p>

                        <p className="school_data">
                          {trainerData.tra_experience === null ||
                          trainerData.tra_experience === "" ? (
                            <p>-</p>
                          ) : (
                            trainerData.tra_experience
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AllProfilesStudent;
