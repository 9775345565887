import React, { useEffect, useState } from "react";
import "../Assets/Styles/StudentList.css";
import { Link } from "react-router-dom";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Excelexport from "../Excelexport";
import Sidebar from "./Sidebar";
import $ from "jquery";
import Bulkupload from "./Bulkupload";
import AddStudent from "./AddStudent";
import { API_START_POINT, GET_ATTEND_DATA_API } from "./AllAPI";
function AttendData() {
  const [student, setStudent] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(GET_ATTEND_DATA_API, {
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setStudent(json.students.grade_1);
        setStudentData(json.students);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  $(document).ready(function () {
    $(".studentList .nav-link").click(function () {
      $(".studentList .nav-link").removeClass("active");
      $(this).addClass("active");
    });
  });
  let trainerSidebar = sidebarData.Trainer;
  return (
    <>
      <Sidebar sidebarLinks={trainerSidebar} />
      <section className="studentList sidebody">
        <div className="container">
          <div className="tableNavbar">
            <div className="row">
              <div className="col-lg-10 col-sm-12 col-md-12 col-12">
                <p className="studentListTitle">Students List</p>
              </div>
              <div className="col-lg-2 col-sm-12 col-md-12 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    <span className="fa fa-search"></span>
                  </span>
                  <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="grades">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mr-auto">
                      {/* <li className="nav-item me-3 active">
                        <Link className="nav-link" to="#">
                          All
                        </Link>
                      </li> */}
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudent(studentData.grade_1)}
                      >
                        <Link className="nav-link active" to="#">
                          I
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudent(studentData.grade_2)}
                      >
                        <Link className="nav-link" to="#">
                          II
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudent(studentData.grade_3)}
                      >
                        <Link className="nav-link" to="#">
                          III
                        </Link>
                      </li>
                      <li className="nav-item me-3">
                        <Link
                          className="nav-link"
                          to="#"
                          onClick={() => setStudent(studentData.grade_4)}
                        >
                          IV
                        </Link>
                      </li>
                      <li className="nav-item me-3">
                        <Link
                          className="nav-link"
                          to="#"
                          onClick={() => setStudent(studentData.grade_5)}
                        >
                          V
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudent(studentData.grade_6)}
                      >
                        <Link className="nav-link" to="#">
                          VI
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudent(studentData.grade_7)}
                      >
                        <Link className="nav-link" to="#">
                          VII
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudent(studentData.grade_8)}
                      >
                        <Link className="nav-link" to="#">
                          VIII
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudent(studentData.grade_9)}
                      >
                        <Link className="nav-link" to="#">
                          IX
                        </Link>
                      </li>
                      <li
                        className="nav-item me-3"
                        onClick={() => setStudent(studentData.grade_10)}
                      >
                        <Link className="nav-link" to="#">
                          X
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <span className="verticalDash"></span>
              <Bulkupload bulkuploadAPI={`${API_START_POINT}/save-data`} />

              <Excelexport excelData={student} fileName={"Student List"} />

              <AddStudent />
            </div>
            <div>
              <table className="table table-borderless text-center mt-5">
                <thead>
                  <th scope="col">Sr No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Grade</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Email id</th>
                  <th scope="col">Parents name</th>
                  <th scope="col">Attendance</th>
                </thead>
                <tbody>
                  {student
                    .filter((data) => {
                      return searchInput.toLowerCase() === ""
                        ? data
                        : data.stu_id.toString().includes(searchInput) ||
                            data.stu_name.toLowerCase().includes(searchInput) ||
                            data.stu_grade.toString().includes(searchInput) ||
                            data.stu_father_contact
                              .toString()
                              .includes(searchInput) ||
                            data.stu_email
                              .toLowerCase()
                              .includes(searchInput) ||
                            data.stu_father_name
                              .toLowerCase()
                              .includes(searchInput) ||
                            data.average_attendance
                              .toString()
                              .includes(searchInput);
                    })

                    .map((data, ind) => (
                      <tr key={ind}>
                        <td className="stud_id">{data.stu_id}</td>
                        <td className="stud_name">{data.stu_name}</td>
                        <td>{data.stu_grade}</td>
                        <td>{data.stu_father_contact}</td>
                        <td>{data.stu_email}</td>
                        <td>{data.stu_father_name}</td>
                        <td>{data.average_attendance}</td>
                        {/* <td>{data.stu_name}</td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AttendData;
