import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";

import ProfilePrinciple from "../StudentPages/ProfilePrinciple";
import TrainerChart from "./TrainerChart";
import SchoolPOC from "./SchoolPOC";
import { useEffect, useState } from "react";
import LottieAnimation from "../Components/LottieAnimation";
import { GET_TRAINER_DASHBOARD_COUNT } from "../Components/AllAPI";
import { useNavigate } from "react-router-dom";

const TrainerDashboard = (props) => {
  let trainerSidebar = sidebarData.Trainer;
  const schoolsId = props.schoolsId;
  const [selectedSchoolId,setSelectedSchoolId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [progressData, setProgressData] = useState([]);
  const navigate= useNavigate()

  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_TRAINER_DASHBOARD_COUNT, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
        schoolId: `${schoolsId}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("dataPS", data);
        setProgressData(data);
        // setStudents(data.student_count);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    setSelectedSchoolId(props.schoolsId);
  }, [props.schoolsId]);
  useEffect(() => {
    // Prevent going back to the previous page after logging out
    const clearHistory = () => {
      window.history.pushState(null, window.location.pathname);
      window.onpopstate = () => {
        navigate("/trainerdashboard");
      };
    };
    clearHistory();
    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);
  return (
    <>
      <Sidebar sidebarLinks={trainerSidebar} selectedSchoolId={selectedSchoolId}></Sidebar>

      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          <section className="sidebody">
            <div className="container">
              <div className="row">
                <div className="col-lg-9 col-md-12 col-12">
                  {/* <DayDateProfile /> */}

                  <div className="row mt-5">
                    <div className="col-lg-4 col-4">
                      <section className="totalStudent">
                        <div
                          className="card total_students h-100"
                          style={{ backgroundColor: "#F2F6EF" }}
                        >
                          <p className="totalNo mb-0">
                            {progressData.totalStudents}
                          </p>
                          <p
                            className="totalTitle"
                            style={{ fontWeight: "bolder" }}
                          >
                            Total Students
                          </p>
                        </div>
                      </section>
                    </div>
                    <div className="col-lg-4 col-4">
                      <div
                        className="card avg_completion h-100"
                        style={{ backgroundColor: "#EDF3FC" }}
                      >
                        <p className="totalNo mb-0">
                          {/* {`${progressData.principal_average_progress}%`} */}
                          {progressData.principal_average_progress === 0 ||
                          progressData.principal_average_progress === undefined
                            ? "0"
                            : progressData.principal_average_progress}
                          %
                        </p>
                        <p
                          className="totalTitle"
                          style={{ fontWeight: "bolder" }}
                        >
                          Avg. Completion
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-4">
                      <div
                        className="card avg_completion h-100"
                        style={{ backgroundColor: "#FFF4F0" }}
                      >
                        <p className="totalNo mb-0">
                          {progressData.principal_average_attendance === 0 ||
                          progressData.principal_average_attendance ===
                            undefined
                            ? "0"
                            : progressData.principal_average_attendance.toFixed(
                                2
                              )}
                          %
                        </p>
                        <p
                          className="totalTitle"
                          style={{ fontWeight: "bolder" }}
                        >
                          Avg. Attendance
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row pb-5">
                    {/* <div className="col-lg-12 col-12">
                      <TrainerChart schoolsId={schoolsId} />
                    </div> */}
                    <div className="col-lg-6 col-6">
                      <SchoolPOC schoolsId={schoolsId} />
                    </div>
                  </div>
                </div>
                {/* <div
                  className="col-lg-3 col-md-12 col-12 mt-5"
                  style={{ borderLeft: "2px solid #F3F5F7" }}
                >
                  <ProfilePrinciple />
                </div> */}
              </div>
              {/* <div className="row mt-5">
                <RecentlyVideos
                  linkTo={"/singleVideo"}
                  viewAll={"/videos"}
                  schoolsId={schoolsId}
                />
              </div> */}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default TrainerDashboard;
