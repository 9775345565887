import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
// import trainerpic from "./assets/image.png";
// import school_logo from "./assets/school_log.png";
// import callIcon from "./assets/phone-solid.svg";
import userIcon from "../Assets/Images/Dashboard/user_speaking.svg";
import callIcon from "../Assets/Images/Dashboard/calling_icon.svg";
import sidebarData from "../Assets/Json/sidebarJson.json";
import "../Assets/Styles/Trainer.css";
import DayDateProfile from "../Components/DayDateProfile";
import profileImg from "../Assets/Images/StudentList/student_profile.svg";
import schoolLogo from "../Assets/Images/Dashboard/school_logo.svg";
import { Link, useLocation } from "react-router-dom";
import LottieAnimation from "../Components/LottieAnimation";
import { GET_MGR_SINGLE_TRAINER_PROFILE } from "../Components/AllAPI";

const SingleTrainerProfile = () => {
  let managerSidebar = sidebarData.Manager;

  const [trainerProfile, setTrainerProfile] = useState("");
  const [trainersSchools, setTrainersSchools] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const tra_id = location.state.data.tra_id;

  useEffect(() => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    async function fetchData() {
      try {
        const response = await fetch(
          GET_MGR_SINGLE_TRAINER_PROFILE,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              authorization: "Bearer " + token,
              Accept: "application/json",
              "Content-Type": "application/json",
              trainerId: `${tra_id}`,
            },
          }
        );
        const json = await response.json();
        console.log("profileData", json);
        setTrainerProfile(json.trainerProfile);
        setTrainersSchools(json.schools);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          <Sidebar sidebarLinks={managerSidebar} />

          <div className="Trainer-main-section sidebody">
            <div className="container">
              <DayDateProfile />
              <div className="row mt-5">
                <div className="col-12 col-lg-4 col-md-4 col-sm-4">
                  <div className="trainer-img text-center">
                    <img src={profileImg} alt="TrainerImage" />
                  </div>
                  <div className="trainer-name mt-3">
                    {trainerProfile.tra_name}
                  </div>
                </div>

                <div
                  className="col-12 col-lg-8 col-md-8 col-sm-8 "
                  style={{ borderLeft: "2px solid #E7E6EA" }}
                >
                  <div className="row ps-5">
                    <div className="col-12 col-lg-4 col-md-4 col-sm-4">
                      <p className="trainer-title-text">Trainer Mobile</p>
                      <p className="trainer-body-text ">
                        {trainerProfile.tra_phone}
                      </p>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-sm-4">
                      <p className="trainer-title-text">Assigned location</p>
                      <p className="trainer-body-text">
                        {trainerProfile.tra_address === null || "" ? (
                          <p>NA</p>
                        ) : (
                          trainerProfile.tra_address
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row ps-5">
                    <div className="col-12 col-lg-4 col-md-4 col-sm-4">
                      <p className="trainer-title-text">Qualification</p>
                      <p className="trainer-body-text">
                        {trainerProfile.tra_qualification}
                      </p>
                    </div>
                    <div className="col-12 col-lg-4 col-md-4 col-sm-4">
                      <p className="trainer-title-text">Experience</p>
                      <p className="trainer-body-text">
                        {trainerProfile.tra_experience}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="schools-allocated">
                <div
                  className="trainer-title-text mt-5 mb-4 pt-3"
                  style={{ fontSize: "16px" }}
                >
                  Schools Allocated
                </div>

                <div className="row ">
                  {/* map function here  for a row*/}
                  {trainersSchools.length > 0 ? (
                    trainersSchools.map((schData, ind) => {
                      return (
                        <div
                          className="col-lg-4 col-md-6 col-sm-12 col-12"
                          key={ind}
                        >
                          <Link
                            to="/manager/specificSchool"
                            style={{ textDecoration: "none", color: "black" }}
                            state={schData}
                          >
                            <div className="card schools-allocated-card">
                              <div className="card-body">
                                <div className="d-flex school-allocated-row-div">
                                  <div className="text-center">
                                    {schData.sch_logo === null ||
                                    schData.sch_logo === "" ? (
                                      <img
                                        src={schoolLogo}
                                        alt="school_logo"
                                        className="school-img-logo"
                                        width={50}
                                      />
                                    ) : (
                                      <img
                                        src={schData.sch_logo}
                                        alt="school_logo"
                                        className="school-img-logo"
                                        width={50}
                                        height={50}
                                        style={{ borderRadius: "100%" }}
                                      />
                                    )}
                                  </div>

                                  <div className="ms-4 mt-2">
                                    <p className="trainer-schools-name text-center">
                                      {schData.sch_name}
                                    </p>
                                  </div>
                                </div>
                                <div className="separation-line mt-3 mb-3"></div>
                                <div className="row school-allocated-row-div">
                                  <div className="col-6 d-flex">
                                    <div className="image-logo-div me-3">
                                      <img src={userIcon} alt="userIcon" />
                                    </div>
                                    <p className="school-allocated-card-body-text">
                                      {schData.sch_poc_name}
                                    </p>
                                  </div>
                                  <div className="col-6 d-flex">
                                    <div className="image-logo-div me-1">
                                      <img src={callIcon} alt="callIcon" />
                                    </div>
                                    <p className="school-allocated-card-body-text">
                                      {schData.sch_poc_contact}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center">
                      <div className="noData">No schools available</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SingleTrainerProfile;
