import React, { useEffect, useState } from "react";
import profileImg from "../Assets/Images/StudentList/student_profile.svg";
import "../Assets/Styles/ProfileStudent.css";
import { GET_PROFILE_PRINCIPLE } from "../Components/AllAPI";

const ProfilePrinciple = () => {
  const [profile, setProfile] = useState({});
  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_PROFILE_PRINCIPLE, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("data pr trainer", data);
        setProfile(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // console.log("data pr trainer len", Object.keys(profile).length);
  const emptyObj = Object.keys(profile).length === 0;
  return (
    <>
      <section className="profile">
        <div className="container">
          <p className="profile_title">Trainer Profile</p>
          <div>
            <img src={profileImg} alt="" />
          </div>
          <p className="student_name">
            {emptyObj ? "-" : profile.tra_name}
          </p>

          <div className="stud_data">
            <div className="row">
              <div className="col-12">
                <p className="data_title mb-0">Email</p>
                <p className="data_subititle">
                  {emptyObj ? "-" : profile.tra_email}
                </p>
              </div>
              <br />
              <div className="col-6">
                <p className="data_title mb-0">Mobile no</p>
                <p className="data_subititle">
                  {emptyObj ? "-" : profile.tra_phone}
                </p>
              </div>

              <div className="col-6">
                <p className="data_title mb-0">Qualification</p>
                <p className="data_subititle">
                  {emptyObj ? "-" : profile.tra_qualification}
                </p>
              </div>
              <br />
              <div className="col-6">
                <p className="stud_addres_title mb-0">Work Experience</p>
                <p className="stud_address_subtitle">
                  {emptyObj ? "-" : profile.tra_experience}
                </p>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfilePrinciple;
