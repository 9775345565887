import React, { useState } from "react";
import phnElearnImg from "../Assets/Images/Login/phn-elearn.webp";
import "../Assets/Styles/ForgotAndResetPass.css";
import { Link, useNavigate } from "react-router-dom";
import { POST_FORGOT_PASS_API } from "../Components/AllAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [inputData, setInputData] = useState({ email_id: "" });
  const [inputError, setInputError] = useState({});
  const navigate = useNavigate();
  const inputHandler = (event) => {
    const { name, value } = event.target;
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const error = {};
    const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z][a-z.A-Z]+$/;

    const { email_id } = inputData;

    if (!email_id.trim()) {
      error.email = "Please enter an email address.";
    } else if (!emailRegx.test(email_id.trim())) {
      error.email = "Please enter a valid email address.";
    }

    setInputError(error);
    // console.log('error: ',error);
    return Object.keys(error).length === 0;

  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const isFormValid = validateForm();

    if(isFormValid){
      try {
        let res = await fetch(POST_FORGOT_PASS_API, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: inputData.email_id,
          }),
        });
        let resJson = await res.json();
        // console.log("resjson", resJson);
        const msg = resJson.message;
        if (msg === "User not found") {
          toast.warning("User not found", {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else if (msg === "Password reset link has been sent to your email") {
          toast("Password reset link has been sent to your email", {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
  
        // console.log("msg", msg);
      } catch (err) {
        console.log(err);
      }
    }
    // else{
    //   toast("Please check email id.", {
    //     position: "top-center",
    //     autoClose: 6000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // }
  };

  const navigatToLoginPage = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="container forgetPassMain">
      <div className="card forgetPassCard">
        <div className="card-body p-0">
          <ToastContainer
            style={{ width: "25%" }}
            autoClose={6000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="text-center">
            <img src={phnElearnImg} alt="Phn-Learn" className="w-50" />
            {/* <p className="forgetPass-title mt-4">Forgot Password</p> */}
          </div>

          <div className="mt-5">
            <p className="forgetPass-label_text">Email Id</p>
            <form onSubmit={submitHandler}>
              <input
                type="text"
                className="form-control forgetPassInput"
                aria-label="email"
                onChange={inputHandler}
                value={inputData.email_id}
                name="email_id"
              />
              <span className="error-msg">{inputError.email}</span>
              <input
                type="submit"
                value="Send Reset Link"
                className="btn loginBtn w-100 mt-4"
              />
              <div className="text-center mt-3">
                <Link className="logInLink" onClick={navigatToLoginPage}>
                  Log In
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;