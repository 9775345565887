import React, { useEffect, useState } from "react";
import { GET_PRINCIPAL_PROGRESS } from "../Components/AllAPI";

const PrincipalProgress = () => {
  const [count, setCount] = useState([]);

  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_PRINCIPAL_PROGRESS, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setCount(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-4 col-12">
          <div className="card avg_completion">
            <p className="totalNo mb-1">
              {count.totalStudents === 0 || count.totalStudents === undefined
                ? "0"
                : count.totalStudents}
            </p>
            <p className="totalTitle" style={{ fontWeight: "bolder" }}>
              Total Students
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <div
            className="card avg_completion"
            style={{ backgroundColor: "#EDF3FC" }}
          >
            <p className="totalNo mb-1">
              {" "}
              {count.principal_average_progress === 0 ||
              count.principal_average_progress === undefined
                ? "0"
                : count.principal_average_progress.toFixed(2)}
              %
            </p>
            <p className="totalTitle" style={{ fontWeight: "bolder" }}>
              Avg. Course Completion
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <div
            className="card avg_completion"
            style={{ backgroundColor: "#FFF4F0" }}
          >
            <p className="totalNo mb-1">
              {count.principal_average_attendance === 0 ||
              count.principal_average_attendance === undefined
                ? "0"
                : count.principal_average_attendance.toFixed(2)}
              %
            </p>
            <p className="totalTitle" style={{ fontWeight: "bolder" }}>
              Avg. Attendance
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrincipalProgress;
