import React, { useEffect, useState } from "react";
import "../Assets/Styles/StudentList.css";
import { Link, useNavigate } from "react-router-dom";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Excelexport from "../Excelexport";
import Sidebar from "./Sidebar";
import $ from "jquery";
import AddStudent from "./AddStudent";
import StudentBulkImport from "../StudentPages/StudentBulkImport";
// import ChooseBulkUpload from "./ChooseBulkUpload";
import LottieAnimation from "./LottieAnimation";
import prevArrow from "../Assets/Images/SuperAdmin/prev_arrow.png";
import nextArrow from "../Assets/Images/SuperAdmin/next_arrow.png";
import {
  API_START_POINT,
  STUD_LIST_STRUCTURE_STUDLIST_TRAINER,
} from "./AllAPI";

function StudentListStructure(props) {
  const [student, setStudent] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isActive, setActive] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const sch_id = props.studSchId;
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(STUD_LIST_STRUCTURE_STUDLIST_TRAINER, {
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
        schoolId: `${sch_id}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
        setStudentData(json.students);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching student data:", error);
        setIsLoading(false);
      });

    $(".studentList .nav-item").click(function () {
      $(".studentList .nav-item").removeClass("active");
      $(this).addClass("active");
    });
  }, [props.studSchId]);

  useEffect(() => {
    if (studentData === undefined) {
      setStudent("");
    } else {
      switch (isActive) {
        case "All":
          setStudent(studentData.all_students);
          break;
        case "I":
          setStudent(studentData.grade_1);
          break;
        case "II":
          setStudent(studentData.grade_2);
          break;
        case "III":
          setStudent(studentData.grade_3);
          break;
        case "IV":
          setStudent(studentData.grade_4);
          break;
        case "V":
          setStudent(studentData.grade_5);
          break;
        case "VI":
          setStudent(studentData.grade_6);
          break;
        case "VII":
          setStudent(studentData.grade_7);
          break;
        case "VIII":
          setStudent(studentData.grade_8);
          break;
        case "IX":
          setStudent(studentData.grade_9);
          break;
        case "X":
          setStudent(studentData.grade_10);
          break;
        case "XI":
          setStudent(studentData.grade_11);
          break;
        case "XII":
          setStudent(studentData.grade_12);
          break;
        default:
          setStudent(studentData.all_students);
      }
    }
  }, [studentData, isActive]);

  let trainerSidebar = sidebarData.Trainer;

  function convertToRoman(num) {
    const romanNumerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    let result = "";

    for (let key in romanNumerals) {
      while (num >= romanNumerals[key]) {
        result += key;
        num -= romanNumerals[key];
      }
    }

    return result;
  }
  const reloadTableData = () => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(STUD_LIST_STRUCTURE_STUDLIST_TRAINER, {
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
        schoolId: `${sch_id}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
        setStudentData(json.students);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching student data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (student && student.length > 0) {
      const filtered = student.filter((data) => {
        const lowercaseSearchInput = searchInput.toLowerCase();
        return (
          lowercaseSearchInput === "" ||
          (data.stu_fname &&
            data.stu_fname.toLowerCase().includes(lowercaseSearchInput)) ||
          (data.stu_mname &&
            data.stu_mname.toLowerCase().includes(lowercaseSearchInput)) ||
          (data.stu_lname &&
            data.stu_lname.toLowerCase().includes(lowercaseSearchInput)) ||
          (data.stu_grade &&
            data.stu_grade.toString().includes(lowercaseSearchInput)) ||
          (data.stu_father_contact &&
            data.stu_father_contact
              .toString()
              .includes(lowercaseSearchInput)) ||
          (data.stu_email &&
            data.stu_email.toString().includes(lowercaseSearchInput)) ||
          (data.stu_father_name &&
            data.stu_father_name
              .toLowerCase()
              .includes(lowercaseSearchInput)) ||
          (data.average_attendance &&
            data.average_attendance.toString().includes(lowercaseSearchInput))
        );
      });

      setResults(filtered);
    } else {
      setResults([]);
    }
  }, [student, searchInput]);

  useEffect(() => {
    setCurrentPage(1);
  }, [isActive]);

  // Slice student data for pagination
  const totalItems = results ? results.length : 0;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = results
    ? results.slice(indexOfFirstItem, indexOfLastItem)
    : [];
  // const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  // const totalItems = filteredData.length;
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const PagePerItem = Math.min(totalItems - indexOfFirstItem, indexOfLastItem);
  const lastItemNumber = Math.min(indexOfLastItem, totalItems);

  // Function to handle changing pages
  const handlePageChange = (pageNumber) => {
    setCurrentPage(parseInt(pageNumber));
  };

  // Function to go to the previous page
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Function to go to the next page
  const nextPage = () => {
    if (indexOfLastItem < totalItems) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };


  return (
    <>
      <Sidebar sidebarLinks={trainerSidebar} />

      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          <section className="studentList sidebody studentData">
            <div className="container">
              <div className="tableNavbar">
                <div className="row">
                  <div className="col-lg-9 col-sm-12 col-md-12 col-12">
                    <p className="studentListTitle">Students List</p>
                  </div>
                  <div className="col-lg-3 col-sm-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="basic-addon1">
                        <span className="fa fa-search"></span>
                      </span>
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="row">
                    <div className="col-7">
                      <div className="grades student_list_structure_grades">
                        <ul className="">
                          <li
                            className={`${
                              isActive === "All"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("All")}
                            onClick={() => setActive("All")}
                          >
                            <Link
                              className="nav-link"
                              style={{
                                color: isActive === "All" ? "white" : "",
                              }}
                              to="#"
                            >
                              All
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "I"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("I")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_1)
                            }
                          >
                            <Link
                              className="nav-link "
                              to="#"
                              style={{
                                color: isActive === "I" ? "white" : "",
                              }}
                            >
                              I
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "II"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("II")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_2)
                            }
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "II" ? "white" : "",
                              }}
                            >
                              II
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "III"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("III")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_3)
                            }
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "III" ? "white" : "",
                              }}
                            >
                              III
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "IV"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("IV")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_4)
                            }
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "IV" ? "white" : "",
                              }}
                            >
                              IV
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "V"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("V")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_5)
                            }
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "V" ? "white" : "",
                              }}
                            >
                              V
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "VI"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("VI")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_6)
                            }
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "VI" ? "white" : "",
                              }}
                            >
                              VI
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "VII"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("VII")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_7)
                            }
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "VII" ? "white" : "",
                              }}
                            >
                              VII
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "VIII"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("VIII")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_8)
                            }
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "VIII" ? "white" : "",
                              }}
                            >
                              VIII
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "IX"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("IX")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_9)
                            }
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "IX" ? "white" : "",
                              }}
                            >
                              IX
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "X"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("X")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_10)
                            }
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "X" ? "white" : "",
                              }}
                            >
                              X
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "XI"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("XI")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_11)
                            }
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "XI" ? "white" : "",
                              }}
                            >
                              XI
                            </Link>
                          </li>
                          <li
                            className={`${
                              isActive === "XII"
                                ? "nav-item me-3 active"
                                : "nav-item me-3"
                            }`}
                            onFocus={() => setActive("XII")}
                            onClick={() =>
                              studentData === undefined
                                ? setStudent("")
                                : setStudent(studentData.grade_12)
                            }
                          >
                            <Link
                              className="nav-link"
                              to="#"
                              style={{
                                color: isActive === "XII" ? "white" : "",
                              }}
                            >
                              XII
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-5 ms-auto">
                      <div className="buttons">
                        <div className="d-flex ms-2">
                          <StudentBulkImport
                            bulkuploadAPI={`${API_START_POINT}/import-student`}
                            sch_id={sch_id}
                            reloadTableData={reloadTableData}
                          />
                          <Excelexport
                            excelData={student}
                            fileName={"Student List"}
                          />

                          <AddStudent
                            sch_id={sch_id}
                            reloadTableData={reloadTableData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <table
                    className="table table-borderless text-center mt-5"
                    id="table1"
                  >
                    <thead style={{ textAlign: "left" }}>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Grade</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Email id</th>
                      <th scope="col">Parents name</th>
                      <th scope="col">Attendance</th>
                    </thead>
                    {studentData && Object.keys(studentData).length > 0 ? (
                      <>
                        {Array.isArray(results) && results.length > 0 ? (
                          <tbody>
                            {currentItems.map((data, ind) => (
                              <tr key={ind} style={{ textAlign: "left" }}>
                                <td className="stud_id">{ind + 1}</td>

                                <td
                                  className="stud_name"
                                  style={{ cursor: "pointer" }}
                                >
                                  <Link
                                    to="/studentAttendance"
                                    style={{
                                      color: "black",
                                      textDecoration: "none",
                                    }}
                                    state={data}
                                  >
                                    {data.stu_fname} {data.stu_mname}{" "}
                                    {data.stu_lname}
                                  </Link>
                                </td>
                                <td>{convertToRoman(data.stu_grade)}</td>
                                <td>{data.stu_father_contact}</td>
                                <td>{data.stu_email}</td>
                                <td>{data.stu_mname}</td>
                                <td>{data.average_attendance.toFixed(2)}%</td>
                                {/* <td>{data.stu_name}</td> */}
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="7" className="noDataContainer">
                                <div className="noData">No data available</div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="7" className="noDataContainer">
                            <div className="noData">No data available</div>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              {Array.isArray(results) && results.length > 0 && (
                <div className="pagination row">
                  <div className="result_msg col-12 col-lg-3 col-sm-7 col-md-7">
                    <p
                      className="result_msg_text"
                      style={{ marginBottom: "0px" }}
                    >
                      {/* Displaying {PagePerItem} results of {totalItems} */}
                      Displaying {indexOfFirstItem + 1} - {lastItemNumber} of {totalItems}
                    </p>
                  </div>

                  <div className="button_module col-12 col-lg-9 col-sm-5 col-md-5 d-flex ">
                    <div
                      className="d-flex"
                      style={{
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <p className="paginate_msg_text">Page</p>
                      <div>
                        <select
                          className="form-select pageDropdown"
                          value={currentPage}
                          onChange={(e) => handlePageChange(e.target.value)}
                          style={{ width: "116%" }}
                        >
                          {Array.from(
                            { length: Math.ceil(totalItems / itemsPerPage) },
                            (_, i) => i + 1
                          ).map((pageNum) => (
                            <option value={pageNum} key={pageNum}>
                              {pageNum}&nbsp;
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="paginate_msg_text d-flex gap-1">
                        <span>of</span> {Math.ceil(totalItems / itemsPerPage)}
                      </p>
                    </div>

                    <div className=" d-flex">
                      <button
                        type="button"
                        className="bton"
                        onClick={prevPage}
                        disabled={currentPage === 1}
                      >
                        <img src={nextArrow} alt="next" className="arrow_btn" />
                      </button>

                      <button
                        type="button"
                        className="bton"
                        onClick={nextPage}
                        disabled={indexOfLastItem >= totalItems}
                      >
                        <img src={prevArrow} alt="prev" className="arrow_btn" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default StudentListStructure;

//working code with pagination
