import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import "../Assets/Styles/Syllabus.css";
import projectLogo from "../Assets/Images/Dashboard/projectLogo.png";
import DayDateProfile from "./DayDateProfile";
import LottieAnimation from "./LottieAnimation";
import syllabusImg from "../Assets/Images/Dashboard/firstLesson.png";
import $ from "jquery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholderImg from "../Assets/Images/Dashboard/placeholder.png";
import { ALL_COURSE_LIST_GET_CHAPTERS_LIST, GET_PROJECT_LIST_API } from "../Components/AllAPI";

const AllCoursesList = () => {
  const user_role = localStorage.getItem("user_role");
  let commonSidebar;
  if (user_role === "Manager") {
    commonSidebar = sidebarData.Manager;
  } else if (user_role === "Trainer") {
    commonSidebar = sidebarData.Trainer;
  } else if (user_role === "Principal") {
    commonSidebar = sidebarData.Principle;
  }

  const [activeGrade, setActiveGrade] = useState(() => {
    const storedGrade = localStorage.getItem("activeGrade");
    return storedGrade ? storedGrade : "grade_1";
  });

  const [syllabus, setSyllabus] = useState([]);
  const [isActive, setIsActive] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filteredChapters, setFilteredChapters] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showChapterData, setShowChapterData] = useState(true);
  const [showProjectData, setShowProjectData] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [results, setResults] = useState([]);

  const fetchData = () => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(ALL_COURSE_LIST_GET_CHAPTERS_LIST, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("data", data);
        setSyllabus(data.videoAssessment);
        // const chapters = data.videoAssessment.sort();
        setFilteredChapters(data.videoAssessment);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (localStorage.getItem("allChapterProjectActive") === "Projects") {
      setShowProjectData(true);
      setShowChapterData(false);
      setIsActive("Projects");
    } else {
      setShowProjectData(false);
      setShowChapterData(true);
      setIsActive("Chapter");
    }
  }, []);
  useEffect(() => {
    setFilteredChapters(syllabus[activeGrade]);
  }, [syllabus[activeGrade]]);

  const currentActive = activeGrade.split("_").pop();

  useEffect(() => {
    if (showProjectData && projectData.length > 0) {
      setFilteredProjects(
        projectData.filter((item) => item.proj_grade === currentActive)
      );
    }
  }, [showProjectData, currentActive, projectData]);
  

  useEffect(() => {
    $(".videoSection .nav-link").click(function () {
      $(".videoSection .nav-link").removeClass("active");
      $(this).addClass("active");
    });

    $(".gradeCircle").click(function () {
      $(".gradeCircle").removeClass("active");
      $(this).addClass("active");
    });
    // }, [chapterData, filterVideos]);
  }, []);

  const projectApi = () => {
    setIsLoading(true);

    var token = localStorage.getItem("authToken");
    fetch(GET_PROJECT_LIST_API, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data.projects && data.projects.length > 0) {
          setProjectData(data.projects);
        } else {
          setProjectData([]);
        }
        setIsLoading(false);
      });
  };


  useEffect(() => {
    if (isActive === "Chapter") {
      fetchData();
    } else if (isActive === "Projects") {
      projectApi();
    }
  }, [isActive]);

  useEffect(() => {
    if (showChapterData) {
      const filteredData = Array.isArray(filteredChapters)
        ? filteredChapters.filter((data) => {
            const lowercaseSearchInput = searchInput.toLowerCase();
            return (
              lowercaseSearchInput === "" ||
              (data.chapter_no &&
                data.chapter_no.toString().includes(lowercaseSearchInput)) ||
              (data.chapter_name &&
                data.chapter_name.toLowerCase().includes(lowercaseSearchInput))
            );
          })
        : [];
      setResults(filteredData);
    } else {
      const filteredData = Array.isArray(filteredProjects)
        ? filteredProjects.filter((data) => {
            const lowercaseSearchInput = searchInput.toLowerCase();
            return (
              lowercaseSearchInput === "" ||
              (data.proj_chapter_no &&
                data.proj_chapter_no
                  .toString()
                  .includes(lowercaseSearchInput)) ||
              (data.proj_title &&
                data.proj_title.toLowerCase().includes(lowercaseSearchInput))
            );
          })
        : [];
      setResults(filteredData);
    }
  }, [filteredChapters, filteredProjects, showChapterData, searchInput]);

  return (
    <>
      <Sidebar sidebarLinks={commonSidebar} />

      <>
        <div className="sidebody chapterList">
          <div className="container">
            {/* {user_role === "Trainer" ? "" : <DayDateProfile />} */}

            <p className="syllabus_title mt-4">Courses</p>
            {/* for navbar */}

            <div className="row me-4">
              <div className="col-lg-8 col-12">
                <div className="grades">
                  <nav className="navbar navbar-expand-lg navbar-light">
                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav mr-auto">
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_1" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_1");
                            localStorage.setItem("activeGrade", "grade_1");
                            setFilteredChapters(syllabus.grade_1);

                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "1"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            style={{
                              color:
                                activeGrade === "grade_1" ? "white" : "black",
                            }}
                          >
                            I
                          </Link>
                        </li>
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_2" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_2");
                            localStorage.setItem("activeGrade", "grade_2");
                            setFilteredChapters(syllabus.grade_2);

                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "2"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            style={{
                              color:
                                activeGrade === "grade_2" ? "white" : "black",
                            }}
                          >
                            II
                          </Link>
                        </li>
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_3" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_3");
                            localStorage.setItem("activeGrade", "grade_3");
                            setFilteredChapters(syllabus.grade_3);
                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "3"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            style={{
                              color:
                                activeGrade === "grade_3" ? "white" : "black",
                            }}
                          >
                            III
                          </Link>
                        </li>
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_4" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_4");
                            localStorage.setItem("activeGrade", "grade_4");
                            setFilteredChapters(syllabus.grade_4);
                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "4"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            style={{
                              color:
                                activeGrade === "grade_4" ? "white" : "black",
                            }}
                          >
                            IV
                          </Link>
                        </li>
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_5" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_5");
                            localStorage.setItem("activeGrade", "grade_5");
                            setFilteredChapters(syllabus.grade_5);
                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "5"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            onClick={() =>
                              setFilteredChapters(syllabus.grade_5)
                            }
                            style={{
                              color:
                                activeGrade === "grade_5" ? "white" : "black",
                            }}
                          >
                            V
                          </Link>
                        </li>
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_6" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_6");
                            localStorage.setItem("activeGrade", "grade_6");
                            setFilteredChapters(syllabus.grade_6);
                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "6"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            style={{
                              color:
                                activeGrade === "grade_6" ? "white" : "black",
                            }}
                          >
                            VI
                          </Link>
                        </li>
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_7" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_7");
                            localStorage.setItem("activeGrade", "grade_7");
                            setFilteredChapters(syllabus.grade_7);
                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "7"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            style={{
                              color:
                                activeGrade === "grade_7" ? "white" : "black",
                            }}
                          >
                            VII
                          </Link>
                        </li>
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_8" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_8");
                            localStorage.setItem("activeGrade", "grade_8");
                            setFilteredChapters(syllabus.grade_8);
                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "8"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            style={{
                              color:
                                activeGrade === "grade_8" ? "white" : "black",
                            }}
                          >
                            VIII
                          </Link>
                        </li>
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_9" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_9");
                            localStorage.setItem("activeGrade", "grade_9");
                            setFilteredChapters(syllabus.grade_9);
                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "9"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            style={{
                              color:
                                activeGrade === "grade_9" ? "white" : "black",
                            }}
                          >
                            IX
                          </Link>
                        </li>
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_10" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_10");
                            localStorage.setItem("activeGrade", "grade_10");
                            setFilteredChapters(syllabus.grade_10);
                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "10"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            style={{
                              color:
                                activeGrade === "grade_10" ? "white" : "black",
                            }}
                          >
                            X
                          </Link>
                        </li>
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_11" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_11");
                            localStorage.setItem("activeGrade", "grade_11");
                            setFilteredChapters(syllabus.grade_11);
                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "11"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            style={{
                              color:
                                activeGrade === "grade_11" ? "white" : "black",
                            }}
                          >
                            XI
                          </Link>
                        </li>
                        <li
                          className={`nav-item me-3 ${
                            activeGrade === "grade_12" ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveGrade("grade_12");
                            localStorage.setItem("activeGrade", "grade_12");
                            setFilteredChapters(syllabus.grade_12);
                            setFilteredProjects(
                              projectData.filter(
                                (item) => item.proj_grade == "12"
                              )
                            );
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            style={{
                              color:
                                activeGrade === "grade_12" ? "white" : "black",
                            }}
                          >
                            XII
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    <span className="fa fa-search"></span>
                  </span>
                  <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="routes-button-div ">
                <Link
                  to=""
                  className={`${
                    isActive === "Chapter"
                      ? "btn routes-button active"
                      : "btn routes-button"
                  }`}
                  onClick={() => {
                    setIsActive("Chapter");
                    setShowChapterData(true);
                    setShowProjectData(false);
                    localStorage.setItem("allChapterProjectActive", "Chapter");
                  }}
                >
                  Chapters
                </Link>
                <Link
                  to=""
                  className={`${
                    isActive === "Projects"
                      ? "btn routes-button active"
                      : "btn routes-button"
                  }`}
                  onClick={() => {
                    setIsActive("Projects");
                    setShowChapterData(false);
                    setShowProjectData(true);
                    localStorage.setItem("allChapterProjectActive", "Projects");
                  }}
                >
                  Projects
                </Link>
              </div>
            </div>
            {isLoading ? (
              <div style={{ marginTop: "-9rem" }}>
                <LottieAnimation />
              </div>
            ) : (
              <>
                <div className="row row-col-auto">
                  {isActive === "Chapter" ? (
                    showChapterData && results && results.length > 0 ? (
                      results.map((data, ind) => (
                        <div
                          className="col-lg-4 col-md-6 col-sm-12 col-12"
                          key={ind}
                        >
                          <div>
                            {
                              <Link
                                to="/allparticularcourse"
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                }}
                                state={{
                                  courses: data,
                                  isActive,
                                }}
                              >
                                <>
                                  {data.chapter_thumbnail === "" ||
                                  data.chapter_thumbnail === null ? (
                                    <LazyLoadImage
                                      src={syllabusImg}
                                      alt="courses_img"
                                      className="Syllabus_Images blur_img mb-0"
                                      width={300}
                                      PlaceholderSrc={placeholderImg}
                                    />
                                  ) : (
                                    <LazyLoadImage
                                      src={data.chapter_thumbnail}
                                      alt="courses_img"
                                      className="Syllabus_Images blur_img mb-0"
                                      width={300}
                                      PlaceholderSrc={placeholderImg}
                                      style={{ borderRadius: "16px" }}
                                    />
                                  )}

                                  <div className="chapter_desc">
                                    <p className="chapter_number">{`Chapter ${data.chapter_no}`}</p>
                                    <p className="chapter_title">
                                      {data.chapter_name}
                                    </p>
                                  </div>
                                </>
                              </Link>
                            }
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">
                        <div className="noData mt-5 pt-5">
                          No data available
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="row row-col-auto">
                  {isActive === "Projects" ? (
                    showProjectData && results && results.length > 0 ? (
                      results.map((data, ind) => (
                        <div
                          className="col-lg-4 col-md-6 col-sm-12 col-12"
                          key={ind}
                        >
                          <div>
                            <Link
                              to="/allloginproject"
                              state={{ data, ind }}
                              style={{ color: "black", textDecoration: "none" }}
                            >
                              <>
                                <div className="blur_img">
                                  <img
                                    src={data.proj_image}
                                    alt="syllabus_img"
                                    className="Syllabus_Images"
                                    width={300}
                                    style={{ borderRadius: "16px" }}
                                  />
                                </div>
                                <div className="chapter_desc">
                                  <div className="d-flex">
                                    <img
                                      src={projectLogo}
                                      alt="projectLogo"
                                      width={38}
                                      height={38}
                                      className="me-2"
                                    />
                                    <div>
                                      <p className="chapter_number">{`Project ${
                                        ind + 1
                                      }`}</p>
                                      <p className="chapter_title">
                                        {data.proj_title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            </Link>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">
                        <div className="noData mt-5 pt-5">
                          No data available
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default AllCoursesList;
