import React, { useEffect, useState } from "react";
import plusIcon from "../Assets/Images/StudentList/plusIcon.svg";
import $ from "jquery";
import { POST_ADD_STUDENT } from "./AllAPI";
import SimpleLoader from "./SimpleLoader";
import { ToastContainer, toast } from "react-toastify";

const AddStudent = (props) => {
  const { reloadTableData } = props;
  const sch_id = props.sch_id;
  let initialData = {
    stu_fname: "",
    stu_mname: "",
    stu_lname: "",
    stu_father_contact: "",
    email: "",
  };
  const [sendData, setSendData] = useState(initialData);
  const [sendGrade, setSendGrade] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const gradeList = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSendData((values) => ({
      ...values,
      [name]: value,
    }));
  };
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      if ($("#staticBackdropAddStudent").hasClass("show")) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
    }
  }, []);

  const validateForm = (values) => {
    const error = {};
    const studName = /^[A-Z a-z]+$/;
    const mobileRegx = /^[6789][0123456789]{9}$/;
    const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z][a-z.A-Z]+$/;

    if (sendGrade.length === 0) {
      error.grade = "Please select grade";
    }

    if (values.stu_fname === "") {
      error.stu_fname = "Please enter first name.";
    } else if (studName.test(values.stu_fname) === false) {
      error.stu_fname = "Please enter valid first name.";
    }

    if (values.stu_mname === "") {
      error.stu_mname = "Please enter middle name.";
    } else if (studName.test(values.stu_mname) === false) {
      error.stu_mname = "Please enter valid middle name.";
    }
    if (values.stu_lname === "") {
      error.stu_lname = "Please enter last name.";
    } else if (studName.test(values.stu_lname) === false) {
      error.stu_lname = "Please enter valid last name.";
    }
    if (values.stu_father_contact === "") {
      error.stu_father_contact = "Please enter mobile no.";
    } else if (mobileRegx.test(values.stu_father_contact) === false) {
      error.stu_father_contact = "Please enter valid mobile no.";
    }

    if (values.email === "") {
      error.email = "Please enter email id.";
    } else if (emailRegx.test(values.email) === false) {
      error.email = "Please enter valid email id.";
    }
    if (values.stu_father_name === "") {
      error.stu_father_name = "Please enter parents name.";
    } else if (studName.test(values.stu_father_name) === false) {
      error.stu_father_name = "Please enter valid parents name.";
    }
    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors(validateForm(sendData));

    var token = localStorage.getItem("authToken");
    if (Object.keys(validateForm(sendData)).length === 0) {
      setIsLoading(true);
      try {
        let res = await fetch(POST_ADD_STUDENT, {
          method: "POST",
          headers: {
            authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
            schoolId: `${sch_id}`,
          },
          body: JSON.stringify({
            stu_fname: sendData.stu_fname,
            stu_mname: sendData.stu_mname,
            stu_lname: sendData.stu_lname,
            stu_grade: sendGrade,
            stu_father_contact: sendData.stu_father_contact,
            email: sendData.email,
          }),
        });
        let resJson = await res.json();
        const msg = resJson.message;

        if (msg === "Student created successfully") {
          toast.success("Student added successfully", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => {
              setIsLoading(false);
              setTimeout(window.location.reload(), 50);
              reloadTableData();
            },
          });
        } else if (msg === "User with this email already exists.") {
          setIsLoading(false);
          toast.error("Student with this email already exists.", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else {
          setIsLoading(false);
          setErrors(validateForm(sendData));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  $(document).ready(function () {
    $(".gradeCircle").click(function () {
      $(".gradeCircle").removeClass("active");
      $(this).addClass("active");
    });
  });

  // const vanishData = () => {
  //   setErrors({});
  //   setSendGrade(null);
  //    $('.addStudent input[type="text"]').val('');
  //    $('.gradeSelect').val('');
  // };

  const vanishData = () => {
    // reloadTableData();

    setErrors("");
  };

  return (
    <>
      <button
        type="button"
        className="btn addStudentBtn"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropAddStudent"
      >
        Add Student
        <img src={plusIcon} alt="plusIcon" className="plusIcon ms-2" />
      </button>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="staticBackdropAddStudent"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <p className="modal-title">Add Student</p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={vanishData}
              ></button>
            </div>

            {sch_id ? (
              <>
                <div className="row container addStudent ms-1">
                  <input
                    type="text"
                    placeholder="First name"
                    name="stu_fname"
                    onChange={handleChange}
                  />
                  <span className="error_msg">{errors.stu_fname}</span>{" "}
                  <input
                    type="text"
                    placeholder="Middle name"
                    name="stu_mname"
                    onChange={handleChange}
                  />
                  <span className="error_msg">{errors.stu_mname}</span>{" "}
                  <input
                    type="text"
                    placeholder="Last name"
                    name="stu_lname"
                    onChange={handleChange}
                  />
                  <span className="error_msg">{errors.stu_lname}</span>{" "}
                  <div className="grade_selection modal-body">
                    <div className="row">
                      {gradeList.map((grade, ind) => {
                        return (
                          <div
                            className="col-sm-2 col-2 col-lg-2 text-center gradeSelect"
                            style={{ cursor: "pointer" }}
                            key={ind}
                            onClick={() => setSendGrade(ind + 1)}
                          >
                            <p className="gradeCircle">{grade}</p>
                          </div>
                        );
                      })}
                    </div>
                    <span className="error_msg">{errors.grade}</span>
                  </div>{" "}
                  <input
                    type="text"
                    placeholder="Parent mobile no"
                    name="stu_father_contact"
                    onChange={handleChange}
                    maxLength={10}
                    minLength={10}
                  />
                  <span className="error_msg">{errors.stu_father_contact}</span>{" "}
                  <input
                    type="text"
                    placeholder="Email id"
                    name="email"
                    onChange={handleChange}
                  />
                  <span className="error_msg">{errors.email}</span>
                </div>
                <div className="text-center my-5">
                  {isLoading && (
                    <div className="simple-loader">
                      <SimpleLoader />
                    </div>
                  )}
                  <input
                    type="submit"
                    className="submit_btn"
                    value="Add"
                    onClick={handleSubmit}
                    id="submit_btn"
                  />
                </div>
              </>
            ) : (
              <div className="m-auto p-5">
                <div className="noData">School not allocated yet</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStudent;
