import "../Assets/Styles/Sidebar.css";
import phnElearnImg from "../Assets/Images/Login/phn-elearn.webp";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import $ from "jquery";
const Sidebar = (props) => {
  const [isLogin, setIsLogin] = useState(0);
  let navigator = useNavigate();
  const sidebarNavLinks = props.sidebarLinks;
  if (isLogin === 1) {
    localStorage.removeItem("authToken");
    localStorage.removeItem("activeGrade");
    localStorage.removeItem("courseChapterActive");
    localStorage.removeItem("allChapterProjectActive");
    localStorage.removeItem("schID");
    navigator("/");
    window.location.reload();
  }
  const location = useLocation();

  // active code
  $(document).ready(function () {
    const user_role = localStorage.getItem("user_role");
    if (user_role === "Trainer") {
      if (
        location.pathname === "/particularchaptervideo" ||
        location.pathname === "/allparticularchapter" ||
        location.pathname === "/allparticularcourse" ||
        location.pathname === "/allloginproject"
      ) {
        $(
          "#navbarSupportedContent > ul > li:nth-child(2) > a:nth-child(4)"
        ).addClass("active");
      } else {
        $(".for_active").removeClass("active");
      }
    } else if (user_role === "Manager" || user_role === "Principal") {
      if (
        location.pathname === "/particularchaptervideo" ||
        location.pathname === "/allparticularchapter" ||
        location.pathname === "/allparticularcourse" ||
        location.pathname === "/allloginproject"
      ) {
        $(
          "#navbarSupportedContent > ul > li:nth-child(2) > a:nth-child(3)"
        ).addClass("active");
      } else {
        $(".for_active").removeClass("active");
      }
    }

    if (
      location.pathname === "/singleVideoStudent" ||
      location.pathname === "/singleVideoPrincipal" ||
      location.pathname === "/manager/singleVideo" ||
      location.pathname === "/manager/createAssessment" ||
      location.pathname === "/student/courseparticularchapter" ||
      location.pathname === "/particularchaptervideo" ||
      location.pathname === "/student/particular-project"
    ) {
      $(
        "#navbarSupportedContent > ul > li:nth-child(2) > a:nth-child(3)"
      ).addClass("active");
    } else {
      $(".for_active").removeClass("active");
    }
    if (
      location.pathname === "/trainer/school-profile" ||
      location.pathname === "/manager/addsuperadmin" ||
      location.pathname === "/manager/editsuperadmin"
    ) {
      $(
        "#navbarSupportedContent > ul > li:nth-child(2) > a:nth-child(5)"
      ).addClass("active");
    } else {
      $(".for_active").removeClass("active");
    }

    if (
      location.pathname === "/specificTrainerProfile" ||
      location.pathname === "/manager/specificSchool" ||
      location.pathname === "/singleVideo" ||
      location.pathname === "/student/edit-profile"
    ) {
      $(
        "#navbarSupportedContent > ul > li:nth-child(2) > a:nth-child(4)"
      ).addClass("active");
    } else {
      $(".for_active").removeClass("active");
    }
    if (
      location.pathname === "/manager/editschool" ||
      location.pathname === "/manager/addschool" ||
      location.pathname === "/superadmin/trainerschooldetails" ||
      location.pathname === "/studentAttendance"
    ) {
      $(
        "#navbarSupportedContent > ul > li:nth-child(2) > a:nth-child(2)"
      ).addClass("active");
    } else {
      $(".for_active").removeClass("active");
    }
  });

  return (
    <>
      <section className="sidebar">
        <nav className="navbar navbar-expand-lg navbar-light">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse sidebar"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto flex-column vertical-nav">
              <Link className="navbar-brand mb-3" to="">
                <img src={phnElearnImg} alt="phnElearnImg" className="w-75" />
              </Link>
              <li className="nav-item">
                {sidebarNavLinks.map((values, ind) => {
                  const link = values;
                  return (
                    <NavLink
                      className="nav-link for-active"
                      to={values.navLink}
                      key={ind}
                    >
                      <span className="d-flex design_component">
                        <img
                          src={values.navLogo}
                          alt=""
                          className="me-2 ms-1"
                        />
                        {values.navLinkTitle}
                      </span>
                    </NavLink>
                  );
                })}
              </li>
              <span className="logoutSpan my-auto"></span>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ borderTop: "1px solid gray" }}
                >
                  <img
                    src={require("../Assets/Images/Sidebar/logout-logo.png")}
                    className="me-3 ms-2"
                    alt="logoutLogo"
                  />
                  Log Out
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </section>
      {/* <!-- Modal --> */}

      <div
        className="modal fade "
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content deleteSchoolModal">
            <div className="modal-header">
              <p className="deleteTitle">Logout</p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <p className="deleteMsg ms-3">Are you sure you want to Logout </p>

            <div className="row mt-3 mb-3">
              <div className="col-lg-6 col-sm-12 col-md-6">
                <button className="btn yes_btn" onClick={() => setIsLogin(1)}>
                  Yes
                </button>
              </div>

              <div className="col-lg-6 col-sm-12 col-md-6">
                <button
                  className="btn no_btn"
                  data-bs-dismiss="modal"
                  // onClick={hideEditModal}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
