import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import { API_START_POINT } from "../Components/AllAPI";
import { GET_MGR_SUPER_ADMIN_SCH_LIST_API } from "../Components/AllAPI";
import defaultSchoolLogo from "../Assets/Images/Dashboard/school_logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DayDateProfile from "../Components/DayDateProfile";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";

const EditSuperAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // console.log("state", location.state?.data);
  const preData = location.state?.data;
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [schools, setSchools] = useState([]);
  const [originalSchools, setOriginalSchools] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState(
    preData ? preData.associated_schools.map((school) => school.sch_id) : []
  );
  const [errors, setErrors] = useState({});
  const [hoveredSchool, setHoveredSchool] = useState(null);
  const [checkboxValidationMessage, setCheckboxValidationMessage] =
    useState("");

  useEffect(() => {
    if (preData && preData.associated_schools) {
      const preselectedSchoolIds = preData.associated_schools.map(
        (schoolData) => schoolData.sch_id
      );
      setSelectedSchools(preselectedSchoolIds);
    }
  }, [preData]);

  let managerSidebar = sidebarData.Manager;
  var token = localStorage.getItem("authToken");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await fetch(GET_MGR_SUPER_ADMIN_SCH_LIST_API, {
          method: "GET",
          headers: {
            Authorization: `bearer ${token}`,
          },
        });
        // console.log(response)
        if (!response.ok) {
          throw new Error("Failed to fetch schools data");
        }
        const data = await response.json();
        const mergedSchools = [
          ...data,
          ...(preData ? preData.associated_schools : []),
        ];
        setOriginalSchools(mergedSchools);
        const filteredSchools = data.filter(
          (school) => !selectedSchools.includes(school.sch_id)
        );
        setSchools(filteredSchools);
        // setSchools(data);
      } catch (error) {
        console.error("Error fetching schools data:", error);
      }
    };

    fetchSchools();
  }, [selectedSchools]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    const filteredSchools = originalSchools.filter(
      (school) =>
        school.sch_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        school.sch_district
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setSchools(filteredSchools);
  };

  const handleCheckboxChange = (school) => {
    setCheckboxValidationMessage("");
    const updatedSelectedSchools = [...selectedSchools];
    const index = updatedSelectedSchools.indexOf(school.sch_id);
    if (index === -1) {
      updatedSelectedSchools.push(school.sch_id);
    } else {
      updatedSelectedSchools.splice(index, 1);
    }
    setSelectedSchools(updatedSelectedSchools);
  };

  const handleRemoveSchool = async (schoolId) => {
    try {
      const response = await fetch(
        `${API_START_POINT}/super-admin/${preData.su_adm_id}/school/${schoolId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          //   body: JSON.stringify(),
        }
      );
      if (response.ok) {
        // console.log("Data deleted Successfully");
        // console.log("response: ", response.json());
      }
    } catch (err) {
      console.error(`Error! ${err}`);
    }

    const updatedSelectedSchools = selectedSchools.filter(
      (id) => id !== schoolId
    );
    setSelectedSchools(updatedSelectedSchools);

    const updatedSchools = schools.filter(
      (school) => school.sch_id !== schoolId
    );
    setSchools(updatedSchools);
  };

  const handleCancel = () => {
    // const preselectedSchoolIds = preData
    //   ? preData.associated_schools.map((school) => school.sch_id)
    //   : [];
    // const filteredSchools = originalSchools.filter(
    //   (school) => !preselectedSchoolIds.includes(school.sch_id)
    // );
    // setSchools([...filteredSchools, ...preData.associated_schools]);
    // setSelectedSchools(preselectedSchoolIds);
    navigate("/manager/superadmindetails");
  };

  const selectedSchoolsString = selectedSchools.join(",");

  const handleAddAdmin = async (e) => {
    e.preventDefault();
    var token = localStorage.getItem("authToken");

    if (selectedSchools.length === 0) {
      setCheckboxValidationMessage("Please select at least one school.");
    } else {
      try {
        let res = await fetch(
          `${API_START_POINT}/suadmin/update/${preData.su_adm_id}`,

          {
            method: "POST",
            headers: {
              authorization: "Bearer " + token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              // Send the comma-separated string in the request body
              su_adm_schools: selectedSchoolsString,
            }),
          }
        );
        let resJson = await res.json();
        const msg = resJson.message;
        // console.log("msg", msg);
        if (msg === "Super admin information updated successfully") {
          toast.success("Super Admin details updated successfully", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => {
              navigate("/manager/superadmindetails");
            },
          });
        } else {
          setCheckboxValidationMessage("Please select at least one school.");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <Sidebar sidebarLinks={managerSidebar} />
      <div className="sidebody">
        <div className="container detail-section">
          <div>
            {/* <DayDateProfile /> */}
            <p className="adminDetailHeader-text mt-3">Edit Admin Details</p>
            <ToastContainer
              position="top-center"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
            <div className="adminDetail-inputs-main">
              <div className="adminDetail-inputs">
                <input
                  type="text"
                  placeholder="Super Admin Name*"
                  className="adminInputField"
                  name="superAdminName"
                  value={preData.su_adm_name || ""}
                  disabled={true}
                />
              </div>

              <div className="dropdown card adminInputDropdown-card mb-0">
                <div className="card-body adminDropdown_card-body">
                  <div
                    className="adminDropdownField "
                    onClick={toggleDropdown}
                    style={{ border: "none", marginBottom: "0px" }}
                  >
                    Select Schools*
                    <p className={`icon ${isOpen ? "iconup" : ""}`}>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="fa-chevron-up "
                      />
                    </p>
                  </div>
                  {isOpen && (
                    <div className="adminDetail-dropdown-content">
                      <div className="dropdown-divider"></div>
                      <input
                        type="search"
                        placeholder="Search Schools"
                        className="searchInput adminDropdown-search"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      {schools.length === 0 ? (
                        <div className="text-center my-4">
                          <p className="no-data-message">No data found.</p>
                        </div>
                      ) : (
                        <div className="adminSchoolList">
                          <ul className="list-group">
                            {schools.map((school, index) => (
                              <li
                                className="list-group-item adminlist-group-item"
                                key={index}
                              >
                                <div className="d-flex adminSchoolListingDiv">
                                  <div className="d-flex">
                                    <img
                                      src={
                                        school.sch_logo
                                          ? school.sch_logo
                                          : defaultSchoolLogo
                                      }
                                      alt="logo"
                                      style={{
                                        width: "22px",
                                        height: "22px",
                                      }}
                                      className="admin_DropdownList_logo"
                                    />
                                    <p className="schoolList-schoolname">
                                      {school.sch_name}
                                    </p>
                                  </div>
                                  <div className="d-flex">
                                    <p className="schoolList-districtname">
                                      {school.sch_district}
                                    </p>
                                    <input
                                      type="checkbox"
                                      checked={selectedSchools.includes(
                                        school.sch_id
                                      )}
                                      className="form-check-input adminSchoolListCheckbox"
                                      style={{
                                        width: "24px",
                                        height: "24px",
                                        margin: "0",
                                      }}
                                      onChange={() =>
                                        handleCheckboxChange(school)
                                      }
                                    />
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {checkboxValidationMessage && (
                <p className="error-msg mt-1">{checkboxValidationMessage}</p>
              )}
            </div>
            <div style={{ width: "100%" }}>
              {selectedSchools.length !== 0 && (
                <p className="adminSelectedSchool-text mt-3">
                  Selected Schools
                </p>
              )}

              <div className="row w-100 row-cols-auto">
                {selectedSchools &&
                  selectedSchools.map((schoolId, index) => (
                    <div
                      className="col col-lg-4 col-md-6 col-sm-12"
                      key={index}
                    >
                      <div className="card adminSelectedSchool">
                        <div
                          className="card-body adminSelectedSchool_card-body"
                          onMouseEnter={() => setHoveredSchool(schoolId)}
                          onMouseLeave={() => setHoveredSchool(null)}
                        >
                          <div className="d-flex">
                            <img
                              src={
                                originalSchools.find(
                                  (school) => school.sch_id === schoolId
                                )?.sch_logo || defaultSchoolLogo
                              }
                              alt="logo"
                              style={{
                                width: "36px",
                                height: "36px",
                              }}
                              className="adminDetail_school-logo"
                            />

                            <p className="selectedSchoolName">
                              {
                                originalSchools.find(
                                  (school) => school.sch_id === schoolId
                                )?.sch_name
                              }
                            </p>
                          </div>
                          {schoolId && (
                            <p style={{ margin: "6px" }}>
                              <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() => handleRemoveSchool(schoolId)}
                                style={{ cursor: "pointer", color: "red" }}
                              />
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="next_prev_button_div">
            <button
              type="button"
              className="btn cancel-btn-admin"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn add-btn-admin"
              onClick={handleAddAdmin}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSuperAdmin;
