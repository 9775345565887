import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

import exportIcon from "./Assets/Images/StudentList/exportIcon.svg";

function Excelexport({ excelData, fileName }) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async () => {
    if (!excelData || excelData.length === 0) {
      alert("No data to export.");
      return;
    }

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    // <button type="button" class="btn btn-primary" >Export Csv</button>
    <button
      type="button"
      className="btn exportBtn ps-2 me-2"
      onClick={(e) => exportToCSV(fileName)}
    >
      Export
       <img src={exportIcon} alt="exportIcon" className="ms-2" />
    </button>
  );
}

export default Excelexport;
