import React, { useEffect, useState } from "react";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import LottieAnimation from "../Components/LottieAnimation";
import { Link, useLocation } from "react-router-dom";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import profileImg from "../Assets/Images/StudentList/student_profile.svg";
import nextArrow from "../Assets/Images/Dashboard/next_icon.png";
import { API_START_POINT } from "../Components/AllAPI";
const SuperAdminTrainerSchoolDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [progressCount, setProgressCount] = useState(0);
  const [chartData, setChartData] = useState(0);
  const [profileDetails, setProfileDetails] = useState("");
  const location = useLocation();
  const sch_id = location.state;
  // console.log(sch_id);
  let superAdminSidebar = sidebarData.SuperAdmin;

  useEffect(() => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(`${API_START_POINT}/suadmin/school/${sch_id}`, {
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("schools list", json);
        setProgressCount(json.counts);
        setChartData(json.monthlyAttendance);
        setProfileDetails(json.schoolInfo[0]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const emptyObj = Object.keys(profileDetails).length === 0;
  // console.log(emptyObj);

  return (
    <>
      <Sidebar sidebarLinks={superAdminSidebar} />

      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          <section className="sidebody">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-12">
                  <div className="mt-3 mb-4">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            to="/superadmin/schoollist"
                            className="prevPageTitle"
                          >
                            Schools
                          </Link>
                        </li>{" "}
                        <img src={nextArrow} alt="nextArrow" />
                        <p
                          className="currentPageTitle mt-0 mb-0"
                          style={{ color: "#211C37" }}
                        >
                          {profileDetails.sch_name === null || ""
                            ? "-"
                            : profileDetails.sch_name}
                        </p>
                      </ol>
                    </nav>
                  </div>

                  {/* //progrss */}

                  <div className="row mt-3">
                    <div className="col-lg-4 col-12">
                      <div className="card avg_completion">
                        <p className="totalNo mb-1">
                          {progressCount.totalStudents === 0 ||
                          progressCount.totalStudents === undefined
                            ? "0"
                            : progressCount.totalStudents}
                        </p>
                        <p
                          className="totalTitle"
                          style={{ fontWeight: "bolder" }}
                        >
                          Total Students
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div
                        className="card avg_completion"
                        style={{ backgroundColor: "#EDF3FC" }}
                      >
                        <p className="totalNo mb-1">
                          {" "}
                          {progressCount.average_progress === 0 ||
                          progressCount.average_progress === undefined
                            ? "0"
                            : progressCount.average_progress.toFixed(2)}
                          %
                        </p>
                        <p
                          className="totalTitle"
                          style={{ fontWeight: "bolder" }}
                        >
                          Avg. Course Completion
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div
                        className="card avg_completion"
                        style={{ backgroundColor: "#FFF4F0" }}
                      >
                        <p className="totalNo mb-1">
                          {progressCount.average_attendance === 0 ||
                          progressCount.average_attendance === undefined
                            ? "0"
                            : progressCount.average_attendance.toFixed(2)}
                          %
                        </p>
                        <p
                          className="totalTitle"
                          style={{ fontWeight: "bolder" }}
                        >
                          Avg. Attendance
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <Charts /> */}
                  <>
                    <p className="chart_title mt-5">Student Attendance</p>

                    <div className="container mt-5 ">
                      <ResponsiveContainer aspect={2}>
                        <BarChart data={chartData}>
                          <XAxis dataKey="month" />
                          <YAxis
                            tickFormatter={(tick) => `${tick}%`}
                            domain={[0, 100]}
                          />
                          {/* <Tooltip /> */}
                          <Bar
                            dataKey="average"
                            fill="rgba(250, 95, 28, 1)"
                            radius={[20, 20, 0, 0]}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </>
                  <br />
                  <br />
                  <div></div>
                </div>
                <div
                  className="col-lg-4 col-md-12 col-12 mt-5 pt-5"
                  style={{ borderLeft: "2px solid #F3F5F7" }}
                >
                  {/* <Profile /> */}

                  <section className="profile">
                    <div className="container">
                      <p className="profile_title ">
                        School Profile
                      </p>
                      <div className="">
                        {!profileDetails.sch_logo && (
                          <img
                            src={profileImg}
                            alt="Placeholder"
                            style={{ borderRadius: "50px" }}
                          />
                        )}
                        {profileDetails.sch_logo && (
                          <img
                            src={profileDetails.sch_logo}
                            alt="profile_image"
                            width={100}
                            height={100}
                            style={{ borderRadius: "50px" }}
                          />
                        )}
                      </div>
                      <p className="student_name">
                        {profileDetails.sch_name === null || ""
                          ? "-"
                          : profileDetails.sch_name}
                      </p>

                      <div className="stud_data">
                        <div className="row">
                          <div className="col-6">
                            <p className="data_title mb-0">Trainer</p>
                            <p className="data_subititle ">
                              {profileDetails.tra_name === null || ""
                                ? "-"
                                : profileDetails.tra_name}
                            </p>
                          </div>

                          <div className="col-6">
                            <p className="data_title mb-0">Trainer Mobile</p>
                            <p className="data_subititle">
                              {profileDetails.tra_phone === null || ""
                                ? "-"
                                : profileDetails.tra_phone}
                            </p>
                          </div>
                          <br />
                          <div className="col-12">
                            <p className="data_title mb-0">Trainer Email Id</p>
                            <p className="data_subititle">
                              {profileDetails.tra_email === null || ""
                                ? "-"
                                : profileDetails.tra_email}
                            </p>
                          </div>
                        </div>
                        <hr
                          style={{
                            color: "rgba(231, 230, 234, 1)",
                            width: "100%",
                          }}
                        />
                        <div className="row">
                          <div className="col-12">
                            <p className="data_title mb-0">POC</p>
                            <p className="data_subititle">
                              {profileDetails.sch_poc_name === null || ""
                                ? "-"
                                : profileDetails.sch_poc_name}
                            </p>
                          </div>

                          <div className="col-6">
                            <p className="data_title mb-0">POC Mobile</p>
                            <p className="data_subititle">
                              {profileDetails.sch_poc_contact === null || ""
                                ? "-"
                                : profileDetails.sch_poc_contact}
                            </p>
                          </div>

                          <div className="col-6">
                            <p className="data_title mb-0">POC Email Id</p>
                            <p className="data_subititle">
                              {profileDetails.sch_poc_email === null || ""
                                ? "-"
                                : profileDetails.sch_poc_email}
                            </p>
                          </div>
                        </div>
                        <hr
                          style={{
                            color: "rgba(231, 230, 234, 1)",
                            width: "100%",
                          }}
                        />
                        <div className="row">
                          <div className="col-6">
                            <p className="data_title mb-0">Location</p>
                            <p className="data_subititle">
                              {profileDetails.sch_district === null || ""
                                ? "-"
                                : profileDetails.sch_district}
                            </p>
                          </div>
                          <br />
                          <br />

                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default SuperAdminTrainerSchoolDetails;
