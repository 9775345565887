import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import TrainerDashboard from "../src/TrainerPages/TrainerDashboard.jsx";
import PrincipleDashboard from "../src/PrinciplePages/PrincipleDashboard.jsx";
import StudentDashboard from "../src/StudentPages/StudentDashboard.jsx";
import Login from "./Pages/Login";
import StudentsListData from "./StudentPages/StudentsListData.jsx";
import AttendanceList from "./Components/AttendanceList.jsx";
import Charts from "./Components/Charts.jsx";
import StudentsPrincipleListData from "./StudentPages/StudentsPrincipleListData.jsx";
import Calendar from "./StudentPages/Calendar.jsx";
import ManagerDashboard from "./ManagerPages/ManagerDashboard.jsx";
import SchoolsList from "./ManagerPages/SchoolsList.jsx";
import TrainersList from "./ManagerPages/TrainersList.jsx";
import AddSchool from "./ManagerPages/AddSchool.jsx";
import AttendData from "./Components/AttendData.jsx";
import EditSchool from "./ManagerPages/EditSchool.jsx";
import BulkImport from "./ManagerPages/BulkImport.jsx";
import SchoolProfile from "./PrinciplePages/SchoolProfile.jsx";
import EditStudentProfile from "./StudentPages/EditStudentProfile.jsx";
import AllProfilesStudent from "./StudentPages/AllProfilesStudent.jsx";
import { useState } from "react";
import SchoolDropdown from "./TrainerPages/SchoolDropdown.jsx";
import StudentListStructure from "./Components/StudentListStructure.jsx";
import TrainerProfile from "./TrainerPages/TrainerProfile.jsx";
import SpecificSchoolProfile from "./TrainerPages/SpecificSchoolProfile.jsx";
import SingleTrainerProfile from "./ManagerPages/SingleTrainerProfile.jsx";
import SingleSchoolTrainer from "./ManagerPages/SingleSchoolTrainer.jsx";
import Syllabus from "./StudentPages/Syllabus.jsx";
import SyllabusChapterOne from "./StudentPages/SyllabusProjectOne.jsx";
import NoInternetConnection from "./Components/NoInternet.jsx";
import SingleChapter from "./StudentPages/SingleChapter.jsx";
import CoursesList from "./StudentPages/CoursesList.jsx";
import CourseParticularChapter from "./StudentPages/CourseParticularChapter.jsx";
import StudentAssessment from "./StudentPages/StudentAssessment.jsx";
import AssessmentCompletion from "./StudentPages/AssessmentCompletion.jsx";
import AllCoursesList from "./Components/AllCourseList.jsx";
import AllParticularCourse from "./Components/AllParticularCourse.jsx";
import AllParticularChapter from "./Components/AllParticularChapter.jsx";
import AllCourseVideoList from "./Components/AllCourseVideoList.jsx";
import ForgotPassword from "./Pages/ForgotPassword.jsx";
import ResetPassword from "./Pages/ResetPassword.jsx";
import SuperAdminDashboard from "./SuperAdmin/SuperAdminDashboard.jsx";
import LeaderBoard from "./SuperAdmin/LeaderBoard.jsx";
import SchoolListSuperAdmin from "./SuperAdmin/SchoolListSuperAdmin.jsx";
import TrainerListSuperAdmin from "./SuperAdmin/TrainerListSuperAdmin.jsx";
import ManagerSuperAdminDetails from "./ManagerPages/ManagerSuperAdminDetails.jsx";
import AddSuperAdmin from "./ManagerPages/AddSuperAdmin.jsx";
import EditSuperAdmin from "./ManagerPages/EditSuperAdmin.jsx";
import SuperAdminTrainerSchoolDetails from "./SuperAdmin/SuperAdminTrainerSchoolDetails.jsx";
import AllLoginProject from "./Components/AllLoginProject.jsx";
import StudentsAttendanceInCalendar from "./StudentPages/StudentsAttendanceInCalendar.jsx";
function App() {
  const [schoolId, setSchoolId] = useState();
  const sendId = (id) => {
    setSchoolId(id);
  };

  const location = useLocation();
  const user_role = localStorage.getItem("user_role");

  if (
    location.pathname === "/trainerdashboard" ||
    location.pathname === "/trainer/studentlist" ||
    location.pathname === "/trainer/attendancelist" ||
    location.pathname === "/trainer/profile"
  ) {
    return (
      <>
        <div className="App">
          <NoInternetConnection>
            <div class="container">
              <div className="schoolDropdown">
                <SchoolDropdown sendId={sendId} />
              </div>
            </div>

            <Routes>
              <Route exact path="/" element={<Login />}></Route>
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              ></Route>
              <Route
                exact
                path="/reset-password/*"
                element={<ResetPassword />}
              ></Route>
              <Route
                exact
                path="/trainer/profile"
                element={<TrainerProfile />}
              ></Route>
              <Route
                exact
                path="/trainer/school-profile"
                element={<SpecificSchoolProfile />}
              ></Route>
              <Route
                exact
                path="/schoolDropdown"
                element={<SchoolDropdown sendId={sendId} />}
              ></Route>

              <Route
                exact
                path="/trainerdashboard"
                element={<TrainerDashboard schoolsId={schoolId} />}
              ></Route>
              <Route
                exact
                path="/trainer/studentlist"
                element={<StudentsListData schoolsId={schoolId} />}
              ></Route>
              <Route
                exact
                path="/studentListStructure"
                element={<StudentListStructure />}
              ></Route>
              <Route
                exact
                path="/trainer/attendancelist"
                element={<AttendanceList schoolsId={schoolId} />}
              ></Route>

              <Route exact path="/chart" element={<Charts />}></Route>

              <Route exact path="/schoolList" element={<SchoolsList />}></Route>
              <Route
                exact
                path="/trainerList"
                element={<TrainersList />}
              ></Route>
              <Route
                exact
                path="/allCourseList"
                element={<AllCoursesList />}
              ></Route>
              <Route
                exact
                path="/allparticularcourse"
                element={<AllParticularCourse />}
              ></Route>
              <Route
                exact
                path="/allparticularchapter"
                element={<AllParticularChapter />}
              ></Route>
              <Route
                exact
                path="/allcoursevideolist"
                element={<AllCourseVideoList />}
              ></Route>
              <Route
                exact
                path="/allloginproject"
                element={<AllLoginProject />}
              ></Route>
              <Route
                exact
                path="/studentAttendance"
                element={<StudentsAttendanceInCalendar />}
              ></Route>
            </Routes>
          </NoInternetConnection>
        </div>
      </>
    );
  } else {
    return (
      <div className="App">
        <NoInternetConnection>
          <Routes>
            <Route exact path="/" element={<Login />}></Route>
            <Route
              exact
              path="/forgot-password"
              element={<ForgotPassword />}
            ></Route>
            <Route
              exact
              path="/reset-password/*"
              element={<ResetPassword />}
            ></Route>
            <Route exact path="/attend" element={<AttendData />}></Route>
            <Route exact path="/newbulkupload" element={<BulkImport />}></Route>
            <Route
              exact
              path="/specificTrainerProfile"
              element={<SingleTrainerProfile />}
            ></Route>
            <Route
              exact
              path="/manager/specificSchool"
              element={<SingleSchoolTrainer />}
            ></Route>
            <Route
              exact
              path="/student/syllabus"
              element={<Syllabus />}
            ></Route>
            <Route
              exact
              path="/student/particular-project"
              element={<SyllabusChapterOne />}
            ></Route>

            <Route
              exact
              path="/trainer/profile"
              element={<TrainerProfile />}
            ></Route>
            <Route
              exact
              path="/trainer/school-profile"
              element={<SpecificSchoolProfile />}
            ></Route>
            <Route
              exact
              path="/schoolDropdown"
              element={<SchoolDropdown sendId={sendId} />}
            ></Route>
            <Route
              exact
              path="/student/studentprofiledetails"
              element={<AllProfilesStudent />}
            ></Route>

            <Route
              exact
              path="/studentdashboard"
              element={<StudentDashboard />}
            ></Route>

            <Route
              exact
              path="/studentListData"
              element={<StudentsListData />}
            ></Route>
            <Route
              exact
              path="/trainer/attendancelist"
              element={<AttendanceList />}
            ></Route>
            <Route
              exact
              path="/principaldashboard"
              element={<PrincipleDashboard />}
            ></Route>
            <Route exact path="/chart" element={<Charts />}></Route>
            <Route
              exact
              path="/principal/school-profile"
              element={<SchoolProfile />}
            ></Route>
            <Route
              exact
              path="/student/edit-profile"
              element={<EditStudentProfile />}
            ></Route>
            <Route
              exact
              path="/principal/student"
              element={<StudentsPrincipleListData />}
            ></Route>
            <Route
              exact
              path="/studentAttendance"
              element={<StudentsAttendanceInCalendar />}
            ></Route>

            <Route exact path="/schoolList" element={<SchoolsList />}></Route>
            <Route exact path="/trainerList" element={<TrainersList />}></Route>

            <Route
              exact
              path="/student/attendance"
              element={<Calendar />}
            ></Route>
            <Route
              exact
              path="/managerdashboard"
              element={<ManagerDashboard Role={"Manager"} />}
            ></Route>
            <Route
              exact
              path="/manager/schoollist"
              element={<SchoolsList />}
            ></Route>

            <Route
              exact
              path="/manager/trainerlists"
              element={<TrainersList />}
            ></Route>
            <Route
              exact
              path="/manager/addschool"
              element={<AddSchool />}
            ></Route>

            <Route
              exact
              path="/manager/editschool"
              element={<EditSchool />}
            ></Route>
            <Route
              exact
              path="/particularchaptervideo"
              element={<SingleChapter />}
            ></Route>
            <Route
              exact
              path="/student/courseslist"
              element={<CoursesList />}
            ></Route>
            <Route
              exact
              path="/student/courseparticularchapter"
              element={<CourseParticularChapter />}
            ></Route>
            <Route
              exact
              path="/student/studentassessment"
              element={<StudentAssessment />}
            ></Route>
            <Route
              exact
              path="/student/assessmentcompletion"
              element={<AssessmentCompletion />}
            ></Route>
            <Route
              exact
              path="/allCourseList"
              element={<AllCoursesList />}
            ></Route>
            <Route
              exact
              path="/allparticularcourse"
              element={<AllParticularCourse />}
            ></Route>
            <Route
              exact
              path="/allparticularchapter"
              element={<AllParticularChapter />}
            ></Route>
            <Route
              exact
              path="/allcoursevideolist"
              element={<AllCourseVideoList />}
            ></Route>
            <Route
              exact
              path="/super_admindashboard"
              element={<SuperAdminDashboard />}
            ></Route>

            <Route
              exact
              path="/superadmin/leader-board"
              element={<LeaderBoard />}
            ></Route>
            <Route
              exact
              path="/superadmin/profile"
              element={<SuperAdminDashboard />}
            ></Route>
            <Route
              exact
              path="/superadmin/schoollist"
              element={<SchoolListSuperAdmin />}
            ></Route>
            <Route
              exact
              path="/superadmin/trainerlist"
              element={<TrainerListSuperAdmin />}
            ></Route>
            <Route
              exact
              path="/manager/superadmindetails"
              element={<ManagerSuperAdminDetails />}
            ></Route>
            <Route
              exact
              path="/manager/addsuperadmin"
              element={<AddSuperAdmin />}
            ></Route>
            <Route
              exact
              path="/manager/editsuperadmin"
              element={<EditSuperAdmin />}
            ></Route>
            <Route
              exact
              path="/superadmin/trainerschooldetails"
              element={<SuperAdminTrainerSchoolDetails />}
            ></Route>
            <Route
              exact
              path="/allloginproject"
              element={<AllLoginProject />}
            ></Route>
          </Routes>
        </NoInternetConnection>
      </div>
    );
  }
}
export default App;
