import React, { useEffect, useState } from "react";
import { GET_STUDENT_COMPLETION } from "../Components/AllAPI";

const StudentCompletion = () => {
  const [progress, setProgress] = useState([]);

 
  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_STUDENT_COMPLETION, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setProgress(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <p className="chart_title mt-5">Course Report</p>
      <div className="row mt-3">
        <div className="col-lg-6 col-12 ">
          <div
            className="card avg_completion pt-4"
            style={{ backgroundColor: "#EDF3FC" }}
          >
            <p className="totalNo mb-1">
              {/* {Math.round(progress.courseCompletion)}% */}

              {progress && progress.courseCompletion !== undefined
                ? Math.round(progress.courseCompletion) + "%"
                : "0"}
            </p>
            <p className="totalTitle" style={{ fontWeight: "bolder" }}>
              Avg. Progress
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div
            className="card avg_completion pt-4"
            style={{ backgroundColor: "#FFF4F0" }}
          >
            <p className="totalNo mb-1">
              {progress && progress.averageAttendance !== undefined
                ? progress.averageAttendance.toFixed(2) + "%"
                : "0"}
            </p>

            <p className="totalTitle" style={{ fontWeight: "bolder" }}>
              Avg. Attendance
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentCompletion;
