import React, { useEffect, useState } from "react";
import $ from "jquery";
import dropdown_arrow from "../Assets/Images/Dashboard/dropdown_arrow.png";
import LottieAnimation from "../Components/LottieAnimation";
import { GET_SCHOOL_DROPDOWN } from "../Components/AllAPI";

const SchoolDropdown = ({ sendId }) => {
  const [allSchoolData, setAllSchoolData] = useState([]);
  const [schoolId, setSchoolId] = useState(localStorage.getItem("schID") || "");
  const [isLoading, setIsLoading] = useState(true);

  const getSchoolId = (e) => {
    const { value } = e.target;
    localStorage.setItem("schID", value);
    setSchoolId(value);
  };

  useEffect(() => {
    sendId(schoolId);
  }, [schoolId]);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_SCHOOL_DROPDOWN, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAllSchoolData(data);
        if (localStorage.getItem("schID")) {
          setSchoolId(localStorage.getItem("schID"));
        } else {
          setSchoolId(data[0].sch_id);
        }
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <LottieAnimation />
      ) : (
        <div className="selectSchool">
          <select value={schoolId} onChange={getSchoolId} className="border">
            {dropdown_arrow}
            {allSchoolData.map((list, ind) => {
              return (
                <option key={ind} value={list.sch_id}>
                  {list.sch_name}
                </option>
              );
            })}
          </select>
        </div>
      )}
    </>
  );
};

export default SchoolDropdown;
