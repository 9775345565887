import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import videoImg from "../Assets/Images/Dashboard/dummyVideoIcon.svg";
import tickIcon from "../Assets/Images/Dashboard/tick_icon_circled.svg";
import notPlayed from "../Assets/Images/Dashboard/playing_video.svg";
import videoPlayingIcon from "../Assets/Images/Dashboard/videoPaused.svg";
import assessmentClipboard from "../Assets/Images/Dashboard/assessment_clipboard.svg";
import textIcon from "../Assets/Images/Dashboard/text_data_icon.svg";

const AllCourseVideoList = (props) => {
  // let studentSidebar = sidebarData.Student;

  // console.log("chapter id", chapter_Id);
  let syllabus = props.syllabusData;

  // console.log("syllabus datta", syllabus);
  const location = useLocation();
  const videoList = syllabus.videos;

  const [isActive, setIsActive] = useState(null);

  const setActiveContent = (index) => {
    setIsActive(index);
    localStorage.setItem("ind", index);
  };

  useEffect(() => {
    const storedIndex = localStorage.getItem("ind");
    if (location.pathname === "/allparticularchapter") {
      if (storedIndex !== null) {
        setIsActive(parseInt(storedIndex));
      }
    }
  }, []);

  return (
    <>
      {/* <Sidebar sidebarLinks={studentSidebar} /> */}

      <div className="chapterVideos">
        {/* topics list */}
        <div className="Topics-Covered-List mt-4">
          <>
            {videoList.map((list, ind) => {
              // console.log("vlist", list.video.vid_path);
              // console.log("isactive", isActive);
              return (
                <Link
                  key={ind}
                  to="/allparticularchapter"
                  style={{ color: "black", textDecoration: "none" }}
                  state={{
                    videoData: list.video,
                    syllabus: syllabus,
                    ind: ind,
                  }}
                >
                  <div
                    onClick={() => setActiveContent(ind)}
                    className={
                      isActive === ind
                        ? "activeVideo Content-1 mt-2 w-100"
                        : "Content-1 mt-2 w-100 "
                    }
                  >
                    <div className="row w-100">
                      <div className="col-lg-2  my-auto">
                        {list.video.vid_path === null ||
                        list.video.vid_path === "" ? (
                          <img
                            src={assessmentClipboard}
                            alt="textIcon"
                            className="ms-3"
                          />
                        ) : list.video.vid_thumbnail === "" ||
                          list.video.vid_thumbnail === null ||
                          list.video.vid_thumbnail === "somepath" ? (
                          <img src={videoImg} alt="videoImg" width="55" className="ms-2"/>
                        ) : (
                          <img src={list.vid_thumbnail} alt="videoImg" />
                        )}
                      </div>
                      <div className="col-lg-8 ps-5 my-auto">
                        <div className="inner-topics">
                          {list.video.vid_title}
                          {/* <p className="videoTime">32:42</p>{" "} */}
                        </div>
                      </div>
                      <div className="col-lg-2  my-auto ">
                        {list.video.vid_path === null ||
                        list.vid_path === "" ? (
                          isActive === ind ? (
                            <img
                            src={textIcon}
                            alt="textIcon"
                            width={25}
                            className="ms-auto me-3"
                            style={{ float: "right" }}
                          />
                          ) : (
                            <img
                              src={textIcon}
                              alt="textIcon"
                              width={25}
                              className="ms-auto me-3"
                              style={{ float: "right" }}
                            />
                          )
                        ) : isActive === ind ? (
                          list.video.vid_progress === 1 ? (
                            <img
                              src={tickIcon}
                              alt="tickIcon"
                              width={25}
                              className="mt-3 ms-5 me-3"
                              style={{ float: "right" }}
                            />
                          ) : (
                            <img
                              src={videoPlayingIcon}
                              alt="videoPlayingIcon"
                              width={25}
                              className="mt-3 ms-5 me-3"
                              style={{ float: "right" }}
                            />
                          )
                        ) : list.video.vid_progress === 1 ? (
                          <img
                            src={tickIcon}
                            alt="tickIcon"
                            width={25}
                            className="mt-3 ms-5 me-3"
                            style={{ float: "right" }}
                          />
                        ) : (
                          <img
                            src={notPlayed}
                            alt="notPlayed"
                            width={25}
                            className="mt-3 ms-5 me-3"
                            style={{ float: "right" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </>
        </div>
      </div>
    </>
  );
};

export default AllCourseVideoList;
