import React, { useState, useEffect } from "react";
import sidebarData from "../Assets/Json/sidebarJson.json";
import Sidebar from "../Components/Sidebar";
import DayDateProfile from "../Components/DayDateProfile";
import Charts from "../Components/Charts";
import ProfilePrinciple from "../StudentPages/ProfilePrinciple";
import PrincipalProgress from "./principalProgress";
import LottieAnimation from "../Components/LottieAnimation";
import { useNavigate } from "react-router-dom";

function PrincipleDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const principleSidebar = sidebarData.Principle;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    // Prevent going back to the previous page after logging out
    const clearHistory = () => {
      window.history.pushState(null, window.location.pathname);
      window.onpopstate = () => {
        navigate("/principaldashboard");
      };
    };
    clearHistory();
    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);

  return (
    <>
      <Sidebar sidebarLinks={principleSidebar}></Sidebar>
      {isLoading ? (
        <LottieAnimation />
      ) : (
        <>
          <section className="sidebody mt-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-12">
                  {/* <DayDateProfile /> */}

                  <PrincipalProgress />

                  <Charts />
                  <br />
                  <br />
                  <div></div>
                </div>
                <div
                  className="col-lg-4 col-md-12 col-12 mt-5 pt-5"
                  style={{ borderLeft: "2px solid #F3F5F7" }}
                >
                  <ProfilePrinciple />
                </div>
              </div>
              {/* <div className="row">
                <RecentlyVideos
                  linkTo={"/singleVideoPrincipal"}
                  viewAll={"/principal/videos"}
                />
              </div> */}
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default PrincipleDashboard;
