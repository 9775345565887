import React, { useEffect } from "react";
import { useTimer } from "react-timer-hook";
import TimerImage from "../Assets/Images/Assessment/timer_clock.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Timer = ({ timer, onExpire }) => {
  const storedExpiryTimestamp = localStorage.getItem("expiryTimestamp");
  const initialExpiryTimestamp = storedExpiryTimestamp
    ? parseInt(storedExpiryTimestamp, 10)
    : timer;

  const { totalSeconds, seconds, minutes, restart } = useTimer({
    expiryTimestamp: initialExpiryTimestamp,
    onExpire: () => onExpire,
  });

  useEffect(() => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + initialExpiryTimestamp);

    restart(time);
  }, [timer]);

  useEffect(() => {
    localStorage.setItem("expiryTimestamp", totalSeconds);
  }, [totalSeconds]);

  useEffect(() => {
    if (totalSeconds === 0) {
      onExpire();
    } else if (timer === 60) {
      // window.alert("1 minute left!, Timer is still Running ");
      toast.warning("1 minute left! Hurry Up", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [totalSeconds, onExpire]);

  return (
    <div className="timer_div" style={{ borderRadius: "38px" }}>
      <div style={{ textAlign: "center" }}>
        <div className="content">
          <div>
            <img
              src={TimerImage}
              className="timer_image"
              style={{ width: "28px", height: "28px" }}
              alt="Timer"
            />
          </div>
          <div style={{ fontSize: "20px" }}>
            <span>{minutes}</span>:<span>{seconds}</span>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Timer;
