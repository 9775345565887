import React, { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import schoolsEnrolled from "../Assets/Images/ManagerDashboard/enrolled-icon.svg";
import trainersAllocated from "../Assets/Images/ManagerDashboard/trainers-allocated-icon.svg";
import { GET_MANAGER_CHART_API } from "../Components/AllAPI";
const ManagerChart = () => {
  const [chartData, setChartData] = useState([]);
  const customYTicks = [0, 5, 10, 15, 20, 25, 30];
  const fetchData = () => {
    var token = localStorage.getItem("authToken");

    fetch(GET_MANAGER_CHART_API, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setChartData(data);
        console.log("manager chart", data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <p className="chart_title mt-5 mb-0">Schools and Trainers</p>
      <div
        className="p-5"
        // style={{
        //   borderRadius: "16px",
        //   border: "1px solid var(--text-20-stroke, #E7E6EA)",
        // }}
      >
        <div className="chart-info d-flex" style={{ marginLeft: "50%" }}>
          <p>
            <img src={schoolsEnrolled} alt="schoolsEnrolledImg" />
            <span className="graphAbout ms-2">Schools Enrolled</span>
          </p>
          <p style={{ marginLeft: "10px" }}>
            <img src={trainersAllocated} alt="" />
            <span className="graphAbout ms-2">Trainers Allocated</span>
          </p>
        </div>
        <ResponsiveContainer aspect={2} width={700}>
          <BarChart data={chartData}>
            <XAxis dataKey="month" />
            <YAxis ticks={customYTicks} />
            <Bar
              dataKey="total_schools"
              fill="rgba(250, 95, 28, 1)"
              radius={[20, 20, 0, 0]}
            />
            <Bar
              dataKey="schools_with_tra_id"
              fill="#FEDFD2"
              radius={[20, 20, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default ManagerChart;
