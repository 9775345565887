import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import { Link, useLocation } from "react-router-dom";
import DayDateProfile from "./DayDateProfile";
// import DummyVideos from "./DummyVideos";
import nextArrow from "../Assets/Images/Dashboard/next_icon.png";

import AllCourseVideoList from "./AllCourseVideoList";
function AllParticularCourse() {
  const location = useLocation();
  const syllabusData = location.state.courses;
  // console.log('syllabus data',syllabusData);
  const user_role = localStorage.getItem("user_role");
  let commonSidebar;
  // console.log(user_role);
  if (user_role === "Manager") {
    commonSidebar = sidebarData.Manager;
  } else if (user_role === "Trainer") {
    commonSidebar = sidebarData.Trainer;
  } else if (user_role === "Principal") {
    commonSidebar = sidebarData.Principle;
  }
  return (
    <>
      <Sidebar sidebarLinks={commonSidebar} />
      
      <div className="sidebody">
        <div className="container">
        {/* {user_role === "Trainer" ? "" : <DayDateProfile />} */}
    
            {/* // syllabus details */}
            <div className="ChapterDetails mt-4 mb-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/allcourselist" className="prevPageTitle">
                      Courses
                    </Link>
                  </li>
                  <img src={nextArrow} alt="nextArrow" />
                  <p
                    className="currentPageTitle mt-0 mb-0"
                    style={{ color: "#211C37" }}
                  >
                    {`Chapter ${syllabusData.chapter_no}`}
                  </p>
                </ol>
              </nav>
              <div className="row mt-4">
                <div className="col-lg-7 col-md-7">
                  <div className="Header-video-div">
                    <div className="chapter-div">
                      <p style={{ marginBottom: "0px" }}>
                        {`Chapter ${syllabusData.chapter_no}`}
                      </p>
                    </div>
                    <div className="Topic-div">
                      <p className="Header-Video">
                        {syllabusData.chapter_name}
                      </p>
                    </div>
                  </div>

                  <div className="Image-Div mt-4" >
                    <img
                      className="Video-Image"
                      src={syllabusData.chapter_thumbnail}
                      alt="Video-Preview"
                      width="96%"
                    />
                  </div>
                  <div className="Description-Div">
                    <div className="Description-Header-Text">
                      <p>Description</p>
                    </div>
                    <div className="Description-Text">
                      <p className="inner-text">
                        {syllabusData.chapter_description}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-5 col-md-5 second-column ps-4"
                  style={{ borderLeft: "1px solid #E7E6EA" }}
                >
                  <div className="Topics-Covered-Div">
                    <p className="Header-Topics">Topics</p>
                  </div>
                  <AllCourseVideoList syllabusData={syllabusData} />
                </div>
              </div>
            </div>
      
        </div>
      </div>
    </>
  );
}

export default AllParticularCourse;
