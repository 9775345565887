import { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import sidebarData from "../Assets/Json/sidebarJson.json";
import { Link, useLocation } from "react-router-dom";
import nextArrow from "../Assets/Images/Dashboard/next_icon.png";
import DayDateProfile from "../Components/DayDateProfile";
import circuit_diagramImg from "../Assets/Images/ProjectList/circuit_diagram.svg";
import projectImg from "../Assets/Images/ProjectList/dummy_project_img.png";
import LottieAnimation from "../Components/LottieAnimation";
import { API_START_POINT } from "./AllAPI";
import imgPlaceholder from "../Assets/Images/img-placeholder.png";
function AllLoginProject() {
  const location = useLocation();
  const projectId = location.state.data.proj_id;
  const projNo = location.state.ind;
  const [projectData, setProjectData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showData, setShowData] = useState(false);
  const [selectedProjectData, setSelectedProjectData] = useState("");
  let commonSidebar;
  const user_role = localStorage.getItem("user_role");
  if (user_role === "Manager") {
    commonSidebar = sidebarData.Manager;
  } else if (user_role === "Trainer") {
    commonSidebar = sidebarData.Trainer;
  } else if (user_role === "Principal") {
    commonSidebar = sidebarData.Principle;
  }

  const showProjectData = (data) => {
    setShowData(true);
    setSelectedProjectData(data);
  };

  useEffect(() => {
    setIsLoading(true);
    var token = localStorage.getItem("authToken");
    fetch(`${API_START_POINT}/get-project-data/${projectId}`, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("project single data", data.project);

        if (data && data.project) {
          setProjectData(data.project);
        } else {
          setProjectData([]);
        }
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Sidebar sidebarLinks={commonSidebar} />

      {isLoading ? (
        <LottieAnimation />
      ) : Object.keys(projectData).length > 0 ? (
        <section className="project_section">
          <div className="sidebody">
            <div className="container">
              {user_role === "Trainer" ? "" : <DayDateProfile />}
              <>
                <div className="mt-5">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/allCourseList" className="prevPageTitle">
                          Courses
                        </Link>
                      </li>
                      <img src={nextArrow} alt="nextArrow" />
                      <li className="breadcrumb-item">
                        <Link to="/allCourseList" className="prevPageTitle">
                          Projects
                        </Link>
                      </li>
                      <img src={nextArrow} alt="nextArrow" />

                      <p
                        className="currentPageTitle mt-0 mb-0"
                        style={{ color: "#211C37" }}
                      >
                        {projectData.proj_title}
                      </p>
                    </ol>
                  </nav>
                </div>

                <div class="row mt-2">
                  <>
                    <div class="col-lg-5 col-md-5">
                      <div
                        className="project_div d-flex mt-4"
                        onClick={() => (
                          setShowData(false), setSelectedProjectData("")
                        )}
                        style={{
                          cursor: "pointer",
                          background: !showData
                            ? "#9566E8"
                            : "rgba(248, 248, 249, 1)",
                        }}
                      >
                        <img
                          src={circuit_diagramImg}
                          alt="componentImg"
                          className={!showData && "filterImg"}
                          onError={(e) => {
                            e.target.src = imgPlaceholder;
                          }}
                        />
                        <p
                          className="project_main_title my-auto ms-4"
                          style={{
                            color: !showData ? "#fff" : "#000",
                          }}
                        >
                          Project Overview
                        </p>
                      </div>
                      {projectData?.listData?.map((data, ind) => {
                        return (
                          <>
                            <div
                              className="project_div d-flex mt-4"
                              onClick={() => showProjectData(data)}
                              key={ind}
                              style={{
                                cursor: "pointer",
                                background:
                                  selectedProjectData.name === data.name
                                    ? "#9566E8"
                                    : "rgba(248, 248, 249, 1)",
                              }}
                            >
                              <img
                                // src={
                                //   data.image === null || ""
                                //     ? componentImg
                                //     : data.image
                                // }
                                src={circuit_diagramImg}
                                alt="componentImg"
                                className={
                                  selectedProjectData.name === data.name &&
                                  "filterImg"
                                }
                              />
                              <p
                                className="project_main_title my-auto ms-4"
                                style={{
                                  color:
                                    selectedProjectData.name === data.name
                                      ? "#fff"
                                      : "#000",
                                }}
                              >
                                {data.name}
                              </p>
                            </div>
                          </>
                        );
                      })}

                      <p className="conclusion_title mt-4">Conclusion</p>
                      <p className="conclusion_details">
                        {projectData.proj_conclusion}
                      </p>
                    </div>
                    <div
                      class="col second-column ps-5"
                      style={{
                        borderLeft: "1px solid #E7E6EA",
                        height: "800px",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                      }}
                    >
                      <p className="selectedProjectName mt-4">
                        {selectedProjectData.name}
                      </p>
                      {showData ? (
                        selectedProjectData?.image?.length > 0 ? (
                          selectedProjectData?.image?.map((data, ind) => {
                            return (
                              <>
                                <div
                                  className="projectSummaryDiv"
                                  key={ind}
                                  style={{
                                    marginBottom: "20px",
                                    marginTop: "80px",
                                  }}
                                >
                                  <p
                                    className="proj_name mt-3"
                                    style={{ fontWeight: "400" }}
                                  >
                                    {data.proj_comp_name}
                                  </p>
                                  <div className="blur_img w-75">
                                    <img
                                      src={data.proj_comp_image}
                                      alt="proj_img"
                                      className=" w-100"
                                      style={{ height: "inherit" }}
                                      onError={(e) => {
                                        e.target.src = imgPlaceholder;
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })
                        ) : selectedProjectData?.value?.length > 0 ? (
                          selectedProjectData?.value[0]?.instructions?.map(
                            (data, ind) => {
                              // console.log('value data',data);
                              return (
                                <>
                                  <div className="projectSummaryDiv" key={ind}>
                                    <p
                                      className="proj_name mt-3"
                                      style={{ fontWeight: "300" }}
                                    >
                                      <li className="list-unstyled">{data}</li>
                                    </p>
                                    {/* <div className="blur_img w-75 mt-4">
                                  <img
                                    src={data.proj_comp_image}
                                    alt="proj_img"
                                    className=" w-100"
                                  />
                                </div> */}
                                  </div>
                                </>
                              );
                            }
                          )
                        ) : (
                          <p className="mt-5 noData">No data available</p>
                        )
                      ) : (
                        <div className="Topics-Covered-Div Project_details">
                          <div className="Project_details d-flex ">
                            <div className=" my-auto me-4">
                              <img src={projectImg} alt="projectImg" />
                            </div>
                            <div>
                              <p className="project_no mb-0">
                                Project {projNo + 1}
                              </p>
                              <p className="Header-Topics">
                                {projectData.proj_title}
                              </p>
                            </div>
                          </div>

                          <img
                            src={
                              projectData.proj_image === null || ""
                                ? imgPlaceholder
                                : projectData.proj_image
                            }
                            className="mt-3 w-75"
                            alt="project_img"
                            onError={(e) => {
                              e.target.src = imgPlaceholder;
                            }}
                          />

                          <p className="project_aim_title mt-4">Aim</p>
                          <p className="project_aim_description">
                            {projectData.proj_aim}
                          </p>
                          <p className="project_objective_title mt-4">
                            Objective
                          </p>
                          <p className="project_objective_description">
                            {projectData.proj_objective}
                          </p>
                          <p className="project_objective_title mt-4">
                            Future Scope
                          </p>
                          <p className="project_objective_description">
                            {projectData.proj_futurescope}
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              </>
            </div>
          </div>
        </section>
      ) : (
        <div className="noDataContainer">
          <div className="noData mt-5 pt-5">No data available</div>
        </div>
      )}
    </>
  );
}

export default AllLoginProject;
