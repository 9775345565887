import React, { useState } from "react";
import deleteIcon from "../Assets/Images/ManagerDashboard/delete-icon.svg";
import { useNavigate } from "react-router-dom";
import { API_START_POINT } from "../Components/AllAPI";

const DeleteSchool = (props) => {
// console.log(props.sch_id);
const sch_id = props.sch_id;
  const navigate = useNavigate();
  const yesBtnHandler = (e) => {
    e.preventDefault();
    // console.log("yes");
    var token = localStorage.getItem("authToken");
    fetch(`${API_START_POINT}/school/delete/${sch_id}`, {
      method: "DELETE",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const msg = json.message;
        // console.log(msg);
        if (msg === "School profile deleted successfully") {
          alert("School profile deleted successfully");
          navigate("/manager/schoollist");
          setTimeout(window.location.reload(), 50);
        }
      });
  };

  return (
    <>
      <button
        type="button"
        className="btn delete_btn mb-5"
        data-bs-toggle="modal"
        data-bs-target="#exampleModalDelete"
      >
        <img src={deleteIcon} alt="delete-icon" />
      </button>

      {/* <!-- Modal --> */}

      <div
        className="modal fade "
        id="exampleModalDelete"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content deleteSchoolModal">
            <div className="modal-header">
              <p className="deleteTitle">Delete School</p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <p className="deleteMsg ms-3">
              Are you sure you want to delete school{" "}
            </p>

            <div className="row mt-3 mb-3">
              <div className="col-lg-6 col-sm-12 col-md-6">
                <button className="btn yes_btn" onClick={yesBtnHandler}>
                  Yes
                </button>
              </div>

              <div className="col-lg-6 col-sm-12 col-md-6">
                <button
                  className="btn no_btn"
                  data-bs-dismiss="modal"
                  // onClick={hideEditModal}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteSchool;
